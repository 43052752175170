import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

import { ListUtils } from "@skillup/shared-utils";

import { TextInput } from "components/Form/TextInput";
import { Select } from "components/Select";

import { type FilterProps } from "../types";
import { isEmpty } from "lodash";

interface Props {
  readonly id: string;
  readonly config: ListUtils.FilterConfiguration;
  readonly filter?: FilterProps<any>;
  readonly value: ListUtils.FilterValue;
  readonly onChange: (filterId: string, value: ListUtils.FilterValue) => void;
  readonly translationPrefix?: string;
  readonly t: (key: string, params?: { defaultValue: string } & any) => string;
}

const FilterComponent = ({
  id,
  config: { type },
  filter: { options, placeholder } = {},
  value,
  onChange,
  t,
}: Props): JSX.Element | null => {
  const [stateValue, setStateValue] = useState<ListUtils.FilterValue["value"]>(value?.value);

  const debouncedOnChange = useCallback(debounce(onChange, 300), []);

  // Necessary to update the value when the filter is reset
  useEffect(() => {
    setStateValue(value?.value);
  }, [value]);

  const handleOnFilterValueChange = (value: any) => {
    setStateValue(value);

    if (type === "text") {
      debouncedOnChange(id, {
        type: ListUtils.FilterType.TEXT,
        operator: ListUtils.FilterOperator.CONTAINS,
        value,
      });

      return;
    }

    if (type === "multiselect") {
      const valueArray = value.filter((v) => !!v);
      onChange(id, {
        type: ListUtils.FilterType.MULTISELECT,
        operator: ListUtils.FilterOperator.CONTAINS,
        value: isEmpty(valueArray) ? null : valueArray,
      });

      return;
    }

    if (type === "select") {
      onChange(id, {
        type: ListUtils.FilterType.SINGLESELECT,
        operator: ListUtils.FilterOperator.EQUALS,
        value,
      });

      return;
    }

    onChange(id, value);
  };

  const placeholderText = placeholder ?? t(`filter.placeholder.${id}`);

  switch (type) {
    case ListUtils.FilterType.TEXT:
      return (
        <TextInput
          autoFocus
          name={id}
          value={stateValue?.toString() ?? ""}
          placeholder={placeholderText}
          onChange={handleOnFilterValueChange}
        />
      );
    case ListUtils.FilterType.MULTISELECT:
    case ListUtils.FilterType.SINGLESELECT:
      return (
        <Select
          multi={type === ListUtils.FilterType.MULTISELECT}
          isSearchable
          canSelectAll
          autoFocus
          clearable={false}
          maxShownValues={1}
          value={(stateValue as any[]) ?? []}
          selectPlaceholder={placeholderText}
          onChange={handleOnFilterValueChange}
          options={options ?? []}
          extraValuesLabel={(count) =>
            t("filter.multiselect.extra_value", {
              defaultValue: "+{{count}} autres",
              count,
            })
          }
          allValuesLabel={t(`select.all-values.${id}`, {
            defaultValue: "Tous les éléments",
          })}
        />
      );
  }

  return null;
};
export { FilterComponent };
