import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef, useMemo, useState, RefObject, MutableRefObject } from "react";

import classNames from "classnames";
import { MdEdit as Edit } from "react-icons/md";

import CloseIcon from "@mui/icons-material/Close";
import { useVirtualizer } from "@tanstack/react-virtual";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ListUtils, getInitials } from "@skillup/shared-utils";
import { Flex, Grid, Text, Loader } from "@skillup/design-system";
import {
  DSButton,
  DSAvatar,
  FilterRef,
  DSTooltip,
  DSDropdown,
  UserPopover,
  DSDropdownItem,
} from "@skillup/ui";

import { EnumerableField } from "services/coreHR";

import { views, Campaign, FiltersConfig } from "../../types";
import { useMatrixData, type MatrixData } from "./useMatrixData";

import styles from "./TalentMatrix.module.scss";

const MAX_COLLABORATORS_FOR_ARBITRATED_MODE = 60;

type DrawerState =
  | { type: "idle" }
  | {
      type: "n-box-not-positioned-cell-selected";
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    }
  | {
      type: "n-box-cell-selected";
      xAxis: { id: string; scaleLabel: string; optionValue: number; optionLabel: string };
      yAxis: { id: string; scaleLabel: string; optionValue: number; optionLabel: string };
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    };

type TalentMatrixProps = {
  campaign: Campaign;
  filterRef: MutableRefObject<FilterRef>;
  drawer: RefObject<null | HTMLDivElement>;
  filterValues: ListUtils.FilterValues<FiltersConfig>;
};
export function TalentMatrix({ campaign, drawer, filterRef, filterValues }: TalentMatrixProps) {
  const { t } = useTranslation();
  const [isArbitratedModeActivated, setIsArbitratedModeActivated] = useState(false);
  const [drawerState, setDrawerState] = useState<DrawerState>({
    type: "idle",
  });

  /**
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   * @wip is enumerableFieldsMap really mandatory in this format ?
   */
  const enumerableFieldsMap = campaign.fields.reduce((acc, field) => {
    if (field.kind === "enumerable" && !acc[field.uuid]) {
      acc[field.uuid] = field;
    }
    return acc;
  }, {});

  const {
    data,
    mutations,
    xAxis: selectedXAxis,
    yAxis: selectedYAxis,
  } = useMatrixData({
    campaign,
    enumerableFieldsMap,
    filters: filterValues,
  });

  const fields = useMemo(() => {
    return campaign.fields
      .filter((field) => field.kind === "enumerable")
      .map((field) => enumerableFieldsMap[field.uuid]);
  }, [campaign.fields, enumerableFieldsMap]);

  const cells = data?.matrix.flat() ?? [];

  const { nBoxWidth, xAxisLabel, xScaleLabels } = useX({ data });
  const { nBoxHeight, yAxisLabel, yScaleLabels } = useY({ data });

  const textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  > = useMemo(() => {
    return {
      "over-median": "plainText-onDark-default",
      p10: "plainText-onLight-default",
      p90: "plainText-onDark-default",
      "under-median": "plainText-onLight-default",
    };
  }, []);

  return (
    <Flex flexGrow={1}>
      <Flex gap="s" width="100%" flexDirection="column">
        {!data && (
          <Flex height="100%" alignItems="center" justifyContent="center" className={styles.loader}>
            <Loader fillSpace />
          </Flex>
        )}
        {data && (
          <>
            <Flex>
              <Flex flexGrow={1} flexDirection="column">
                <Text espaceFont="body1Bold" color="plainText-onLight-default">
                  {t("peopleReview.talentMatrix.positionedCollabCount", {
                    count: data.positionedCount,
                    defaultValue:
                      data.positionedCount > 1
                        ? `{{count}} collaborateurs positionnés`
                        : `{{count}} collaborateur positionné`,
                  })}
                </Text>
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                  {t("peopleReview.talentMatrix.positionedCollabsDetailsPart1", {
                    defaultValue: `Seuls les collaborateurs ayant été évalués sur ${xAxisLabel} et ${yAxisLabel} sont positionnés dans la matrice ci-dessous.`,
                  })}
                </Text>
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                  {t("peopleReview.talentMatrix.positionedCollabsDetailsPart2", {
                    defaultValue: `Cliquez sur une case pour afficher la liste des collaborateurs qu’elle contient.`,
                  })}
                </Text>
              </Flex>
              <Flex alignItems="flex-end">
                <DSTooltip
                  direction="top"
                  disabled={
                    data.positionedCount > 1 &&
                    data.positionedCount < MAX_COLLABORATORS_FOR_ARBITRATED_MODE
                  }
                  label={t("peopleReview.talentMatrix.tooltip.activeArbitratedModeDisabled", {
                    count: MAX_COLLABORATORS_FOR_ARBITRATED_MODE,
                    defaultValue:
                      "Le mode détaillé n'est accessible que pour une matrice contenant moins de {{count}} collaborateurs positionnés",
                  })}
                >
                  <DSButton
                    type="button"
                    buttonSize="M"
                    emphasis="High"
                    onClick={() => setIsArbitratedModeActivated((prev) => !prev)}
                    disabled={
                      data.positionedCount < 1 ||
                      data.positionedCount > MAX_COLLABORATORS_FOR_ARBITRATED_MODE
                    }
                    label={
                      isArbitratedModeActivated
                        ? t("peopleReview.talentMatrix.btn.disableArbitratedMode", {
                            defaultValue: "Quitter le mode détaillé",
                          })
                        : t("peopleReview.talentMatrix.btn.activeArbitratedMode", {
                            defaultValue: "Accéder au mode détaillé",
                          })
                    }
                  />
                </DSTooltip>
              </Flex>
            </Flex>

            <div className={styles.container}>
              {selectedYAxis && (
                <YAxis
                  allFields={fields}
                  scaleLabels={yScaleLabels}
                  label={selectedYAxis?.label}
                  selectedFields={[selectedXAxis, selectedYAxis]}
                  onAxisLabelClick={(field) =>
                    mutations.swap({
                      newXAxisFieldID: selectedXAxis.uuid,
                      newYAxisFieldID: field.uuid,
                    })
                  }
                />
              )}
              {selectedXAxis && (
                <XAxis
                  allFields={fields}
                  scaleLabels={xScaleLabels}
                  label={selectedXAxis?.label}
                  selectedFields={[selectedXAxis, selectedYAxis]}
                  onAxisLabelClick={(field) =>
                    mutations.swap({
                      newXAxisFieldID: field.uuid,
                      newYAxisFieldID: selectedYAxis.uuid,
                    })
                  }
                />
              )}
              <SwapButton
                onSwap={() => {
                  const oldX = selectedXAxis;

                  mutations.swap({
                    newXAxisFieldID: selectedYAxis.uuid,
                    newYAxisFieldID: oldX.uuid,
                  });
                }}
              />
              <Grid
                className={styles.matrix}
                gridTemplateColumnsCustom={`5rem repeat(${nBoxWidth}, 1fr)`}
                gridTemplateRowsCustom={`repeat(${nBoxHeight}, auto) 3.125rem`}
              >
                <MatrixCells
                  cells={cells}
                  filterRef={filterRef}
                  textColor={textColor}
                  nBoxWidth={nBoxWidth}
                  nBoxHeight={nBoxHeight}
                  drawerState={drawerState}
                  selectedYAxis={selectedYAxis}
                  selectedXAxis={selectedXAxis}
                  setDrawerState={setDrawerState}
                  isArbitratedModeActivated={isArbitratedModeActivated}
                />
              </Grid>
            </div>

            <Flex gap="xxs" flexDirection="column" alignItems="flex-start">
              {data.notPositioned.reviews.length ? (
                <Text espaceFont="body1Bold" color="plainText-onLight-default">
                  {t("people.talentMatrix.notPositionedCollab", {
                    count: data.notPositioned.reviews.length,
                    defaultValue:
                      data.notPositioned.reviews.length > 1
                        ? "{{count}} collaborateurs non positionnés"
                        : "{{count}} collaborateur non positionné",
                  })}
                </Text>
              ) : (
                <Text espaceFont="body2Regular" color="plainText-onLight-default">
                  {t("peopleReview.talentMatrix.cell.notPositionnedCollabs.empty", {
                    defaultValue: "Aucun collaborateur non positionné",
                  })}
                </Text>
              )}

              <Flex
                gap="xxs"
                minWidth={"12rem"}
                flexDirection="column"
                onClick={() =>
                  setDrawerState({
                    type: "n-box-not-positioned-cell-selected",
                    collaborators: data.notPositioned.reviews,
                  })
                }
                className={classNames(styles.matrixCell, {
                  [styles.noSelected]: drawerState.type === "n-box-cell-selected",
                  [styles.overMedian]: data.notPositioned.heatMapStatus === "over-median",
                  [styles.p10]: data.notPositioned.heatMapStatus === "p10",
                  [styles.p90]: data.notPositioned.heatMapStatus === "p90",
                  [styles.selected]: drawerState.type === "n-box-not-positioned-cell-selected",
                  [styles.underMedian]: data.notPositioned.heatMapStatus === "under-median",
                })}
              >
                {isArbitratedModeActivated ? (
                  <MatrixCellAvatar
                    filterRef={filterRef}
                    textColor={textColor}
                    cell={data.notPositioned}
                    disabledFlexForEachItems={true}
                  />
                ) : (
                  <MatrixCellDefault textColor={textColor} cell={data.notPositioned} />
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {drawer.current &&
        createPortal(
          <TalentMatrixDrawer
            filterRef={filterRef}
            drawerState={drawerState}
            filterValues={filterValues}
            closeDrawer={() => setDrawerState({ type: "idle" })}
          />,
          drawer.current
        )}
    </Flex>
  );
}

type ArrowLeftProps = {
  label: string;
  fields: EnumerableField[];
  hasMoreThanTwoFields: boolean;
  currentFields: EnumerableField[];
  onChangeField: (field: EnumerableField) => void;
};

const ArrowLeft = ({
  currentFields,
  fields,
  hasMoreThanTwoFields,
  label,
  onChangeField,
}: ArrowLeftProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowLeft)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>

      {hasMoreThanTwoFields && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<Edit />} />}
        >
          {fields?.map((field) => (
            <DSDropdownItem
              key={field.uuid}
              label={field?.label}
              disabled={currentFields.includes(field)}
              onClick={() => onChangeField(field)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type YAxisProps = {
  label: string;
  scaleLabels: string[];
  allFields: EnumerableField[];
  selectedFields: EnumerableField[];
  onAxisLabelClick: (field: EnumerableField) => void;
};

const YAxis = ({ allFields, label, onAxisLabelClick, scaleLabels, selectedFields }: YAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.yAxis)}>
      <ArrowLeft
        label={label}
        fields={allFields}
        currentFields={selectedFields}
        hasMoreThanTwoFields={allFields.length > 2}
        onChangeField={(field) => onAxisLabelClick(field)}
      />
    </Flex>
  </>
);

type ArrowBottomProps = {
  label: string;
  fields: EnumerableField[];
  hasMoreThanTwoFields: boolean;
  currentFields: EnumerableField[];
  onChangeField: (field: EnumerableField) => void;
};

const ArrowBottom = ({
  currentFields,
  fields,
  hasMoreThanTwoFields,
  label,
  onChangeField,
}: ArrowBottomProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowBottom)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>{" "}
      {hasMoreThanTwoFields && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          position="up-right"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<Edit />} />}
        >
          {fields.map((field) => (
            <DSDropdownItem
              key={field.uuid}
              label={field?.label}
              disabled={currentFields.includes(field)}
              onClick={() => onChangeField(field)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type XAxisProps = {
  label: string;
  scaleLabels: string[];
  allFields: EnumerableField[];
  selectedFields: EnumerableField[];
  onAxisLabelClick: (field: EnumerableField) => void;
};

const XAxis = ({ allFields, label, onAxisLabelClick, scaleLabels, selectedFields }: XAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.xAxis)}>
      <ArrowBottom
        label={label}
        fields={allFields}
        currentFields={selectedFields}
        hasMoreThanTwoFields={allFields.length > 2}
        onChangeField={(field) => onAxisLabelClick(field)}
      />
    </Flex>
  </>
);

type SwapButtonProps = {
  onSwap: () => void;
};

const SwapButton = ({ onSwap }: SwapButtonProps) => (
  <Flex alignItems="center" justifyContent="center" className={styles.axisSwap}>
    <DSTooltip direction="top" label="Inverser les axes">
      <DSButton iconOnly type="button" buttonSize="M" icon={<SwapHorizIcon />} onClick={onSwap} />
    </DSTooltip>
  </Flex>
);

type UseXParams = {
  data: MatrixData;
};

const useX = ({ data }: UseXParams) => {
  const nBoxWidth = data?.xAxis.scale.options.length;
  const xAxisLabel = data?.xAxis.fieldLabel;
  const xScaleLabels = data?.xAxis.scale.options.map((option) => option?.label);

  return { nBoxWidth, xAxisLabel, xScaleLabels };
};

type UseYParams = {
  data: MatrixData;
};

const useY = ({ data }: UseYParams) => {
  const nBoxHeight = data?.yAxis.scale.options.length;
  const yAxisLabel = data?.yAxis.fieldLabel;
  const yScaleLabels = data?.yAxis.scale.options
    .sort((a, b) => b.value - a.value)
    .map((option) => option?.label);

  return { nBoxHeight, yAxisLabel, yScaleLabels };
};

type TalentMatrixDrawerProps = {
  closeDrawer: () => void;
  drawerState: DrawerState;
  filterRef: TalentMatrixProps["filterRef"];
  filterValues: TalentMatrixProps["filterValues"];
};

const TalentMatrixDrawer = ({
  closeDrawer,
  drawerState,
  filterRef,
  filterValues,
}: TalentMatrixDrawerProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  if (drawerState.type === "idle") return null;

  return (
    <Flex padding="s" flexDirection="column" className={styles.talentMatrixDrawer}>
      <Flex padding="s" width={"100%"} className={styles.talentMatrixDrawerContainer}>
        <DSButton
          iconOnly
          label="close"
          icon={<CloseIcon />}
          className={styles.talentMatrixDrawerCloseButton}
          onClick={closeDrawer}
        />

        <Flex width={"100%"} flexDirection="column">
          <Flex flexDirection="column">
            {drawerState.type === "n-box-not-positioned-cell-selected" ? (
              <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
                {t("peopleReview.talentMatrix.drawer.notPositionedCollabs", {
                  defaultValue: "Collaborateurs non positionnés",
                })}
              </Text>
            ) : (
              <>
                <Flex marginBottom="xs" flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {drawerState.xAxis.scaleLabel}
                  </Text>
                  <Text espaceFont="body2Regular" color="plainText-onLight-default">
                    {drawerState.xAxis.optionLabel}
                  </Text>
                </Flex>
                <Flex flexDirection="column">
                  <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                    {drawerState.yAxis.scaleLabel}
                  </Text>
                  <Text espaceFont="body2Regular" color="plainText-onLight-default">
                    {drawerState.yAxis.optionLabel}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>

          <Flex flex={1} marginTop="m" overflowY="auto" flexDirection="column">
            {!drawerState.collaborators.length ? (
              <Text espaceFont="body2Regular" color="plainText-onLight-default">
                {t("peopleReview.talentMatrix.drawer.positionedCollabs.empty", {
                  defaultValue: "Aucun collaborateur",
                })}
              </Text>
            ) : (
              <DrawerContent filterRef={filterRef} collaborators={drawerState.collaborators} />
            )}
          </Flex>
          <Flex marginTop="xs">
            {drawerState.type === "n-box-cell-selected" && filterRef.current && (
              <DSButton
                emphasis="Mid"
                buttonSize="S"
                label={t("peopleReview.talentMatrix.drawer.goto-talentgrid", {
                  defaultValue: "Voir dans la vue d'arbitrage",
                })}
                onClick={() => {
                  filterRef.current.addFilter(drawerState.xAxis.id);
                  filterRef.current.addFilter(drawerState.yAxis.id);
                  filterRef.current.setValues({
                    ...filterValues,
                    [drawerState.xAxis.id]: {
                      value: [drawerState.xAxis.optionValue.toString()],
                    },
                    [drawerState.yAxis.id]: {
                      value: [drawerState.yAxis.optionValue.toString()],
                    },
                  });

                  history.push({
                    pathname: history.location.pathname.replace(views.matrix, views.grid),
                  });
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const DrawerContent = ({
  collaborators,
  filterRef,
}: {
  filterRef: MutableRefObject<FilterRef>;
  collaborators: MatrixData["matrix"][number][number]["reviews"];
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: collaborators.length,
    estimateSize: () => 34,
    getScrollElement: () => parentRef.current,
    overscan: 10,
  });

  const items = virtualizer.getVirtualItems();
  return (
    <div
      ref={parentRef}
      style={{
        contain: "strict",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{
            left: 0,
            position: "absolute",
            top: 0,
            transform: `translateY(${items[0]?.start ?? 0}px)`,
            width: "100%",
          }}
        >
          {items.map((virtualRow) => {
            const collaborator = collaborators[virtualRow.index];

            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
                className={virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"}
              >
                <Flex marginBottom="s" key={collaborator.id}>
                  <UserPopover
                    position="down-right"
                    properties={[collaborator.role]}
                    user={{
                      uuid: collaborator.id,
                      fullName:
                        collaborator.fullName ??
                        `${collaborator.firstName} ${collaborator.lastName}`,
                      initials: "AB",
                    }}
                    profileBtn={{
                      label: t("peopleReview.talentMatrix.drawer.avatar-popover.goto-talentgrid", {
                        defaultValue: "Voir dans le tableau d'arbitrage",
                      }),
                      onClick: () => {
                        filterRef.current.addFilter("fullName");
                        filterRef.current.setValue("fullName", {
                          type: "text",
                          operator: "contains",
                          value: collaborator.fullName,
                        });
                        history.push({
                          pathname: history.location.pathname.replace(views.matrix, views.grid),
                        });
                      },
                    }}
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <DSAvatar
                        size="S"
                        darkMode
                        type="Initials"
                        imageUrl={undefined}
                        initials={getInitials(collaborator)}
                        randomColorSeedString={collaborator.id}
                      />
                      <Text
                        marginLeft="xs"
                        espaceFont={"body2Regular"}
                        color="plainText-onLight-default"
                      >
                        {collaborator.fullName ??
                          `${collaborator.firstName} ${collaborator.lastName}`}
                      </Text>
                    </Flex>
                  </UserPopover>
                </Flex>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

type MatrixCellsProps = {
  nBoxWidth: number;
  nBoxHeight: number;
  drawerState: DrawerState;
  selectedYAxis: EnumerableField;
  selectedXAxis: EnumerableField;
  isArbitratedModeActivated: boolean;
  cells: MatrixData["matrix"][number];
  filterRef: MutableRefObject<FilterRef>;
  setDrawerState: (state: DrawerState) => void;
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
};

const YAxisLabel = ({ label }: { label: string }) => (
  <Flex alignItems="center" className={styles.yScaleLabelContainer}>
    <p className={styles.yScaleLabel}>{label}</p>
  </Flex>
);

const MatrixCell = ({
  cell,
  drawerState,
  filterRef,
  isArbitratedModeActivated,
  selectedXAxis,
  selectedYAxis,
  setDrawerState,
  textColor,
}: {
  nBoxWidth: number;
  drawerState: DrawerState;
  selectedXAxis: EnumerableField;
  selectedYAxis: EnumerableField;
  isArbitratedModeActivated: boolean;
  filterRef: MutableRefObject<FilterRef>;
  cell: MatrixData["matrix"][number][number];
  setDrawerState: (state: DrawerState) => void;
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={isArbitratedModeActivated ? "flex-start" : "center"}
      onClick={() =>
        setDrawerState({
          type: "n-box-cell-selected",
          collaborators: cell.reviews,
          xAxis: {
            id: selectedXAxis.uuid,
            optionLabel: selectedXAxis.scale.options.find((o) => o.value === cell.xValue)?.label,
            optionValue: cell.xValue,
            scaleLabel: selectedXAxis.label,
          },
          yAxis: {
            id: selectedYAxis.uuid,
            optionLabel: selectedYAxis.scale.options.find((o) => o.value === cell.yValue)?.label,
            optionValue: cell.yValue,
            scaleLabel: selectedYAxis.label,
          },
        })
      }
      className={classNames(styles.matrixCell, {
        [styles.noSelected]:
          (drawerState.type === "n-box-cell-selected" &&
            (cell.xValue !== drawerState.xAxis.optionValue ||
              cell.yValue !== drawerState.yAxis.optionValue)) ||
          drawerState.type === "n-box-not-positioned-cell-selected",
        [styles.overMedian]: cell.heatMapStatus === "over-median",
        [styles.p10]: cell.heatMapStatus === "p10",
        [styles.p90]: cell.heatMapStatus === "p90",
        [styles.selected]:
          drawerState.type === "n-box-cell-selected" &&
          cell.xValue === drawerState.xAxis.optionValue &&
          cell.yValue === drawerState.yAxis.optionValue,
        [styles.underMedian]: cell.heatMapStatus === "under-median",
      })}
    >
      {isArbitratedModeActivated ? (
        <MatrixCellAvatar cell={cell} textColor={textColor} filterRef={filterRef} />
      ) : (
        <MatrixCellDefault cell={cell} textColor={textColor} />
      )}
    </Flex>
  );
};

const XAxisLabels = ({ options }: { options: EnumerableField["scale"]["options"] }) => (
  <>
    {options.map((option) => (
      <Flex
        alignItems="center"
        justifyContent="center"
        key={`x-${option.label}`}
        className={styles.xScaleLabelContainer}
      >
        <p className={styles.xScaleLabel}>{option.label}</p>
      </Flex>
    ))}
  </>
);

const MatrixCells = ({
  cells,
  drawerState,
  filterRef,
  isArbitratedModeActivated,
  nBoxHeight,
  nBoxWidth,
  selectedXAxis,
  selectedYAxis,
  setDrawerState,
  textColor,
}: MatrixCellsProps) => {
  const cellsRender = useMemo(() => {
    const render = [];
    for (let index = 0; index < cells.length; index++) {
      const cell = cells[index];
      if (index % nBoxWidth === 0) {
        const label =
          selectedYAxis.scale.options[nBoxHeight - 1 - Math.floor(index / nBoxWidth)]?.label;
        render.push(<YAxisLabel label={label} key={`y-${label}`} />);
      }

      render.push(
        <MatrixCell
          cell={cell}
          filterRef={filterRef}
          textColor={textColor}
          nBoxWidth={nBoxWidth}
          drawerState={drawerState}
          selectedYAxis={selectedYAxis}
          selectedXAxis={selectedXAxis}
          setDrawerState={setDrawerState}
          key={`${cell.xValue}-${cell.yValue}`}
          isArbitratedModeActivated={isArbitratedModeActivated}
        />
      );
    }

    render.push(<div key="spacer" />);
    render.push(<XAxisLabels options={selectedXAxis.scale.options} />);
    return render;
  }, [
    cells,
    drawerState,
    nBoxHeight,
    nBoxWidth,
    selectedXAxis,
    selectedYAxis,
    setDrawerState,
    textColor,
    isArbitratedModeActivated,
    filterRef,
  ]);

  return <>{cellsRender}</>;
};

type MatrixCellProps = {
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
  cell: {
    fraction: number;
    reviews: MatrixData["notPositioned"]["reviews"];
    heatMapStatus: MatrixData["notPositioned"]["heatMapStatus"];
  };
};

const MatrixCellDefault = ({ cell, textColor }: MatrixCellProps) => {
  const { t } = useTranslation();

  const userInCellCount = cell.reviews.length;

  return (
    <>
      <DSTooltip
        direction="right"
        label={`${(cell.fraction ?? 0).toFixed(2)} %`}
        darkMode={cell.heatMapStatus === "p90" || cell.heatMapStatus === "over-median"}
      >
        <Text espaceFont="h2" color={textColor[cell.heatMapStatus]}>
          {Math.round(cell.fraction ?? 0)} %
        </Text>
      </DSTooltip>

      <Text espaceFont="captionBold" color={textColor[cell.heatMapStatus]}>
        {t("peopleReview.talentMatrix.positionedCollabDetails", {
          count: userInCellCount,
          defaultValue:
            userInCellCount > 1 ? "{{count}} collaborateurs" : "{{count}} collaborateur",
        })}
      </Text>
    </>
  );
};

type MatrixCellAvatarProps = {
  disabledFlexForEachItems?: boolean;
  filterRef: MutableRefObject<FilterRef>;
  cell: {
    reviews: MatrixData["notPositioned"]["reviews"];
    heatMapStatus: MatrixData["notPositioned"]["heatMapStatus"];
  };
  textColor: Record<
    MatrixData["notPositioned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  >;
};
const MatrixCellAvatar = ({
  cell,
  disabledFlexForEachItems,
  filterRef,
  textColor,
}: MatrixCellAvatarProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Flex flexWrap="wrap">
      { !!cell.reviews.length  && (
        cell.reviews.map((collaborator) => (
          <Flex
            margin="xxs"
            alignItems="center"
            key={collaborator.id}
            flex={disabledFlexForEachItems ? "0 0 10rem" : "1 1 10rem"}
          >
            <UserPopover
              position="down-right"
              properties={[collaborator.role]}
              user={{
                uuid: collaborator.id,
                fullName:
                  collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`,
                initials: "AB",
              }}
              profileBtn={{
                label: t("peopleReview.talentMatrix.drawer.avatar-popover.goto-talentgrid", {
                  defaultValue: "Voir dans le tableau d'arbitrage",
                }),
                onClick: () => {
                  filterRef.current.addFilter("fullName");
                  filterRef.current.setValue("fullName", {
                    type: "text",
                    operator: "contains",
                    value: collaborator.fullName,
                  });
                  history.push({
                    pathname: history.location.pathname.replace(views.matrix, views.grid),
                  });
                },
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <DSAvatar
                  size="S"
                  darkMode
                  type="Initials"
                  imageUrl={undefined}
                  initials={getInitials(collaborator)}
                  randomColorSeedString={collaborator.id}
                />
                <Text
                  marginLeft="xs"
                  espaceFont="captionBold"
                  color={textColor[cell.heatMapStatus]}
                >
                  {collaborator.fullName ?? `${collaborator.firstName} ${collaborator.lastName}`}
                </Text>
              </Flex>
            </UserPopover>
          </Flex>
        ))
      )}
    </Flex>
  );
};
