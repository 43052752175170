import { useState } from "react";
import { useToggle } from "react-use";

import { MdClose as Close } from "react-icons/md";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { DSModal, Popover, DSButton, DSAvatarGroup } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import {
  Link,
  Divider,
  FullSelect,
  SwitchButton,
  PopoverContent,
  ModalContainer,
} from "./SuggestionCard.styled";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  employees: Array<{
    uuid: string;
    fullName: string;
  }>;
}

const jobList = [
  { label: "Eleveur de grenouilles flamboyantes", value: "1" },
  { label: "Eleveur de poneys arc-en-ciel", value: "2" },
  { label: "Dompteur de licornes invisibles", value: "3" },
  { label: "Cueilleur de nuages en guimauve", value: "4" },
  { label: "Architecte de châteaux de sable volants", value: "5" },
  { label: "Peintre de zèbres à pois", value: "6" },
  { label: "Chef cuisinier de plats invisibles", value: "7" },
  { label: "Jardinier de plantes carnivores chantantes", value: "8" },
  { label: "Pilote de tapis volants", value: "9" },
  { label: "Dresseur de dragons miniatures", value: "10" },
  { label: "Explorateur de mondes parallèles", value: "11" },
  { label: "Collectionneur de rayons de lune", value: "12" },
  { label: "Fabricant de bonbons éternels", value: "13" },
  { label: "Inventeur de gadgets impossibles", value: "14" },
  { label: "Maître des illusions optiques", value: "15" },
  { label: "Chasseur de fantômes amicaux", value: "16" },
  { label: "Créateur de parfums interstellaires", value: "17" },
  { label: "Sculpteur de glace éternelle", value: "18" },
  { label: "Magicien des mots oubliés", value: "19" },
];

const ApproveSuggestionModal = ({ closeModal, employees, isOpen }: ModalProps) => {
  const { t } = useTranslation();

  const [loading, toggleLoading] = useToggle(false);
  const [opened, toggleOpen] = useToggle(false);
  const [closePopover, setClosePopover] = useState<() => void>();

  const handleSubmit = () => {
    toggleLoading(true);
    closeModal();
  };

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("skills.suggestions.modal.title", {
            defaultValue: "Traiter la suggestion",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <ModalContainer>
          <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
            {employees.length > 1
              ? t("skills.suggestions.employees", {
                  count: employees.length,
                  defaultValue: "{{count}} collaborateurs",
                })
              : t("skills.suggestions.employee", {
                  defaultValue: "1 collaborateur",
                })}
          </Text>
          <Flex marginVertical="s">
            <DSAvatarGroup
              size="S"
              maxItems={5}
              firstElementForeground
              totalItems={employees.length}
              users={employees.map((employee) => ({
                uuid: employee.uuid,
                fullName: employee.fullName,
                initials: getInitials(employee),
              }))}
            />
            <Popover
              actions={({ close }) => {
                setClosePopover(() => close);
              }}
              children={
                <Link>
                  {t("skills.suggestions.seeList", {
                    defaultValue: "Voir la liste",
                  })}
                </Link>
              }
              content={() => (
                <PopoverContent>
                  <Flex justifyContent="space-between">
                    <Text
                      marginBottom="xxs"
                      espaceFont="captionBold"
                      color="plainText-onLight-default"
                    >
                      {t("skills.suggestions.associatedEmployees", {
                        count: employees.length,
                        defaultValue: "{{count}} collaborateurs associés",
                      })}
                    </Text>
                    <DSButton iconOnly buttonSize="S" icon={<Close />} onClick={closePopover} />
                  </Flex>

                  {employees.map((employee) => (
                    <Text
                      padding="xxs"
                      espaceFont="captionRegular"
                      color="plainText-onLight-default"
                    >
                      {employee.fullName}
                    </Text>
                  ))}
                </PopoverContent>
              )}
            />
          </Flex>
          <Flex marginBottom="s" alignItems="center">
            <Flex flex={1} flexDirection="column">
              <Text espaceFont="captionRegular">
                {t("skills.suggestions.role", {
                  defaultValue: "Rôle",
                })}
              </Text>
              <Text espaceFont="body1Bold" color="plainText-onLight-default">
                Eleveur de grenouilles flamboyantes
              </Text>
            </Flex>
            <Flex marginTop="s" marginHorizontal="s">
              <ArrowForwardIcon />
            </Flex>
            <Flex flex={1} flexDirection="column">
              <Text espaceFont="captionRegular">
                {t("skills.suggestions.job", {
                  defaultValue: "Fiche de poste",
                })}
              </Text>
              <Flex width="100%">
                <FullSelect options={jobList} defaultValue={jobList[1]} />
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <SwitchButton
            active={opened}
            onToggle={() => toggleOpen(!opened)}
            label={t("skills.suggestions.modal.associateCollab", {
              defaultValue: "Associer les fiches de poste collaborateur par collaborateur",
            })}
          />
          {opened && (
            <Flex
              marginBottom="s"
              paddingVertical="m"
              alignItems="center"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Flex width="100%" justifyContent="space-between">
                <Flex width="50%">
                  <Text espaceFont="captionRegular">
                    {t("skills.suggestions.collab", {
                      defaultValue: "Collaborateur",
                    })}
                  </Text>
                </Flex>
                <Flex width="50%">
                  <Text espaceFont="captionRegular">
                    {t("skills.suggestions.job", {
                      defaultValue: "Fiche de poste",
                    })}
                  </Text>
                </Flex>
              </Flex>
              {employees.map((employee) => (
                <Flex width="100%" marginBottom="s" justifyContent="space-between">
                  <Flex width="50%" flexDirection="column">
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {employee.fullName}
                    </Text>
                  </Flex>
                  <Flex width="50%" flexDirection="column" align-items="flex-end">
                    <Flex width="100%">
                      <FullSelect options={jobList} defaultValue={jobList[1]} />
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          )}
        </ModalContainer>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("skills.suggestions.modal.cancel", {
            defaultValue: "Retour",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handleSubmit}
          label={t("skills.suggestions.modal.associate", {
            defaultValue: "Associer ces fiches de poste",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default ApproveSuggestionModal;
