import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DSTooltip, DSDropdown, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";

import { removeEmployees } from "services/peopleReview/useCampaignDetails/removeEmployee";
import { arbitrateEmployees } from "services/peopleReview/useCampaignDetails/arbitrateEmployees";

import { Review, Campaign, OpenModal } from "../../../types";

type ActionsMenuProps = {
  campaign: Campaign;
  openModal: OpenModal;
  params: GridRenderCellParams<Review>;
};

const ActionsMenu = ({ campaign, openModal, params }: ActionsMenuProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { isLoading: isArbitrateLoading, mutate: arbitrate } = useMutation(arbitrateEmployees, {
    onSuccess() {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
    },
  });
  const { isLoading: isRemoveLoading, mutate: remove } = useMutation(removeEmployees, {
    onSuccess() {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
    },
  });

  let arbitrateTooltip = "";
  const canArbitrate = campaign?.permissions.arbitrate.isEnabled;

  if (canArbitrate === false)
    arbitrateTooltip = t(campaign?.permissions?.arbitrate?.reasonKey, {
      defaultValue: "Vous n'avez pas les droits nécessaires pour arbitrer cette ligne.",
    });

  const isRowArbitrated = params.row.coordinatorStatus === "done";

  if (isRowArbitrated)
    arbitrateTooltip = t("peoplereview.talent-grid.row-actions.arbitrate.done", {
      defaultValue: "Le collaborateur a déjà été arbitré",
    });

  return (
    <DSDropdown buttonSize="S" overflow="initial">
      {campaign.permissions.arbitrate.isVisible && (
        <DSTooltip label={arbitrateTooltip}>
          <DSDropdownItem
            onClick={() => arbitrate({ campaignId: campaign.id, reviewIDs: [params.row.id] })}
            disabled={
              !campaign.permissions.arbitrate.isEnabled || isArbitrateLoading || isRowArbitrated
            }
            label={t("peopleReview.talentGrid.action.arbitrate.label", {
              defaultValue: `Passer la ligne au statut "Arbitré"`,
            })}
          />
        </DSTooltip>
      )}

      {campaign.hasManagerPreparation && campaign.permissions.arbitrate.isVisible && (
        <DSTooltip
          label={t("not.implemented", {
            defaultValue: "Bientôt disponible",
          })}
        >
          <DSDropdownItem
            disabled
            label={t("peoplereview.talent-grid.row-actions.reset-prep-manager", {
              defaultValue: "Rétablir la préparation manager",
            })}
          />
        </DSTooltip>
      )}

      {(campaign.permissions.arbitrate.isVisible || campaign.hasManagerPreparation) && (
        <DSDropdownDivider />
      )}

      {campaign.hasManagerPreparation &&
        campaign.permissions["change-manager"].isVisible &&
        (isRowArbitrated ? (
          <DSTooltip
            label={t("peoplereview.talent-grid.row-actions.change-manager.arbitrated", {
              defaultValue: "Impossible de changer le manager sur une ligne déjà arbitrée",
            })}
          >
            <DSDropdownItem
              disabled
              label={t("peoplereview.talent-grid.row-actions.change-manager", {
                defaultValue: "Changer le manager",
              })}
            />
          </DSTooltip>
        ) : (
          <DSDropdownItem
            disabled={!campaign.permissions["change-manager"].isEnabled}
            onClick={() => openModal("changeManager", [params.row])}
            label={t("peoplereview.talent-grid.row-actions.change-manager", {
              defaultValue: "Changer le manager",
            })}
          />
        ))}

      {campaign.permissions["delete-reviews"].isVisible && (
        <DSDropdownItem
          disabled={!campaign.permissions["delete-reviews"].isEnabled || isRemoveLoading}
          onClick={() => remove({ campaignID: campaign.id, reviewIDs: [params.row.id] })}
          label={t("peoplereview.talent-grid.row-actions.remove-employee", {
            defaultValue: "Retirer le collaborateur de cette campagne",
          })}
        />
      )}
    </DSDropdown>
  );
};

/**
 * We should hide this column if nothing is visible in it
 */
export function getActionMenuVisibility(campaign: Campaign) {
    return campaign.permissions.arbitrate.isVisible || 
    campaign.permissions["change-manager"].isVisible ||
    campaign.permissions["delete-reviews"].isVisible 
}

export function getActionMenuColDef({
  campaign,
  openModal,
}: {
  campaign: Campaign;
  openModal: OpenModal;
}): GridColDef<Review> {
  return {
    type: "actions",
    field: "actions",
    filterable: false,
    headerAlign: "right",
    hideable: false,
    renderCell: (params) => (
      <ActionsMenu params={params} campaign={campaign} openModal={openModal} />
    ),
    sortable: false,
    width: 50,
  };
}
