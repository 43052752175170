import { Flex } from "@skillup/ui";

import { CardProps } from "./Card";
import { PieChart } from "../Charts/Pie";
import { CardMenu } from "../CardMenu";

import styles from "./DetailedIndicator.module.scss";
import { HistogramChart } from "../Charts/Histogram";

export const DetailedIndicator = ({ data, loading, card }: CardProps) => {
  const isPieChart =
    card.charts.includes("pie") &&
    card.question_meta.axes[1]?.fingerprint?.distinct_values &&
    card.question_meta.axes[1].fingerprint.distinct_values.length < 4;
  const isPaginated = !isPieChart && data?.data.length > 8;

  return (
    <Flex column className={styles.detailedIndicator}>
      <Flex row className={styles.header}>
        <div className={styles.title}>{card.short_name}</div>
        <CardMenu data={data} />
      </Flex>

      {data && isPieChart && <PieChart loading={loading} data={data} card={card} />}
      {data && !isPieChart && (
        <HistogramChart loading={loading} data={data} card={card} isPaginated={isPaginated} />
      )}
    </Flex>
  );
};
