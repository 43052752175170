import { useEffect, useMemo } from "react";
import { MdInfoOutline as InfoOutline } from "react-icons/md";

import useTranslation from "hooks/useTranslation";

import { FormikInputSelect, FormikInputText, Text, Flex } from "@skillup/design-system";
import { Label, Select } from "@skillup/ui";

import type { CardConfigForm } from "../../../types";
import { useTrainingDashboardContext } from "../../../context";
import style from "../../../views/Configuration.module.scss";
import { Field, getIn } from "formik";

type Props = {
  path: string;
  card: CardConfigForm;
  setFieldValue: (field: string, value: any) => void;
  header?: boolean;
};

export const DetailedIndicator = ({ path, card, header, setFieldValue }: Props) => {
  const { t } = useTranslation();

  const {
    fields,
    detailedIndicators,
    detailedIndicatorsUtilities: { indicatorDimensionMap },
  } = useTrainingDashboardContext();

  useEffect(() => {
    if (card.dimension) {
      const dimensions = Object.keys(indicatorDimensionMap[card.indicator]);
      if (!dimensions.includes(card.dimension)) {
        setFieldValue(`${path}.dimension`, null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.indicator]);

  useEffect(() => {
    if (card.dimension) {
      const question = detailedIndicators.find(
        (question) => question.fields[0] === card.indicator && question.fields[1] === card.dimension
      );

      if (card.question_name !== question?.name) {
        setFieldValue(path, {
          ...card,
          question_name: question.name,
          short_name: question.recommended_short_name,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.dimension]);

  const indicatorOptions = useMemo(() => {
    return Object.keys(indicatorDimensionMap).map((key) => {
      return {
        label: fields.find((field) => field.name === key)?.recommended_display_name,
        value: key,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return header ? (
    <div className={style.CardKeyIndicatorTitleInput} key={card.id}>
      <Label
        label={t("training.dashboard.configuration.card.key-indicator.header.label.input", {
          defaultValue: "Libellé du graphique",
        })}
        required
      />
      <FormikInputText name={`${path}.short_name`} value={card.short_name} />
    </div>
  ) : (
    <Flex flexDirection="column" gap={"xs"}>
      <div>
        <Label
          label={t("training.dashboard.configuration.card.detail-indicators.label.indicator", {
            defaultValue: "Choix de l'indicateur",
          })}
          required
        />
        <FormikInputSelect
          name={`${path}.indicator`}
          value={card.indicator}
          options={indicatorOptions}
        />
      </div>
      <div>
        <Label
          label={t("training.dashboard.configuration.card.detail-indicators.label.dimension", {
            defaultValue: "Choix de la dimension",
          })}
          required
        />
        <Field
          name={`${path}.dimension`}
          options={Object.keys(indicatorDimensionMap[card.indicator]).map((key) => ({
            label: fields.find((field) => field.name === key)?.recommended_display_name,
            value: key,
          }))}
        >
          {({ field, form }) => (
            <>
              <Select
                value={card.dimension}
                options={Object.keys(indicatorDimensionMap[card.indicator]).map((key) => ({
                  label: fields.find((field) => field.name === key)?.recommended_display_name,
                  value: key,
                }))}
                error={getIn(form.errors, field.name)}
                onChange={(val) => {
                  form.setFieldValue(field.name, val);
                }}
              />
              {getIn(form.errors, field.name) && (
                <Text fontSize="0.75rem" espaceFont="body1Regular" color="status-error-darker">
                  <Flex gap="xs" alignItems="center">
                    <InfoOutline />
                    {getIn(form.errors, field.name)}
                  </Flex>
                </Text>
              )}
            </>
          )}
        </Field>
      </div>
    </Flex>
  );
};
