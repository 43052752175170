import moment from "moment";
import { Currency, getMonetarySymbol, NumberUtil } from "@skillup/shared-utils";
import { DSTooltip } from "@skillup/ui";
import { QuestionData } from "../../../types";
import { GridColDef } from "@mui/x-data-grid-pro";

import styles from "./Summary.module.scss";

export const generateColumns = (t, data: QuestionData, currency: Currency): GridColDef<any>[] => {
  const currencySymbol = getMonetarySymbol({ currency });

  return data?.columns?.map((column) => ({
    field: column.name,
    sortable: false,
    headerName: t(`training.dashboard.grid.column.header.${column.name}`, {
      // type should be monetary for currency columns
      defaultValue: column.type === "number" ? `${column.name} ({{currency}})` : column.name,
      currency: currencySymbol,
    }),
    renderCell: ({ row }) => (
      <DSTooltip label={row.data[column.name]} className={styles.TooltipCell} enabledOnOverflow>
        {row.data[column.name]}
      </DSTooltip>
    ),
    align: column.name === "training_plan_plan_completion_phase" ? "left" : "right",
    headerAlign: "right",
  }));
};

export const generateRows = (t, data: QuestionData) => {
  if (!data) return [];

  return data?.data.map((row, index) => ({
    id: index,
    data: row.reduce((acc, value, i) => {
      if (data?.columns[i].type === "duration") {
        const hours = moment.duration(value).asHours();
        const roundedHours = Math.ceil(hours);
        acc[data.columns[i].name] = NumberUtil.decimalNumberToFormatedString(roundedHours);
        return acc;
      }

      if (data?.columns[i].type === "number") {
        const roundedNumber = Math.ceil(Number(value));
        acc[data.columns[i].name] = NumberUtil.decimalNumberToFormatedString(roundedNumber);
        return acc;
      }

      if (data?.columns[i].name === "training_plan_plan_completion_phase") {
        switch (value) {
          case "plan-approved":
            acc[data.columns[i].name] = t("dashboard-summary-plan-approved", {
              defaultValue: "À inscrire",
            });
            break;
          case "plan-attended":
            acc[data.columns[i].name] = t("dashboard-summary-plan-attended", {
              defaultValue: "Réalisées",
            });
            break;
          case "plan-processing":
            acc[data.columns[i].name] = t("dashboard-summary-plan-processing", {
              defaultValue: "Inscrit",
            });

            break;
          case "plan-denied":
            acc[data.columns[i].name] = t("dashboard-summary-plan-denied", {
              defaultValue: "Annulées",
            });
            break;
          case "plan-positioning":
            acc[data.columns[i].name] = t("dashboard-summary-plan-positioning", {
              defaultValue: "Choix de session en cours",
            });
            break;
          case "total":
            acc[data.columns[i].name] = t("dashboard-summary-total", {
              defaultValue: "Total plan",
            });
            break;
          default:
            acc[data.columns[i].name] = value;
            break;
        }
        return acc;
      }

      acc[data.columns[i].name] = value;
      return acc;
    }, {}),
  }));
};
