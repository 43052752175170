import { useMemo } from "react";
import moment from "moment";
import { MdAccountBalanceWallet, MdGroup, MdInfo, MdTimelapse, MdWebStories } from "react-icons/md";

import { Flex, Loader } from "@skillup/ui";
import { formatMonetary } from "@skillup/shared-utils";

import useSettings from "hooks/useSettings";

import { CardProps } from "./Card";

import styles from "./KeyIndicator.module.scss";

const icons = {
  default: MdInfo,
  total_number_of_concerned_employees: MdGroup,
  total_sum_of_additional_expenses: MdAccountBalanceWallet,
  total_sum_of_financed_amount: MdAccountBalanceWallet,
  total_sum_of_prices: MdAccountBalanceWallet,
  total_sum_of_real_costs: MdAccountBalanceWallet,
  total_sum_of_realized_duration: MdTimelapse,
  total_training_number_of_line: MdWebStories,
};

export const KeyIndicator = ({ data, card, loading }: CardProps) => {
  const type = card.question_meta.axes[0].data_type;
  const {
    settings: { currency },
  } = useSettings();

  const row = data?.data[0];
  const [value] = row ?? [];

  const actualValue = useMemo(() => {
    if (type === "money") {
      return formatMonetary(value, { currency });
    }

    if (type === "duration") {
      return moment.duration(value).asHours() + "h";
    }

    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, type]);

  const Icon = icons[card.question_meta.id] || icons.default;

  return (
    <Flex column className={styles.keyIndicator}>
      <Flex className={styles.header}>
        <Icon className={styles.icon} />
        {loading && <Loader />}
        {!loading && <p className={styles.value}>{actualValue}</p>}
      </Flex>
      <p>{card.short_name}</p>
    </Flex>
  );
};
