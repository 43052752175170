import { MdLock as Lock } from "react-icons/md";
import { useCallback, useEffect, useMemo, useState } from "react";
import { match } from "react-router-dom";
import { isNil, isUndefined } from "lodash";
import { useToasts } from "react-toast-notifications";

import { DateTime } from "@skillup/shared-utils";
import { InterviewWithStates } from "@skillup/types";
import { useModal, DSTooltip, DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import {
  type CampaignsRoutes,
  type UserNotificationRoutes,
  CampaignExportStates,
} from "@skillup/espace-rh-bridge";

import { IWithRouter } from "utils/types";
import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";
import useFetch from "hooks/useFetch";
import { useTypedFetch } from "hooks";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

import CampaignDetails from "./components/CampaignDetails";
import CompletionRates from "./components/CompletionRates";
import Interviews from "./components/Interviews";
import DSLayout from "components/DSLayout";
import Loader from "components/Loader";
import { EditionContext } from "./components/modals/utils";
import useCampaignModals from "./components/modals/useCampaignModals";
import CampaignActions from "./components/CampaignActions";
import { ExportAsyncProvider, useExportAsyncContext } from "./ExportAsyncContext";

import styles from "./CampaignStyles.module.scss";

interface CampaignProps extends IWithRouter {
  match: match<{ campaignUuid?: string }>;
}

const Campaign = ({ match: { params }, history }: CampaignProps): JSX.Element => {
  const { campaignUuid } = params;
  const { userNotificationState, userNotificationUuid, showAsyncExportAlert } =
    useExportAsyncContext();
  const [alertVisibility, setAlertVisibility] = useState(showAsyncExportAlert);

  useEffect(() => {
    setAlertVisibility(showAsyncExportAlert);
  }, [showAsyncExportAlert, userNotificationState, setAlertVisibility]);

  const { settings, userAccessModules } = useSettings();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const {
    data: campaign,
    loading: fetchingCampaign,
    refetch: refetchCampaign,
  } = useTypedFetch<CampaignsRoutes.GetByUuid>({
    method: "GET",
    path: "/campaigns/{campaignUuid}",
    params: { campaignUuid },
  });
  const isCampaignClosed = useMemo(() => !isNil(campaign?.archivedAt), [campaign]);

  const { data: interviews = [], refetch: refetchInterviews } = useFetch<InterviewWithStates[]>({
    url: `/v1/campaigns/${campaignUuid}/interviews`,
  });

  const title = useMemo(() => {
    const archivedDate = DateTime.fromISO(campaign?.archivedAt).toFormat("dd/MM/yyyy");
    const isCampaignClosed = !isNil(campaign?.archivedAt);
    const tooltipLockMessage = !isNil(campaign?.closedBy?.[0]?.fullName)
      ? `Cette campagne a été clôturée par ${campaign?.closedBy?.[0]?.fullName} le ${archivedDate}`
      : "Cette campagne a été clôturée";

    const mainTitle = (
      <div className={styles.mainTitle}>
        <div className={styles.titleContainer}>
          {isCampaignClosed && (
            <DSTooltip
              className={styles.tooltip}
              label={tooltipLockMessage}
              direction="right"
              tooltipClassName={styles.tooltip}
            >
              <Lock className={styles.lockIcon} />
            </DSTooltip>
          )}
          <span>{campaign?.titleForHR}</span>
        </div>
      </div>
    );

    if (campaign?.titleForHR !== campaign?.titleForAttendees) {
      return (
        <div className={styles.title}>
          {mainTitle}
          <small>
            (Titre côté participants : <em>{campaign?.titleForAttendees}</em>)
          </small>
        </div>
      );
    } else {
      return mainTitle;
    }
  }, [campaign?.archivedAt, campaign?.closedBy, campaign?.titleForHR, campaign?.titleForAttendees]);

  const resync = useCallback(async () => {
    refetchCampaign();
    refetchInterviews();
  }, [refetchCampaign, refetchInterviews]);

  const { isOpen, show, hide } = useModal();
  const [state, setState] = useState<EditionContext>(EditionContext.IDLE);
  const modalToDisplay = useCampaignModals({
    resync,
    campaign,
    context: state,
    onClose: () => {
      setActionsState(EditionContext.IDLE);
    },
  });
  const setActionsState = useCallback(
    (stateChange: EditionContext) => {
      setState(stateChange);
      if (stateChange === EditionContext.IDLE) {
        return hide();
      }
      show();
      setState(stateChange);
    },
    [show, hide]
  );

  const onDelete = useCallback(() => history.push("/responsable/campagnes"), [history]);

  const menuItems = useMemo(() => {
    if (campaign) {
      return CampaignActions({ campaign, interviews, onDelete, resync, setActionsState });
    }
    return null;
  }, [campaign, interviews, onDelete, resync, setActionsState]);
  if (fetchingCampaign || isUndefined(campaign)) {
    return <Loader />;
  }

  return (
    <>
      <DSLayout
        title={title}
        parent={{
          title: "Suivi des entretiens",
          onClick: () => history.push("/responsable/campagnes"),
        }}
        layouts={
          !campaign
            ? []
            : [
                {
                  primaryButton: UserAccessChecker.Dashboards.toInterviewDashboard() ? (
                    <DSNewHeaderButton
                      label="Accéder au dashboard"
                      onClick={() => {
                        history.push(`/responsable/dashboard/interview/${campaign.uuid}`);
                      }}
                    />
                  ) : undefined,
                  dropdownContent: menuItems,
                },
              ]
        }
      >
        {fetchingCampaign ? (
          <Loader />
        ) : (
          <div className={styles.Campaign}>
            {alertVisibility && (
              <AsyncDownloadAlert
                userNotificationState={userNotificationState}
                setShowAsyncExportAlert={setAlertVisibility}
                userNotificationUuid={userNotificationUuid}
              />
            )}
            <div className={styles.Wrapper}>
              {!campaign ? (
                <h5>Cette page n'existe pas ou a été supprimée</h5>
              ) : (
                <main>
                  <section className={styles.summary}>
                    <CampaignDetails isLoading={fetchingCampaign} campaign={campaign} />
                    <CompletionRates interviews={interviews} campaignIsClosed={isCampaignClosed} />
                  </section>

                  <section className={styles.interviewsTable}>
                    {campaign && (
                      <>
                        <Interviews
                          campaign={{
                            uuid: campaign.uuid,
                            titleForHR: campaign.titleForHR,
                            type: campaign.type,
                            template: campaign.template,
                            archivedAt: campaign.archivedAt,
                          }}
                          interviews={interviews}
                          resync={refetchInterviews}
                        />
                      </>
                    )}
                  </section>
                </main>
              )}
            </div>
          </div>
        )}
      </DSLayout>
      {isOpen && modalToDisplay}
    </>
  );
};

export default (p: CampaignProps) => {
  const {
    match: {
      params: { campaignUuid },
    },
  } = p;

  return (
    <ExportAsyncProvider campaignUuid={campaignUuid}>
      <Campaign {...p} />
    </ExportAsyncProvider>
  );
};

const AsyncDownloadAlert = ({
  userNotificationUuid,
  userNotificationState,
  setShowAsyncExportAlert,
}: {
  userNotificationUuid: string;
  userNotificationState: string;
  setShowAsyncExportAlert: (state: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast } = useToasts();
  const downloadFileFromNotif = useCallback(() => {
    if (isLoading || !userNotificationUuid) return;

    setIsLoading(true);

    downloadTypedFileAsUser<UserNotificationRoutes.DownloadFile>(
      {
        method: "GET",
        path: "/user-notification/{userNotificationUuid}/download",
        params: { userNotificationUuid },
      },
      {
        target: "API",
        deduceFileDataFromResponseHeaders: true,
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    )
      .catch(() => {
        addToast("Une erreur est survenue pendant le téléchargement du fichier.", {
          appearance: "error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [userNotificationUuid, isLoading, setIsLoading, addToast]);

  if (userNotificationState === CampaignExportStates.IN_PROGRESS) {
    return (
      <DSAlert
        type={DSAlertType.TIMER}
        display={DSAlertDisplay.FULL_BLEED}
        closeButton
        onClose={() => setShowAsyncExportAlert(false)}
      >
        <p>
          La génération du fichier a commencé, cette opération peut prendre du temps.
          <br />
          Vous recevrez un mail quand le fichier sera prêt à être téléchargé : vous pouvez donc
          quitter cette page.
        </p>
      </DSAlert>
    );
  } else if (userNotificationState === CampaignExportStates.READY) {
    return (
      <DSAlert
        type={DSAlertType.SUCCESS}
        display={DSAlertDisplay.FULL_BLEED}
        alignButtons={true}
        buttonLabel="Télécharger le fichier"
        closeButton
        onAction={downloadFileFromNotif}
        disabledActionButton={isLoading}
        onClose={() => setShowAsyncExportAlert(false)}
      >
        <p>Votre fichier d'export est prêt à être téléchargé. Il sera supprimé dans 24 heures.</p>
      </DSAlert>
    );
  } else if (userNotificationState === CampaignExportStates.ERRORED) {
    return (
      <DSAlert
        type={DSAlertType.ERROR}
        display={DSAlertDisplay.FULL_BLEED}
        closeButton
        onClose={() => setShowAsyncExportAlert(false)}
      >
        <p>
          Erreur dans la génération du fichier. Veuillez réessayer. Si l'erreur persiste, veuillez
          contacter le support.
        </p>
      </DSAlert>
    );
  }
  return <></>;
};
