import { GridColDef } from "@mui/x-data-grid-pro";

import type { Review, Campaign } from "../../../types";

export function getCampaignFieldsColDefs(campaign: Campaign): GridColDef<Review>[] {
  return campaign.fields.map((field) => {
    if (field.kind === "enumerable") {
      return {
        type: "singleSelect",
        editable: campaign.permissions.review.isEnabled,
        field: field.uuid,
        flex: 1,
        getOptionLabel: (option: (typeof field.scale.options)[number]) => option?.label ?? "",
        getOptionValue: (option: (typeof field.scale.options)[number]) => option?.value ?? "",
        headerName: field.label,
        minWidth: 150,
        valueGetter: (params) => params.row.fields[field.uuid],
        valueOptions: field.scale.options
          /**
           * @wip isActive should be filtered in backend ?
           * @wip isActive should be filtered in backend ?
           * @wip isActive should be filtered in backend ?
           * @wip isActive should be filtered in backend ?
           */
          .filter((option) => option.isActive)
          .map(({ label, value }) => ({ label, value: value.toString() })),
        valueSetter: (params) => {
          return {
            ...params.row,
            fields: {
              ...params.row.fields,
              [field.uuid]: params.value,
            },
          };
        },
      };
    } else {
      return {
        type: "string",
        editable: campaign.permissions.review.isEnabled,
        field: field.uuid,
        flex: 1,
        headerName: field.label,
        minWidth: 100,
      };
    }
  });
}
