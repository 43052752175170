import { GridColDef } from "@mui/x-data-grid-pro";

import { Review } from "../../../types";

export const coordinatorCommentColDef: GridColDef<Review> = {
  type: "string",
  cellClassName: "fake-editable--cell",
  field: "coordinatorComment",
  filterable: false,
  flex: 1,
  headerName: "Commentaire coordinateur",
  minWidth: 200,
  sortable: false,
};
