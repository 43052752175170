import React, { useMemo } from "react";
import cx from "classnames";

import {
  DataGridPro,
  type DataGridProProps,
  type GridColDef,
  type GridSortModel,
  LicenseInfo,
} from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import { DSColor, SkuiSpacing } from "components/DesignSystemContext";
import {
  Toolbar,
  type ToolbarButtonProps,
  type FooterProps,
  DSColumnMenu,
  Footer,
  EmptyOverlay,
  ErrorOverlay,
} from "./components";

import { type DataGridProps } from "./types";

import { usePersistColumnSettings } from "./hooks/usePersistColumnSettings";

import styles from "./DSDatagrid.module.scss";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? "");
/**
 * Documentation here: https://mui.com/x/api/data-grid/data-grid-pro/#css
 *
 * Others useful docs here:
 * DataGrid: https://www.notion.so/skillupco/DataGrid-bdd724e84f944df88769ca968a7495b2
 * DataGrid styling tips: https://www.notion.so/skillupco/Styling-0c8d24b6ae0b4f2da4f79219559cfe0a
 *
 * It basically works like a stylesheet, to understand wich css rule does what, you can use the inspector
 * and check the class name of the element you want to style. Then you can search for it in the documentation
 * and see what things it is changing
 */

const StyledDataGrid = styled(DataGridPro)(
  ({ editable, isRowClickable }: { editable: boolean; isRowClickable: boolean }) => ({
    background: "unset",
    border: "unset",
    "& .MuiDataGrid-main": {
      backgroundColor: DSColor["surface-light"],
      outline: `1px solid ${DSColor["border-onLight"]}`,
      borderRadius: "var(--unstable_DataGrid-radius) !important",
      marginRight: "1px",
      marginLeft: "1px",
    },
    // Header
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-withBorderColor
    "& .MuiDataGrid-columnHeader .MuiDataGrid-withBorderColor": {
      borderColor: "unset",
      borderBottomWidth: "unset",
      borderBottomStyle: "unset",
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-columnHeaders
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: DSColor["surface-light-darker"],
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-pinnedColumnHeaders
    "& .MuiDataGrid-pinnedColumnHeaders > div[role='row']:not([aria-rowindex='1'])": {
      borderTop: `1px solid ${DSColor["border-onLight"]}`,
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-columnHeader
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: DSColor["surface-light-darker"],
      color: DSColor["plainText-onLight-lighter"],
      fontSize: "0.75rem",
    },
    "& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox)": {
      padding: `${SkuiSpacing.xs} ${SkuiSpacing.s}`,
    },
    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within": {
      outline: "none",
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-columnHeadersInner
    "& .MuiDataGrid-columnHeadersInner": {
      position: "absolute",
      top: 0,
    },
    "& .MuiDataGrid-columnHeadersInner > div[role='row']:not([aria-rowindex='1'])": {
      borderTop: `1px solid ${DSColor["border-onLight"]}`,
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-pinnedColumns
    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: DSColor["surface-light-darker"],
      overflow: "visible",
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-columnHeader--sorted
    "& .MuiDataGrid-columnHeader--sorted": {
      color: DSColor["plainText-onLight-default"],
    },
    // Header icons
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-iconButtonContainer
    "& .MuiDataGrid-iconButtonContainer": {
      marginLeft: "2px",
      visibility: "visible !important",
      width: "auto !important",
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-iconButtonContainer
    "& .MuiDataGrid-iconButtonContainer svg[data-testid='SwapVertIcon']": {
      opacity: "0.3 !important",
    },
    // Rows
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-MuiDataGridPro-row
    "& .MuiDataGrid-row:hover": isRowClickable && {
      cursor: "pointer",
    },
    // Cells
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-cell
    "& .MuiDataGrid-cell": {
      color: DSColor["plainText-onLight-default"],
      fontSize: "0.875rem",
    },
    "& .MuiDataGrid-editInputCell": {
      fontFamily: ["Noto Sans Display"],
    },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-cell--editing
    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing, .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within":
      {
        outline: `2px solid ${DSColor["action-onLight-lighter"]}`,
        outlineOffset: "-2px",
      },
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-cell--editable
    "& .MuiDataGrid-cell--editable:hover, & .MuiDataGrid-cell--editable:focus-within, & .MuiDataGrid-cell.fake-editable--cell:hover,  & .MuiDataGrid-cell.fake-editable--cell:focus-within":
      {
        outline: `2px solid ${DSColor["action-onLight-default"]}`,
        outlineOffset: "-2px",
      },
    // Footer
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-footerContainer
    "& .MuiDataGrid-footerContainer": {
      borderTop: "unset",
    },
    // Scrollable
    // Docs: https://mui.com/x/api/data-grid/data-grid-pro/#DataGridPro-css-virtualScroller
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: DSColor["surface-light"],
      flex: "1",
      overflow: "auto !important",
    },
    // Custom: Not referenced in the docs
    // Checkbox
    "& .MuiCheckbox-root.Mui-checked": {
      color: DSColor["decoration-blue-base"],
    },
    // Hide Mui fieldset borders when editing dropdown fields
    "& fieldset": {
      border: "unset",
    },
    "& .MuiDataGrid-overlayWrapper": {
      height: "100%",
    },
    " & .MuiDataGrid-overlayWrapperInner": {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: "fit-content",
    },
    "& .MuiLinearProgress-root": {
      width: "100%",
    },
    "& .MuiDataGrid-cell.MuiDataGrid-cell--editable": editable && {
      cursor: "cell",
    },
    // readonly styling
    "& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editing):not(.MuiDataGrid-cell--editable):not(.fake-editable--cell)":
      editable && {
        outline: "none",
      },
  })
);

const DSDataGrid = React.forwardRef<
  HTMLDivElement,
  DataGridProps & ToolbarButtonProps & FooterProps
>(({ theme, ...props }, ref) => {
  const {
    editable = false,
    apiRef,
    columns,
    onFilter,
    onSort,
    onPageChange,
    loading,
    persistenceId,
    isError,
    toolbarButtons,
    paginationModel,
    pagination,
    entityName,
    sorting,
    slots,
    emptyOverlay,
    errorOverlay,
    onCellClick,
    rows,
    onRowClick,
    ...dataGridProps
  } = props;

  const handleSortModelChange = (model: GridSortModel) => {
    const [sortOptions] = model;
    switch (sortOptions.sort) {
      case "asc":
        onSort?.(sortOptions.field, "ASC");
        break;
      case "desc":
        onSort?.(sortOptions.field, "DESC");
        break;
      default:
        onSort?.(sortOptions.field, undefined);
    }
  };

  const overlay = props.isError ? (
    <ErrorOverlay text={errorOverlay?.text} button={errorOverlay?.button} />
  ) : (
    <EmptyOverlay text={emptyOverlay?.text} button={emptyOverlay?.button} />
  );

  const customizedProps = useMemo(() => {
    const serverPaginationProps: any = onPageChange
      ? {
          paginationMode: "server",
          onPaginationModelChange: (model) => onPageChange(model.page, model.pageSize),
        }
      : {};
    return {
      ...dataGridProps,
      autoHeight: true,
      sx: { "--DataGrid-overlayHeight": "fit-content" },
      sortingMode: props.onSort ? "server" : props.sortingMode,
      onSortModelChange: props.onSort ? handleSortModelChange : props.onSortModelChange,
      sortModel: sorting
        ? [
            {
              field: sorting.property,
              sort: sorting.direction === "DESC" ? "desc" : "asc",
            },
          ]
        : props.sortModel,
      slots: {
        toolbar: Toolbar,
        columnUnsortedIcon: ({ sortingOrder, ...other }) => <SwapVertIcon {...other} />,
        footer: Footer,
        loadingOverlay: LinearProgress,
        noRowsOverlay: () => overlay,
        noResultsOverlay: () => overlay,
        columnMenu: DSColumnMenu(theme, onFilter, onSort, sorting),
        ...props.slots,
      },
      onCellClick,
      onRowClick,
      slotProps: {
        toolbar: {
          entityName: entityName,
          buttons: props.toolbarButtons,
          actionItems: props.toolbarActionItems,
          translationPrefix: props.translationPrefix,
          disableToolbar: props.disableToolbar,
        },
        footer: {
          entityName: entityName,
          translationPrefix: props.translationPrefix,
        },
        ...props.slotProps,
      },
      pagination: pagination,
      ...serverPaginationProps,
    } satisfies DataGridProProps;
  }, [
    slots,
    sorting,
    entityName,
    theme,
    toolbarButtons,
    pagination,
    paginationModel,
    loading,
    isError,
    onCellClick,
    onRowClick,
    dataGridProps,
  ]);

  const dsColumns = useMemo(() => {
    return columns?.map((column) => ({
      ...column,
      sortingOrder: ["asc", "desc"],
    })) as GridColDef<any>[];
  }, [columns]);

  usePersistColumnSettings({
    apiRef,
    persistenceId,
    // @ts-expect-error
    columns,
  });

  return (
    <StyledDataGrid
      editable={editable}
      isRowClickable={!!onRowClick}
      apiRef={apiRef}
      rowHeight={40}
      className={cx(styles.DSDataGrid, styles[theme])}
      columnHeaderHeight={38}
      {...customizedProps}
      columns={dsColumns}
      rows={rows}
      ref={ref}
    />
  );
});

export { DSDataGrid };
