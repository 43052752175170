import { useState, useCallback } from "react";
import { useToasts } from "react-toast-notifications";

import { MdDelete as Delete } from "react-icons/md";

import { Text } from "@skillup/design-system";
import {
  Flex,
  DSModal,
  DSButton,
  DSTextInput,
  DSNumberInput,
  DSFormGroupTextInput,
} from "@skillup/ui";

import { Scale, useCoreHRScales } from "services/coreHR";

import styles from "./CreateScaleModal.module.scss";

type Option = Scale["options"][0] & {
  label_error?: string;
  value_error?: string;
};

type ModalState = { step: "idle" } | { step: "creating" };

export function CreateScaleModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { actions } = useCoreHRScales();
  const { addToast } = useToasts();

  const [label, setLabel] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [state, setState] = useState<ModalState>({ step: "idle" });
  const oneOptionHasError = options.some((option) => option.label_error || option.value_error);
  const noOptions = options.length === 0;
  const cannotCreate = oneOptionHasError || noOptions;
  const errors = `${oneOptionHasError ? "Une ou plusieurs options ont des erreurs" : ""}\n${
    noOptions ? "Aucune option n'a été ajoutée" : ""
  }}`;

  function setOptionLabel(index: number, newLabel: string) {
    options[index]["label"] = newLabel;
    if (!newLabel.length) {
      options[index]["label_error"] = "Le label ne peut pas être vide";
    } else {
      options[index]["label_error"] = undefined;
    }
    setOptions([...options]);
  }

  function setOptionClientCode(index: number, newClientCode: number) {
    options[index]["value"] = newClientCode;
    if (!newClientCode.toString().length) {
      options[index]["value_error"] = "Le code client ne peut pas être vide";
    } else {
      options[index]["value_error"] = undefined;
    }
    setOptions([...options]);
  }

  function addNewOption() {
    const maxOptionValue = options.reduce(
      (max, option) => (option.value > max ? option.value : max),
      0
    );
    setOptions([...options, { isActive: true, label: "Option", value: maxOptionValue + 1 }]);
  }

  function deleteOption(index: number) {
    options.splice(index, 1);
    setOptions([...options]);
  }

  const clearValue = useCallback(() => {
    setLabel("");
    setOptions([]);
  }, [setLabel, setOptions]);

  const handleCreate = useCallback(async () => {
    setState({ step: "creating" });
    const result = await actions.create({
      label,
      options: options.map((option) => ({
        isActive: true,
        label: option.label,
        value: option.value,
      })),
    });

    if (result.isLeft()) {
      setState({ step: "idle" });
      addToast("Erreur lors de la création de l'échelle", { appearance: "error" });
      return;
    }

    setState({ step: "idle" });
    addToast("Echelle créée avec succès", { appearance: "success" });
    clearValue();
    onClose();
  }, [actions, label, options, onClose, addToast, clearValue]);

  return (
    <DSModal isOpen={isOpen} className={styles.createScaleModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Créer une nouvelle échelle" />
      </DSModal.Header>
      <DSModal.Content>
        <DSFormGroupTextInput required label="Nom de l'échelle">
          <DSTextInput name="scale-label" defaultValue={label} onChange={setLabel} />
        </DSFormGroupTextInput>
        <Text espaceFont="body1Regular">Options de l'échelle</Text>
        {options.map((option, index) => (
          <Flex column className={styles.optionForm}>
            {/* <Text espaceFont="body2Regular">Option {index + 1}</Text> */}
            <Flex row>
              <DSFormGroupTextInput required label="Label" assistiveText={option.label_error}>
                <DSTextInput
                  defaultValue={option.label}
                  error={!!option.label_error}
                  name={`scale-option-${index}-label`}
                  className={styles.optionForm__input}
                  onChange={(newLabel) => setOptionLabel(index, newLabel)}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput required label="Code client" assistiveText={option.value_error}>
                <DSNumberInput
                  min={0}
                  defaultValue={option.value}
                  error={!!option.value_error}
                  className={styles.optionForm__input}
                  name={`scale-option-${index}-client-code`}
                  onChange={(newClientCode) =>
                    setOptionClientCode(index, parseInt(newClientCode, 10))
                  }
                />
              </DSFormGroupTextInput>

              <DSButton
                iconOnly
                emphasis="Low"
                buttonSize="S"
                icon={<Delete />}
                onClick={() => deleteOption(index)}
              />
            </Flex>
          </Flex>
        ))}

        <DSButton emphasis="Low" buttonSize="S" label="Ajouter une option" onClick={addNewOption} />
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Annuler" onClick={onClose} />
        <DSModal.Footer.PrimaryButton
          label="Enregistrer"
          disabled={cannotCreate}
          loading={state.step === "creating"}
          tooltip={cannotCreate ? errors : undefined}
          onClick={handleCreate}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
