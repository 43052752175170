import { useMemo, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory } from "react-router-dom";

import { useTypedFetch } from "hooks";
import { isNil, isEmpty } from "lodash";
import { MdSearch as Search } from "react-icons/md";

import { format } from "@skillup/shared-utils";
import { getInitials } from "@skillup/shared-utils";
import { Flex, Text, Title } from "@skillup/design-system";
import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";
import {
  Loader,
  useModal,
  DSAvatar,
  DSTextInput,
  DSDropdownItem,
  useMediaQueries,
} from "@skillup/ui";

import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import downloadFileAsUser from "utils/downloadFileAsUser";
import { DatatableJob, AssignCollabPayload } from "types/skills";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import { ArchiveJobsModal } from "../../components/Modals";
import generateTableRows from "../List/utils/generateTableRows";
import { JobsProvider, useJobsContext } from "../../JobsContext";
import { TextAreaWrapper, SkillsListDetail } from "./JobDetail.styled";
import { FormatSkillList, getCollabNameFromUuid } from "../../components/Form/helper";

import styles from "./JobDetail.module.scss";

const JobDetail = () => {
  return (
    <JobsProvider>
      <Layout />
    </JobsProvider>
  );
};

const Layout = () => {
  const { archiveJob, getJob, jobList, reAssignEmployeeToJobAndArchiveJob } = useJobsContext();
  const { addToast } = useToasts();
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation();
  const history = useHistory();
  const { jobUuid } = useParams<{ jobUuid: string }>();
  const { data, error, status } = getJob(jobUuid);

  const archiveJobModal = useModal();

  const jobs = useMemo(() => {
    return jobList?.map((e) => ({
      ...e,
      createdAt: new Date(e.createdAt),
      updatedAt: new Date(e.updatedAt),
    })) as undefined | DatatableJob[];
  }, [jobList]);

  const rows = generateTableRows(jobs);

  const job = useMemo(() => {
    return data ?? undefined;
  }, [data]);

  const pendingSkills =
    job?.skills.map((s) => ({
      ...s.skill,
      category: s.skill.category ? { label: s.skill.category } : undefined,
      levelUuid: s.expectedLevelUuid,
    })) || [];

  const generatedByAiLabel = t("jobs.addSkillsModal.generatedByAi", {
    defaultValue: "Compétences générées par IA",
  });

  const traductionLabel = t("jobs.addSkillsModal.noCategoryLabel", {
    defaultValue: "Compétences sans catégorie",
  });

  const skillsToDisplay = FormatSkillList(pendingSkills, traductionLabel, generatedByAiLabel);

  const customFieldsWithValues = job?.fields.filter((field) => field.value);
  const [searchCollabQuery, setSearchCollabQuery] = useState<string>();

  const employeeToDisplay =
    isNil(searchCollabQuery) && isEmpty(searchCollabQuery)
      ? job?.employees
      : job?.employees.filter((employee) =>
          employee.fullName.toLowerCase().includes(searchCollabQuery.toLowerCase())
        );

  const reassignJobEmployeesAndArchive = (assignPayload: AssignCollabPayload) => {
    const jobWithVersion = { uuid: job.uuid, version: job.version + 1 };

    reAssignEmployeeToJobAndArchiveJob(
      jobWithVersion,
      assignPayload.employees,
      assignPayload.targetJob
    );

    archiveJobModal.hide();
  };

  const menuItems = useMemo(() => {
    return [
      <DSDropdownItem
        key="edit-job"
        onClick={() => history.push(`/responsable/referentiels/fiches-de-poste/edit/${jobUuid}`)}
        label={t("jobs.list.label.editJob", {
          defaultValue: "Modifier la fiche de poste",
        })}
      />,
      <DSDropdownItem
        key="archive-job"
        label={t("jobs.list.label.deleteJob", {
          defaultValue: "Archiver la fiche de poste",
        })}
        onClick={() => {
          if (job?.employees.length > 0) {
            archiveJobModal.show();
          } else {
            handleSetArchive();
          }
        }}
      />,
      <DSDropdownItem
        key="go-to-analytics"
        onClick={() => history.push(`/responsable/analyses/fiche-de-poste/${jobUuid}`)}
        label={t("jobs.list.label.goToAnalytics", {
          defaultValue: "Accéder à la page d’analyse des évaluations",
        })}
      />,
      <DSDropdownItem
        key="download-job-pdf"
        label={t("jobs.list.label.downloadJobPDF", {
          defaultValue: "Télécharger la fiche de poste en PDF",
        })}
        onClick={() => {
          const url = `/v1/pdf/job/${jobUuid}`;
          const filename = `fiche_de_poste_${job.name}.pdf`;
          try {
            downloadFileAsUser(url, filename, "API", "application/pdf");
          } catch (err) {
            addToast(
              t("jobs.list.label.downloadJobPDF.error", {
                defaultValue: "Erreur lors du téléchargement du PDF",
              }),
              {
                appearance: "error",
              }
            );
          }
        }}
      />,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveJobModal, history, job, jobUuid, t]);

  const handleSetArchive = () => {
    archiveJob([{ uuid: job.uuid, version: job.version + 1 }], true);
  };

  const { data: collaboratorsData, loading: loadingCollaborators } =
    useTypedFetch<CollaboratorsRoutes.Get>({
      method: "GET",
      path: `/collaborators`,
      query: {
        userDetail: true,
      },
    });

  const collaboratorName = getCollabNameFromUuid(collaboratorsData, job?.userUuid);

  useEffect(() => {
    if (job?.isArchived) {
      addToast(
        t("jobs.label.jobIsArchived.error", {
          defaultValue: "Cette fiche de poste est archivée, vous ne pouvez plus y accéder.",
        }),
        {
          appearance: "error",
        }
      );
      history.push("/responsable/referentiels/fiches-de-poste");
    }
  }, [job, addToast, history, t]);

  if (error) history.goBack();

  if (status === "loading")
    return (
      <DSLayout title="Fiche de poste">
        <Loader />
      </DSLayout>
    );

  if (isMobile) return <NotAvailableInMobileView />;

  return (
    <DSLayout
      title={job.name}
      layouts={[{ dropdownContent: menuItems }]}
      parent={{
        onClick: () => history.goBack(),
        title: t("jobs.label.jobSheets", {
          defaultValue: "Fiches de poste",
        }),
      }}
    >
      <Flex width="100%" overflowY="hidden" flexDirection="row">
        <Flex padding="l" justifyContent="flex-start">
          <Flex marginHorizontal="xxs" flexDirection="column" justifyContent="space-between">
            {customFieldsWithValues && (
              <Flex paddingRight="s" paddingBottom="s" flexDirection="column">
                <Flex gap="s" flexDirection="column">
                  {customFieldsWithValues.map((field) => (
                    <div key={field.uuid}>
                      <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                        {field.name}
                      </Text>
                      <Text espaceFont="body1Regular" color="plainText-onLight-default">
                        {field.value}
                      </Text>
                    </div>
                  ))}
                </Flex>
              </Flex>
            )}
            <Flex padding="xxs" height="100%" overflow="hidden" flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {employeeToDisplay.length > 0
                  ? t("jobs.label.collabLinked", {
                      defaultValue: "Collaborateurs auxquels cette fiche de poste est associée",
                    })
                  : t("jobs.label.noCollabLinked", {
                      defaultValue: "Cette fiche de poste n’est associée à aucun collaborateur",
                    })}
              </Text>
              {job?.employees.length > 7 && (
                <Flex marginTop="xxs" className={styles.search}>
                  <DSTextInput
                    name="searchEmployee"
                    actionButton={<Search />}
                    value={searchCollabQuery}
                    className={styles.searchInput}
                    onChange={setSearchCollabQuery}
                    placeholder={t("jobs.list.label.searchEmployee", {
                      defaultValue: "Rechercher un collaborateur",
                    })}
                  />
                </Flex>
              )}
              <Flex padding="xs" overflowY="auto" flexDirection="column">
                {employeeToDisplay.map((e) => {
                  return (
                    <Flex key={e.uuid} marginVertical="xs" alignItems="center">
                      <DSAvatar size="S" initials={e.initials} randomColorSeedString={e.uuid} />
                      <Text
                        fontSize="14px"
                        marginLeft="xs"
                        espaceFont="captionRegular"
                        color="plainText-onLight-default"
                      >
                        {e.fullName}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
              {!loadingCollaborators && collaboratorName && (
                <Flex flex="1" marginTop="s" alignItems="flex-end">
                  <DSAvatar
                    size="S"
                    randomColorSeedString={job?.userUuid}
                    initials={getInitials({ fullName: collaboratorName })}
                  />
                  <Text marginLeft="xs" espaceFont="captionRegular">
                    {t("jobs.label.lastEdit", {
                      date: format(new Date(job.updatedAt), "dd/MM/yyyy"),
                      defaultValue: "Dernière modification le {{date}} par {{name}}",
                      name: collaboratorName,
                    })}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex flexGrow={1} paddingTop="l" paddingRight="l" overflowY="auto" justifyContent="center">
          <Flex width="100%" maxWidth="40rem" flexDirection="column" marginHorizontal="auto">
            <Flex flexDirection="column">
              {job.sections.length > 0 &&
                job.sections
                  .filter((section) => section.content)
                  .map((section) => (
                    <div key={section.uuid}>
                      <Title h5 margin="l 0 0" color="plainText-onLight-lighter">
                        {section.name}
                      </Title>
                      <TextAreaWrapper>
                        <Text
                          marginTop="s"
                          marginBottom="s"
                          espaceFont="body1Regular"
                          color="plainText-onLight-default"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: section.content,
                            }}
                          ></div>
                        </Text>
                      </TextAreaWrapper>
                    </div>
                  ))}
            </Flex>
            <Flex width="100%" flexDirection="column">
              {skillsToDisplay.length > 0 && (
                <>
                  <Title h5 margin="m 0 0" color="plainText-onLight-lighter">
                    {t("jobs.label.linkedSkill", {
                      defaultValue: "Compétences liées",
                    })}
                  </Title>

                  <SkillsListDetail toReach categories={skillsToDisplay} />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {rows && (
        <ArchiveJobsModal
          datatableRows={rows}
          close={archiveJobModal.hide}
          open={archiveJobModal.isOpen}
          reassignAndArchive={reassignJobEmployeesAndArchive}
          selectedJob={{
            id: job.uuid,
            uuid: job.uuid,
            employees: job.employees,
            fields: job.fields,
            isArchived: job.isArchived,
            isPrivate: job.isPrivate,
            lastEdit: job.updatedAt,
            name: job.name,
            skillsCount: job.skills.length,
            version: job.version,
          }}
        />
      )}
    </DSLayout>
  );
};
export default JobDetail;
