import { useMemo } from "react";
import useTranslation from "hooks/useTranslation";
import cx from "classnames";

import { DSButton, DSSortable, DSTooltip, Flex } from "@skillup/ui";
import Delete from "@mui/icons-material/Delete";
import { rectSortingStrategy } from "@dnd-kit/sortable";

import { CardConfigForm, PanelConfigType, PanelConfigTypeEnum } from "../../../types";
import style from "../../../views/Configuration.module.scss";
import { DetailedIndicator } from "./DetailedIndicatorCard";
import { AddCardDropdown } from "./AddCardDropdown";
import { DndContainer } from "./DndContainer";

type Props = {
  cards: CardConfigForm[];
  type: PanelConfigType;
  panelIndex: number;
  setFieldValue: (field: string, value: any) => void;
};

export const SortableCard = ({ cards, type, panelIndex, setFieldValue }: Props) => {
  const { t } = useTranslation();
  const { DETAIL_INDICATORS, KEY_INDICATORS } = PanelConfigTypeEnum;
  const path = `panels[${panelIndex}].cards`;

  const onDragItem = (items: any[], from: number, to: number): any[] => {
    const newItems = [...items];
    const [replaced] = newItems.splice(from, 1);
    newItems.splice(to, 0, replaced);

    setFieldValue(
      path,
      newItems.map((item) => cards.find((card) => card.id === item.id))
    );

    return items;
  };

  const draggableItems = useMemo(
    () => {
      return cards.map((card, cardIndex) => ({
        id: card.id,
        title: (
          <Flex row className={style.sortableCardsTitle} key={"header" + cardIndex}>
            {type === DETAIL_INDICATORS && (
              <DetailedIndicator
                path={`${path}[${cardIndex}]`}
                card={card}
                setFieldValue={setFieldValue}
                header
              />
            )}
            {type === KEY_INDICATORS && (
              <Flex column className={style.CardKeyIndicator}>
                {card.short_name}
              </Flex>
            )}
            <DSTooltip
              label={t("training.dashboard.configuration.card.header.delete", {
                defaultValue: "Supprimer l'indicateur",
              })}
              withPortal
            >
              <DSButton
                icon={<Delete />}
                iconOnly
                emphasis="Mid"
                onClick={() => {
                  setFieldValue(
                    `${path}`,
                    cards.filter((_, i) => i !== cardIndex)
                  );
                }}
              />
            </DSTooltip>
          </Flex>
        ),
        content: type === DETAIL_INDICATORS && (
          <DetailedIndicator
            path={`${path}[${cardIndex}]`}
            card={card}
            setFieldValue={setFieldValue}
          />
        ),
        displayAfter: (cardIndex + 1) % 2 === 0 && type === DETAIL_INDICATORS && (
          <AddCardDropdown
            offset={cardIndex}
            cards={cards}
            onChange={(value) => setFieldValue(`${path}`, value)}
            panelType={type}
            simplePlus
            className={style.addCardButtonRepeated}
          />
        ),
        displayBefore: (
          <AddCardDropdown
            offset={cardIndex}
            cards={cards}
            onChange={(value) => setFieldValue(`${path}`, value)}
            panelType={type}
            simplePlus
          />
        ),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards]
  );

  return (
    <>
      <Flex
        className={cx(style.draggableCards, {
          [style.dagglableCardsDetaileds]: type === DETAIL_INDICATORS,
          [style.dagglableCardsKeys]: type === KEY_INDICATORS,
        })}
        column
      >
        <DSSortable
          strategy={rectSortingStrategy}
          items={draggableItems}
          removeAlwaysVisible
          reorderItems={onDragItem}
          useDragOverlay={false}
          Container={DndContainer}
        />
        <>
          <AddCardDropdown
            offset={cards.length + 1}
            cards={cards}
            onChange={(value) => setFieldValue(`${path}`, value)}
            panelType={type}
          />
        </>
      </Flex>
    </>
  );
};
