import { useEffect, useRef } from "react";

import Highcharts from "highcharts";
import HighchartsReact, { HighchartsReactRefObject } from "highcharts-react-official";

import { formatMonetary } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import { DashboardCardConfig, QuestionData } from "../../types";

import { PIE_PALETTE } from "../../utils/graph";

import styles from "./Pie.module.scss";

export type PieChartProps = {
  data: QuestionData;
  card: DashboardCardConfig;
  loading: boolean;
};

export const PieChart = ({ data, card, loading }: PieChartProps) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const chartRef = useRef<HighchartsReactRefObject>(null);

  const isMoneyValue = card.question_meta.axes[0].data_type === "money";
  const { currency } = settings;

  useEffect(() => {
    if (!chartRef.current?.chart) {
      return;
    }

    if (!loading) chartRef.current?.chart?.hideLoading();
    if (loading) chartRef.current?.chart?.showLoading();
  }, [loading]);

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      options={{
        chart: {
          type: "pie",
        },
        title: {
          text: "",
        },
        subtitle: {
          test: "",
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          useHTML: true,
          shadox: false,
          padding: 0,
          borderRadius: 0,
          backgroundColor: "transparent",
          formatter: function () {
            return `<div class='${styles.pieTooltip}'>
              <span class='${styles.name}'>${this.point.name}</span>
              <span class='${styles.value}'>${
                isMoneyValue
                  ? formatMonetary(this.point.y, {
                      currency,
                    })
                  : this.point.y.toFixed(0)
              }</span>
            </div>`;
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              useHTML: true,
              inside: true,
              distance: 18,
              connectorWidth: 0,
              formatter: ((currency) =>
                function () {
                  return `<div class='${styles.pieLabel}'>
                        <span class='${styles.name}'>${this.point.name} : ${this.point.percentage.toFixed(1)}%</span>
                        <span class='${styles.value}'>${
                          isMoneyValue
                            ? formatMonetary(this.point.y, {
                                currency,
                              })
                            : this.point.y.toFixed(0)
                        }</span>
                      </div>`;
                })(currency),
            },
          },
        },
        series: [
          {
            name: card.short_name,
            type: "pie",
            size: "90%",
            innerSize: "80%",
            data: data.data
              .sort((a, b) => b[0] - a[0])
              .map((d, index) => ({
                name:
                  d[1] ??
                  t("training.dashboard.configuration.panel.no-data", {
                    defaultValue: "N/A",
                  }),
                y: d[0],
                color: PIE_PALETTE[index],
              })),
          },
        ],
      }}
    />
  );
};
