import { TranslationFunction } from "@skillup/design-system";
import { PanelConfigType, PanelConfigTypeEnum } from "../types";

export const ConfigurationTypeTranslationMap: (t: TranslationFunction) => {
  [type in PanelConfigType]: string;
} = (t) => ({
  [PanelConfigTypeEnum.KEY_INDICATORS]: t(
    "training.dashboard.configuration.panel.type.key-indicator",
    {
      defaultValue: "Indicateurs clés",
    }
  ),
  [PanelConfigTypeEnum.DETAIL_INDICATORS]: t(
    "training.dashboard.configuration.panel.type.detailed-indicator",
    {
      defaultValue: "Indicateurs détaillés",
    }
  ),
  [PanelConfigTypeEnum.SUMMARY]: t("training.dashboard.configuration.panel.type.summary", {
    defaultValue: "Résumé du plan de formation",
  }),
  [PanelConfigTypeEnum.MONTHLY]: t("training.dashboard.configuration.panel.type.monthly", {
    defaultValue: "Dépenses mensuelles",
  }),
});
