import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cx from "classnames";

import { Flex, Text } from "@skillup/design-system";
import { DSAvatarGroup, DSProgressBar, TypeProgressBar, VerticalDivider } from "@skillup/ui";

import { getUserInitials } from "utils/User";
import type { BasicCampaign } from "services/peopleReview/campaign/usePeopleReviewCampaigns";

import { PausedStatus } from "../CampaignState/PausedStatus";
import { BaseUrl as PeopleReviewBaseUrl } from "../../pages/router";
import { NotStartedStatus } from "./../CampaignState/NotStartedStatus";

import styles from "./PPRCampaignCard.module.scss";

export const PPRCampaignCard = ({ campaign }: { campaign: BasicCampaign }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const isClosed = campaign.state === "closed";

  const progressData = useMemo(() => {
    const total = campaign.reviewCount;
    const progress: React.ComponentProps<typeof DSProgressBar>["data"] = [];

    if (campaign.arbitratedCount > 0) {
      progress.push({
        color: "success" as const,
        label: t("people-review.campaignCard.arbitratedCount", {
          count: campaign.arbitratedCount,
          defaultValue: `Arbitrage réalisé pour {{count}} collaborateur${campaign.arbitratedCount > 1 ? "s" : ""}`,
        }),
        value: (campaign.arbitratedCount * 100) / total,
      });
    }

    if (campaign.hasManagerPreparation) {
      if (campaign.managerPrepDoneCount > 0) {
        progress.push({
          color: "successLight" as const,
          label: t("people-review.campaignCard.managerPrepDoneCount", {
            count: campaign.managerPrepDoneCount,
            defaultValue: `Préparation manager réalisée pour {{count}} collaborateur${campaign.managerPrepDoneCount > 1 ? "s" : ""}`,
          }),
          value: (campaign.managerPrepDoneCount * 100) / total,
        });
      }
      if (campaign.othersCount > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("people-review.campaignCard.othersCountWithManagerPrep", {
            count: campaign.othersCount,
            defaultValue: `En attente de préparation manager pour {{count}} collaborateur${campaign.othersCount > 1 ? "s" : ""}`,
          }),
          value: (campaign.othersCount * 100) / total,
        });
      }
    } else {
      if (campaign.othersCount > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("people-review.campaignCard.othersCount", {
            count: campaign.othersCount,
            defaultValue: `{{count}} collaborateur${campaign.othersCount > 1 ? "s" : ""} en attente d'arbitrage`,
          }),
          value: (campaign.othersCount * 100) / total,
        });
      }
    }

    return progress;
  }, [t, campaign]);

  return (
    <Flex
      gap="xs"
      paddingVertical="xs"
      paddingHorizontal="s"
      flexDirection="column"
      className={cx(styles.campaignCard, {
        [styles.closed]: isClosed,
      })}
      onClick={() => history.push(`${PeopleReviewBaseUrl}/campaigns/${campaign.id}/grid`)}
    >
      {campaign.state === "not_started" && (
        <NotStartedStatus isDark={false} className={styles.state} />
      )}
      {campaign.state === "paused" && <PausedStatus isDark={false} className={styles.state} />}
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          espaceFont="h4"
          color={isClosed ? "plainText-onLight-lighter" : "plainText-onLight-default"}
        >
          {campaign.title}
        </Text>
      </Flex>
      <Flex gap="xs" marginBottom="s" flexDirection="column">
        <Flex flexWrap="wrap">
          <Text espaceFont="captionRegular">
            {t("people-review.campaignCard.population.collaboratorCount", {
              count: campaign.reviewCount,
              defaultValue: `{{count}} collaborateur${campaign.reviews.length > 0 ? "s" : ""}`,
            })}
          </Text>
          <VerticalDivider left="xs" right="xs" />
          <Text espaceFont="captionRegular">
            {t("people-review.campaignCard.population.managerCount", {
              count: campaign.managerCount,
              defaultValue: `{{count}} manager${campaign.managerCount > 0 ? "s" : ""}`,
            })}
          </Text>
          <VerticalDivider left="xs" right="xs" />
          <Text espaceFont="captionRegular">
            {t("people-review.campaignCard.population.coordinatorsCount", {
              count: campaign.coordinatorCount,
              defaultValue: `{{count}} coordinateur${campaign.coordinatorCount > 0 ? "s" : ""}`,
            })}
          </Text>
        </Flex>
        <DSAvatarGroup
          size="S"
          maxItems={5}
          greyMode={isClosed}
          firstElementForeground
          totalItems={campaign.reviews.length}
          users={campaign.collabPreviews.map((employee) => ({
            uuid: employee.uuid,
            fullName: employee.fullName,
            initials: getUserInitials({
              fullName: employee.fullName,
            }),
          }))}
        />
      </Flex>
      {/* TODO: commented because we will need this after release */}
      {campaign.state !== "not_started" && (
        <DSProgressBar
          thin
          border
          layout="segmented"
          data={progressData}
          showLegends={false}
          disabled={isClosed}
          type={TypeProgressBar.PERCENTAGE}
        />
      )}
    </Flex>
  );
};
