import { MdDelete as Delete } from "react-icons/md";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";

import {
  DropDownCheckbox,
  DSModal,
  DSTextInput,
  DSFormGroupTextInput,
  NumberInput,
  AssistiveArea,
  Flex,
  Checkbox,
  DSButton,
  DSRadioGroup,
  DSRadio,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
  DSCheckbox,
  DSTooltip,
} from "@skillup/ui";
import { DateRangePicker, Text, TimeRangePicker } from "@skillup/design-system";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import { searchUsersByQuery } from "fetchers/users-fetcher";
import { Project } from "services/sessions";
import useTranslation from "hooks/useTranslation";
import useAreas from "hooks/useAreas";
import { ProjectsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import { parseDateRange } from "utils/dates/dates";

import { AFTER_SUMMONED_STATES } from "../helper";
import {
  disableReviewsCheckbox,
  formatPayloadForUpdate,
  formatToDate,
  manageDatesError,
  onlyColdReviewsWouldBeDisabled,
  parsedDates,
  processTimeError,
} from "./utils";
import { State } from "./types";
import { AssistiveErrorText, PriceInputs, ReferenceInput } from "./components";
import styles from "./Modal.module.scss";

type Props = {
  project: Project;
  onClose: () => void;
  isOpen: boolean;
  openSummonModal: (updatedProject?: Project) => void;
};

export const UpdateModal = ({ onClose, isOpen, project, openSummonModal }: Props) => {
  const hasAccessToReviews =
    project.reviewsState?.companyHasColdReviews === true ||
    project.reviewsState?.companyHasHotReviews === true;

  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState({ value: false, fields: [] });

  const initialState: State = {
    trainers: project.trainers?.map(({ uuid, fullName }) => ({ uuid, fullName })) || [],
    areas: project.areas.map((area) => area.uuid) || [],
    price: {
      type: project.properties.priceType || "total",
      value:
        project.properties.priceType === "perTrainee"
          ? project.training.pricePerTrainee
          : project.training.bookingPrice,
    },
    priceWithTax:
      project.properties.priceType === "perTrainee"
        ? project.training.pricePerTraineeWithTax
        : project.training.bookingPriceWithTax,
    pricePerTrainee: project.training.pricePerTrainee,
    pricePerTraineeWithTax: project.training.pricePerTraineeWithTax,
    bookingPrice: project.training.bookingPrice,
    bookingPriceWithTax: project.training.bookingPriceWithTax,
    city: project.properties.city,
    room: project.properties.room,
    link: project.properties.link,
    min: project.properties.minStock,
    max: project.properties.stock,
    dates: parsedDates(project.properties.dates),
    reference: project.properties.reference,
    afterSummoned: AFTER_SUMMONED_STATES.includes(project.properties?.state),
    evalGroupsGenerated: !hasAccessToReviews
      ? undefined
      : project.reviewsState?.evalGroupsGenerated ?? true,
  };

  const [state, setState] = useSetState<State>(initialState);
  const [hasTimeError, setHasTimeError] = useState<{ [key: number]: boolean }>({});

  const { allAreas } = useAreas();
  const { addToast } = useToasts();

  const setProperty =
    <T extends keyof State>(property: T) =>
    (value: State[T]) =>
      setState({ [property]: value });

  const { mutate, isLoading } = useMutation(async () => {
    try {
      const payload = formatPayloadForUpdate(state);

      const updatedProject = await buildRequest<ProjectsRoutes.Update>({
        method: "POST",
        params: {
          uuid: project.properties.uuid,
        },
        path: "/projects/{uuid}",
        payload,
      })();
      addToast(
        t("supervisor.sessions.updateModal.submitSuccess", {
          defaultValue: "La session a été modifiée avec succès",
        }),
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      onClose();
      return updatedProject;
    } catch (err) {
      addToast(
        t("supervisor.sessions.updateModal.submitError", {
          defaultValue: "Une erreur est survenue lors de la modification de la session",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      console.error(err);
      return null;
    }
  });

  function setDates(index: number, dateRange: Date[], hourRange?: Date[], allDay = false) {
    setHasTimeError(processTimeError(index, hasTimeError, dateRange, hourRange, allDay));
    setState((state) => {
      return {
        ...state,
        dates: state.dates.map((date, i) => {
          if (i === index) {
            return { dateRange, hourRange, allDay };
          }
          return date;
        }),
      };
    });
  }

  const checkAlertConditions = () => {
    if (!state.afterSummoned) return;
    const alertFields = [];

    const isDatesChanged = () => {
      const parsedProjectDates = parsedDates(project.properties.dates);
      if (parsedProjectDates.length !== state.dates.length) return true;

      return parsedProjectDates.some((projectDate, index) => {
        const stateDate = state.dates[index];
        return (
          projectDate.allDay !== stateDate.allDay ||
          projectDate.dateRange[0]?.getTime() !== stateDate.dateRange[0]?.getTime() ||
          projectDate.dateRange[1]?.getTime() !== stateDate.dateRange[1]?.getTime() ||
          projectDate.hourRange?.[0]?.getTime() !== stateDate.hourRange?.[0]?.getTime() ||
          projectDate.hourRange?.[1]?.getTime() !== stateDate.hourRange?.[1]?.getTime()
        );
      });
    };

    const isFieldChanged = (field: string) =>
      state[field] !== initialState[field] && (state[field] !== "" || initialState[field] !== null);

    if (isDatesChanged()) alertFields.push("dates");
    if (isFieldChanged("room")) alertFields.push("room");
    if (isFieldChanged("link")) alertFields.push("link");
    if (isFieldChanged("city")) alertFields.push("city");

    setShowAlert({ value: alertFields.length > 0, fields: alertFields });
  };

  useEffect(() => {
    checkAlertConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dates, state.room, state.link, state.city]);

  useEffect(() => {
    setState({ isPostponed: showAlert.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert]);

  const formIsValid = useMemo(() => {
    const requiredFields = ["dates", "areas", "city"];

    return requiredFields.every((field) => {
      if (
        field === "dates" &&
        (state.dates.length === 0 ||
          state.dates.some((date) => !date.dateRange || date.dateRange.some((d) => !d)))
      )
        return false;
      if (
        field === "dates" &&
        state.dates.some(
          (date) => !date.allDay && (!date.hourRange || date.hourRange.some((d) => !d))
        )
      )
        return false;
      if (field === "dates" && state.dates.some((date) => manageDatesError(date, t))) return false;
      if (field === "areas" && state.areas.length === 0) return false;
      if (state.max && state.min > state.max) return false;
      if (state.max === 0) return false;
      if (state.min < 0) return false;

      return !!state[field];
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setState(initialState);
    setShowAlert({ value: false, fields: [] });
    setHasTimeError({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, onClose]);

  const reviewsGenerationAlert = (() => {
    if (project.reviewsState?.evalGroupsGenerated === true && state.evalGroupsGenerated === false) {
      if (onlyColdReviewsWouldBeDisabled(project.reviewsState)) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.coldOnly", {
            defaultValue: "Cette action n'affectera que les évaluations à froid.",
          }),
        };
      }
    }

    if (project.reviewsState?.evalGroupsGenerated === false && state.evalGroupsGenerated === true) {
      const intervalInfos = parseDateRange([project.properties.endDate], {
        dateFormat: "DD/MM/YYYY",
        hourFormat: "H:mm",
      });
      const { days } = intervalInfos;

      const endDay = days[0];

      const endDate = formatToDate({ date: endDay });
      const now = new Date();

      const date10DaysAgo = new Date(now);
      date10DaysAgo.setDate(now.getDate() - 10);
      const date100DaysAgo = new Date(now);
      date100DaysAgo.setDate(now.getDate() - 100);

      const completedMoreThan10DaysAgo = endDate < date10DaysAgo;
      const completedLessThan100DaysAgo = endDate > date100DaysAgo;
      const completedMoreThan100DaysAgo = endDate < date100DaysAgo;

      if (completedMoreThan10DaysAgo && project.reviewsState?.companyHasColdReviews === false) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotOnly", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".`,
          }),
        };
      }

      if (
        completedMoreThan10DaysAgo &&
        completedLessThan100DaysAgo &&
        project.reviewsState?.companyHasColdReviews === true
      ) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotAndColdRecent", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".
                Les évaluations à froid seront bien générées et envoyées.`,
          }),
        };
      }

      if (completedMoreThan100DaysAgo && project.reviewsState?.companyHasColdReviews === true) {
        return {
          value: t("supervisor.sessions.updateModal.reviews.warningText.hotAndColdOld", {
            defaultValue: `Les modifications apportées ne sont pas rétroactives : les évaluations à chaud et à froid ne seront pas envoyées aux collaborateurs mais seront disponibles dans l'onglet "Évaluations".`,
          }),
        };
      }
    }
    return undefined;
  })();

  const reviewsAlertRef = useRef(null);

  useEffect(() => {
    if (reviewsGenerationAlert) {
      reviewsAlertRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [reviewsGenerationAlert]);

  return (
    <DSModal isOpen={isOpen} className={styles.DSModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.sessions.modal.update.title", {
            defaultValue: "Modifier la session",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.modal}>
          {showAlert.value && (
            <DSAlert
              type={DSAlertType.WARNING}
              display={DSAlertDisplay.FULL_BLEED}
              className={styles.alert}
            >
              {t("supervisor.sessions.updateModal.alert", {
                defaultValue:
                  "La modification de « {{fields}} » rend obsolètes les convocations déjà envoyées. Si vous ne relancez pas de convocations, la session sera enregistrée comme « Provisoire ».",
                fields: showAlert.fields
                  .map((field) => {
                    return t(`supervisor.sessions.UpdateModal.${field}`, {
                      defaultValue: field,
                    });
                  })
                  .join(", "),
              })}
            </DSAlert>
          )}
          <section className={styles.infoSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.updateModal.sessionInfo", {
                defaultValue: "Infos de session",
              })}
            </Text>

            <DSFormGroupTextInput className={styles.dateForm} label="Dates" required>
              <>
                {state.dates.map((date, index) => (
                  <DSFormGroupTextInput
                    className={styles.dateFormGroup}
                    key={index}
                    assistiveArea={manageDatesError(date, t) as any}
                  >
                    <Flex wrap className={styles.dateContainer}>
                      <DateRangePicker
                        className={styles.dateRangePicker}
                        defaultValue={date.dateRange as any}
                        onChange={(value) => setDates(index, value, date.hourRange, date.allDay)}
                        slotProps={{
                          popper: {
                            style: {
                              zIndex: 1000,
                            },
                          },
                        }}
                      />
                      {!date.allDay && (
                        <div className={styles.timeRangeContainer}>
                          <TimeRangePicker
                            className={cx({ [styles.timeError]: hasTimeError[index] })}
                            value={date.hourRange as any}
                            onChange={(value) =>
                              setDates(index, date.dateRange, value, date.allDay)
                            }
                            slotProps={{
                              popper: {
                                style: {
                                  zIndex: 1000,
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                      <Checkbox
                        key="toggle-time"
                        className={styles.toggle}
                        label={t("supervisor.sessions.updateModal.allDay", {
                          defaultValue: "Toute la journée",
                        })}
                        value="all"
                        checked={date.allDay}
                        onClick={() =>
                          setDates(index, date.dateRange, date.hourRange, !date.allDay)
                        }
                      />
                      <DSButton
                        onClick={() => {
                          setProperty("dates")(state.dates.filter((_, i) => i !== index));
                        }}
                        iconOnly
                        icon={<Delete />}
                        buttonSize={"S"}
                        emphasis={"Low"}
                      />
                    </Flex>
                  </DSFormGroupTextInput>
                ))}

                <DSButton
                  label={t("supervisor.sessions.updateModal.addDate", {
                    defaultValue: "Ajouter des dates à la session",
                  })}
                  buttonSize={"S"}
                  emphasis={"Low"}
                  onClick={() => setProperty("dates")(state.dates.concat({ allDay: true }))}
                  type="button"
                />
              </>
            </DSFormGroupTextInput>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.updateModal.city", {
                defaultValue: "Ville",
              })}
              required
            >
              <DSTextInput
                className={styles.shortInput}
                name="Ville"
                placeholder={t("supervisor.sessions.updateModal.cityPlaceholder", {
                  defaultValue: "Ex: Paris",
                })}
                value={state.city}
                onChange={(value) => {
                  setProperty("city")(value);
                  state.afterSummoned &&
                    (value !== project.properties.city
                      ? setShowAlert({
                          value: true,
                          fields: showAlert.fields.includes("city")
                            ? showAlert.fields
                            : showAlert.fields.concat("city"),
                        })
                      : setShowAlert({
                          value: false,
                          fields: showAlert.fields.filter((field) => field !== "city"),
                        }));
                }}
              />
            </DSFormGroupTextInput>

            <Flex>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.updateModal.room", {
                  defaultValue: "Salle",
                })}
              >
                <DSTextInput
                  name="Salle"
                  placeholder={t("supervisor.sessions.updateModal.roomPlaceholder", {
                    defaultValue: "Ex: 439",
                  })}
                  className={styles.shortInput}
                  value={state.room}
                  onChange={(value) => {
                    setProperty("room")(value);
                  }}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.updateModal.link", {
                  defaultValue: "Lien de connexion",
                })}
              >
                <DSTextInput
                  name="Lien de connexion"
                  placeholder={t("supervisor.sessions.updateModal.linkPlaceholder", {
                    defaultValue: "Ex : https://www.skillup.co/",
                  })}
                  className={styles.shortInput}
                  value={state.link}
                  onChange={setProperty("link")}
                />
              </DSFormGroupTextInput>
            </Flex>

            <DSFormGroupTextInput
              className={styles.priceForm}
              label={t("supervisor.sessions.updateModal.sessionPrice", {
                defaultValue: "Prix de la session",
              })}
              required
            >
              <>
                <AssistiveArea
                  mode="default"
                  text={t("supervisor.sessions.updateModal.sessionPrice.assistiveText", {
                    defaultValue:
                      "Si vous renseignez un prix de session, le prix prévisionnel par participant sera ignoré.",
                  })}
                />

                <DSRadioGroup name="sessionPrice">
                  <DSRadio
                    label={t("supervisor.sessions.updateModal.sessionPrice", {
                      defaultValue: "Prix de la session",
                    })}
                    checked={state.price.type === "total"}
                    disabled={state.afterSummoned}
                    onChange={() => {
                      setProperty("price")({
                        type: "total",
                        value: state.bookingPrice,
                      });
                      setProperty("priceWithTax")(state.bookingPriceWithTax);
                    }}
                  />
                  {state.price.type === "total" && (
                    <PriceInputs
                      state={state}
                      setPriceHT={(value) => setProperty("price")({ type: "total", value })}
                      setPriceTTC={(value) => setProperty("priceWithTax")(value)}
                    />
                  )}
                  <DSRadio
                    label={t("supervisor.sessions.updateModal.sessionPrice.provisional", {
                      defaultValue: "Prix prévisionnel par participant",
                    })}
                    checked={state.price.type === "perTrainee"}
                    disabled={state.afterSummoned}
                    onChange={() => {
                      setProperty("price")({
                        type: "perTrainee",
                        value: state.pricePerTrainee,
                      });
                      setProperty("priceWithTax")(state.pricePerTraineeWithTax);
                    }}
                  />
                  {state.price.type === "perTrainee" && (
                    <PriceInputs
                      state={state}
                      setPriceHT={(value) => setProperty("price")({ type: "perTrainee", value })}
                      setPriceTTC={(value) => setProperty("priceWithTax")(value)}
                    />
                  )}
                </DSRadioGroup>
              </>
            </DSFormGroupTextInput>
            <Flex className={styles.attendeeForm} row wrap>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.updateModal.minimum", {
                  defaultValue: "Nombre de participants minimum",
                })}
                className={styles.input}
                assistiveArea={
                  state.max && state.min > state.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.creationModal.minimum.inferior", {
                            defaultValue:
                              "La valeur minimale doit être inférieure à la valeur maximale",
                          })}
                        />
                      ) as any)
                    : state.min < 0
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.creationModal.minimum.superior", {
                              defaultValue: "La valeur minimale doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={0}
                  step={1}
                  max={state.max}
                  errored={(state.max && state.min > state.max) || state.min < 0}
                  value={state.min}
                  onChange={setProperty("min")}
                  placeholder={t("supervisor.sessions.updateModal.minimum.placeholder", {
                    defaultValue: "Ex : 1",
                  })}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.updateModal.minimum", {
                  defaultValue: "Nombre de participants maximum",
                })}
                className={styles.input}
                assistiveArea={
                  state.max && state.min > state.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.updateModal.maximum.superior", {
                            defaultValue:
                              "La valeur maximale doit être supérieure à la valeur minimale",
                          })}
                        />
                      ) as any)
                    : state.max === 0
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.updateModal.maximum.inferior", {
                              defaultValue: "La valeur maximale doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={state.min ?? 0}
                  step={1}
                  errored={(state.max && state.min > state.max) || state.max === 0}
                  value={state.max}
                  onChange={setProperty("max")}
                  placeholder={t("supervisor.sessions.updateModal.maximum.placeholder", {
                    defaultValue: "Ex : 10",
                  })}
                />
              </DSFormGroupTextInput>
            </Flex>
          </section>

          <section className={styles.perimeterSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.updateModal.parameterSession", {
                defaultValue: "Paramètres de la session",
              })}
            </Text>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.updateModal.perimeter.label", {
                defaultValue: "Périmètres",
              })}
              className={styles.perimeterForm}
              required
            >
              <DropDownCheckbox
                labels={{
                  itemSingular: t("supervisor.sessions.updateModal.perimeter.optionLabel", {
                    defaultValue: "périmètre",
                    count: 1,
                  }),
                  itemPlural: t("supervisor.sessions.updateModal.perimeter.optionLabel", {
                    defaultValue: "périmètres",
                    count: 2,
                  }),
                  allItemsSelected: t("supervisor.sessions.updateModal.perimeter.optionAll", {
                    defaultValue: "Tous sélectionnés",
                  }),
                  noItemSelected: t("supervisor.sessions.updateModal.perimeter.optionNone", {
                    defaultValue: "aucune sélection",
                  }),
                }}
                items={(allAreas ?? []).map((area) => ({
                  label: area.name,
                  value: area.uuid,
                  isSelected: state.areas?.some((projectAreaUuid) => projectAreaUuid === area.uuid),
                }))}
                onChange={(selection) => {
                  setProperty("areas")(
                    selection.filter((area) => area.isSelected).map((area) => area.value)
                  );
                }}
              />
            </DSFormGroupTextInput>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.updateModal.internalTrainer.label", {
                defaultValue: "Formateur(s) interne(s)",
              })}
              className={styles.trainerForm}
            >
              <DropDownCheckbox
                aria-label="trainers"
                labels={{
                  itemSingular: t("supervisor.sessions.updateModal.internalTrainer.optionLabel", {
                    defaultValue: "formateur",
                    count: 1,
                  }),
                  itemPlural: t("supervisor.sessions.updateModal.internalTrainer.optionLabel", {
                    defaultValue: "formateurs",
                    count: 2,
                  }),
                  noItemSelected: t(
                    "supervisor.sessions.updateModal.internalTrainer.noItemSelected",
                    {
                      defaultValue: "Choisissez le(s) formateur(s) parmi vos collaborateurs",
                    }
                  ),
                  searchPlaceholder: t(
                    "supervisor.sessions.updateModal.internalTrainer.searchPlaceholder",
                    {
                      defaultValue: "Rechercher un collaborateur...",
                    }
                  ),
                  noItemFound: t("supervisor.sessions.updateModal.internalTrainer.noItemFound", {
                    defaultValue: "Aucun collaborateur trouvé, veuillez modifier votre recherche.",
                  }),
                }}
                items={state.trainers?.map((user) => ({
                  label: user.fullName,
                  value: user.uuid,
                  isSelected: state.trainers?.some(({ uuid }) => uuid === user.uuid),
                }))}
                onChange={(selection) => {
                  setProperty("trainers")(
                    selection
                      .filter((user) => user.isSelected)
                      .map((user) => ({ uuid: user.value, fullName: user.label }))
                  );
                }}
                canSelectAll={false}
                searchAutoFocus
                onSearch={async (query) => {
                  const users = await searchUsersByQuery(query, USER_FILE_VALIDATION_TYPE.NONE);
                  return users.map((user) => ({
                    label: user.fullName,
                    value: user.uuid,
                    isSelected: !!state.trainers?.some(({ uuid }) => uuid === user.uuid),
                  }));
                }}
              />
            </DSFormGroupTextInput>

            {state?.reference && (
              <ReferenceInput
                onChange={setProperty("reference")}
                reference={state.reference}
                sessionUuid={project.properties.uuid}
              />
            )}

            {hasAccessToReviews && (
              <DSFormGroupTextInput
                className={styles.reviewsForm}
                label={t("supervisor.sessions.updateModal.reviewSession", {
                  defaultValue: "Paramètre des évaluations dans la session",
                })}
              >
                <DSTooltip
                  label={t("supervisor.sessions.updateModal.reviews.tooltip", {
                    defaultValue: "Les évaluations sont déjà générées",
                  })}
                  disabled={!disableReviewsCheckbox(project.reviewsState)}
                >
                  <DSCheckbox
                    key="toggle-reviews"
                    className={styles.toggle}
                    label={t("supervisor.sessions.updateModal.generateReviews", {
                      defaultValue: "Générer les évaluations",
                    })}
                    value="all"
                    checked={state.evalGroupsGenerated}
                    helpText={t("supervisor.sessions.updateModal.reviews.assistiveText", {
                      defaultValue:
                        "La génération des évaluations entraînera un envoi d’une invitation à évaluer la formation aux collaborateurs",
                    })}
                    disabled={disableReviewsCheckbox(project.reviewsState)}
                    onChange={() =>
                      !disableReviewsCheckbox(project.reviewsState) &&
                      setProperty("evalGroupsGenerated")(!state.evalGroupsGenerated)
                    }
                  />
                </DSTooltip>
              </DSFormGroupTextInput>
            )}
            {reviewsGenerationAlert && (
              <div className={styles.reviewsAlert} ref={reviewsAlertRef}>
                <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                  {reviewsGenerationAlert.value}
                </DSAlert>
              </div>
            )}
          </section>
        </div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        {showAlert.value ? (
          <>
            <DSModal.Footer.PrimaryButton
              label={t("supervisor.sessions.updateModal.submit", {
                defaultValue: "Enregister",
              })}
              emphasis="Mid"
              disabled={!formIsValid}
              tooltip={
                !formIsValid
                  ? t("supervisor.sessions.updateModal.submitError", {
                      defaultValue:
                        "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                    })
                  : ""
              }
              loading={isLoading}
              onClick={() => {
                mutate();
              }}
            />
            <DSModal.Footer.PrimaryButton
              label={t("supervisor.sessions.updateModal.submitAndSummon", {
                defaultValue: "Enregister et reconvoquer",
              })}
              disabled={!formIsValid}
              tooltip={
                !formIsValid
                  ? t("supervisor.sessions.updateModal.submitError", {
                      defaultValue:
                        "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                    })
                  : ""
              }
              loading={isLoading}
              onClick={() => {
                mutate(null, {
                  onSuccess: (data) => {
                    openSummonModal(data);
                  },
                });
              }}
            />
          </>
        ) : (
          <DSModal.Footer.PrimaryButton
            label={t("supervisor.sessions.updateModal.submit", {
              defaultValue: "Modifier la session",
            })}
            disabled={!formIsValid}
            tooltip={
              !formIsValid
                ? t("supervisor.sessions.updateModal.submitError", {
                    defaultValue:
                      "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                  })
                : ""
            }
            loading={isLoading}
            onClick={() => {
              mutate();
            }}
          />
        )}
      </DSModal.Footer>
    </DSModal>
  );
};
