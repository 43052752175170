import { MdInsertChart as Chart } from "react-icons/md";
import { MdSchool as School } from "react-icons/md";
import { MdQuestionAnswer as QuestionAnswer } from "react-icons/md";
import { MdPsychology as Psychology } from "react-icons/md";
import { MdEmojiEvents as Trophy } from "react-icons/md";
import { MdSupervisorAccount as SupervisorAccount } from "react-icons/md";
import { MdSettings as Settings } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useLocalStorage, useLocation } from "react-use";

import cx from "classnames";

import * as Sentry from "@sentry/react";
import {
  DSNavigationSideNav,
  DSNavigationSideNavItem,
  DSNavigationSideNavSubItem,
  Loader,
  useMediaQueries,
} from "@skillup/ui";

import User from "utils/User";
import useSettings, { useAccessCheckForList } from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";
import useEnvironment from "hooks/useEnvironment";

import UnknownErrorIcon from "assets/error/unknown_error.svg";
import DSNewHeader, { DSNewHeaderProps, Layout } from "components/DSNewHeader";
import ApplicationError from "components/ApplicationError";
import ErrorPage from "components/ErrorPage";

import styles from "./DSLayoutStyles.module.scss";
import { ArrayUtils } from "utils/arrayUtils";

type SubNav = {
  id: string;
  label: string;
  url: string;
};

type Nav = {
  id: string;
  label: string;
  icon: JSX.Element;
  url?: string;
  subNavs: Array<SubNav>;
};

interface DSLayoutProps {
  readonly children?: JSX.Element | JSX.Element[];
  readonly headerSubtitle?: JSX.Element;
  readonly onClickLayout?: () => void;
  readonly isRelative?: boolean;
}

function DSLayout<T extends Layout>({
  title,
  parent,
  leftItem,
  className,
  layouts = new Array<T>(),
  activeLayout,
  onChangeLayout,
  header,
  isHiddenOnMobile,
  children,
  headerSubtitle,
  onClickLayout,
  isRelative = false,
}: DSNewHeaderProps<T> & DSLayoutProps) {
  const [isNavigationOpen, setNavigationState] = useLocalStorage<boolean>("navigationState", true);

  useEffect(() => {
    window.dispatchEvent(new Event("navigationStateChanged"));
  }, [isNavigationOpen]);

  const { isDevelopment } = useEnvironment();
  const { settings, userAccessModules, loading } = useSettings();
  const { pathname } = useLocation();
  const { isMobile } = useMediaQueries();
  const [active, setActive] = useState<string | undefined>(pathname);
  const [forceOpenId, setForceOpenId] = useState<string | undefined>();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const canAccess = useAccessCheckForList(["people-review"]);

  const [currentSchedule, setCurrentSchedule] = useState<string>(User.getCurrentSchedule());

  window.addEventListener("scheduleChanged", (e: CustomEvent) => {
    setCurrentSchedule(User.getCurrentSchedule());
  });

  const forceCloseNavigation = useMemo(() => {
    const campaignPathPatterns = [
      /\/responsable\/people-review\/campaigns\/.*/,
      /\/responsable\/template\//,
      /\/responsable\/campagne\/.+?\/edit/,
    ];

    return campaignPathPatterns.some((pattern) => pattern.test(pathname));
  }, [pathname]);

  const navs: Array<Nav> = useMemo(() => {
    return ArrayUtils.filterFalsy([
      UserAccessChecker.Dashboards.toDashboards() && {
        id: "dashboard/",
        label:
          UserAccessChecker.Dashboards.toTrainingDashboard() &&
          UserAccessChecker.Dashboards.toInterviewDashboard()
            ? "Dashboards"
            : "Dashboard",
        icon: <Chart />,
        subNavs: ArrayUtils.filterFalsy([
          UserAccessChecker.Dashboards.toTrainingDashboard() && {
            id: "training",
            label: "Formation",
            url: "/responsable/dashboard/training",
          },
          UserAccessChecker.Dashboards.toTrainingDashboardV2() && {
            id: "training-v2",
            label: "Formation bêta",
            url: "/responsable/dashboard/training-v2",
          },
          UserAccessChecker.Dashboards.toInterviewDashboard() && {
            id: "interview",
            label: "Entretiens",
            url: "/responsable/dashboard/interview",
          },
        ]),
      },
      UserAccessChecker.Trainings.toTrainings() && {
        id: "formation",
        label: "Formation",
        icon: <School />,
        subNavs: ArrayUtils.filterFalsy([
          UserAccessChecker.Trainings.toTrainingsSchedulePlan() &&
            currentSchedule && {
              id: "recueil-des-besoins",
              label: "Recueil des besoins",
              url: `/responsable/${currentSchedule}/recueil-des-besoins/collection-pending`,
            },
          UserAccessChecker.Trainings.toTrainingsScheduleCollection() &&
            currentSchedule && {
              id: "plan-de-formation",
              label: "Plan de formation",
              url: `/responsable/${currentSchedule}/plan-de-formation/plan-approved`,
            },
          UserAccessChecker.Trainings.toTrainingsRegulatoryHabilitations() && {
            id: "reglementaire",
            label: "Réglementaire",
            url: "/responsable/reglementaire/habilitations",
          },
          UserAccessChecker.Trainings.toTrainingsIntrasOrganization() && {
            id: "programmes",
            label: "Programmes",
            url: "/responsable/programmes/gestion-intras",
          },
          !UserAccessChecker.Trainings.toTrainingsIntrasOrganization() &&
            UserAccessChecker.Trainings.toTrainingsIntrasSkillup() && {
              id: "programmes",
              label: "Programmes",
              url: "/responsable/programmes/catalogue",
            },
          UserAccessChecker.Trainings.toTrainingsSessions() && {
            id: "sessions",
            label: "Sessions",
            url: "/responsable/mes-sessions/en-cours",
          },
          UserAccessChecker.Trainings.toTrainingsHotEvaluations() && {
            id: "evaluations",
            label: "Évaluations",
            url: "/responsable/evaluations/hot",
          },
        ]),
      },
      UserAccessChecker.Interviews.toInterviews() && {
        id: "entretiens",
        label: "Entretiens",
        icon: <QuestionAnswer />,
        subNavs: ArrayUtils.filterFalsy([
          UserAccessChecker.Interviews.toInterviewsTrackings() && {
            id: "campagnes",
            label: "Suivi des entretiens",
            url: "/responsable/campagnes",
          },
          {
            id: "templates",
            label: "Trames d’entretien",
            url: "/responsable/templates",
          },
        ]),
      },
      UserAccessChecker.Skills.toSkills() && {
        id: "competences",
        label: "Compétences",
        icon: <Psychology />,
        subNavs: ArrayUtils.filterFalsy([
          {
            id: "analyses",
            label: "Analyses des évaluations",
            url: "/responsable/analyses/competences",
          },
          {
            id: "referentiels",
            label: "Référentiels",
            url: "/responsable/referentiels/fiches-de-poste",
          },
        ]),
      },
      (User.isSkillupDeveloper() || canAccess["people-review"]) && {
        id: "people-review",
        label: "People Review",
        icon: <Trophy />,
        subNavs: [],
        url: "/responsable/people-review",
      },
    ]);
  }, [UserAccessChecker, currentSchedule, canAccess]);

  const footer: Array<Nav> = useMemo(() => {
    return ArrayUtils.filterFalsy([
      UserAccessChecker.toEmployees() && {
        id: "collaborateur",
        label: "Collaborateurs",
        icon: <SupervisorAccount />,
        subNavs: [],
        url: "/responsable/collaborateurs",
      },
      UserAccessChecker.Configuration.toConfiguration() && {
        id: "configuration",
        label: "Configuration",
        icon: <Settings />,
        subNavs: ArrayUtils.filterFalsy([
          UserAccessChecker.Configuration.toConfigurationAdminPlatform() && {
            id: "administration-du-portail",
            label: "Administration de la plateforme",
            url: "/responsable/administration-du-portail",
          },
          UserAccessChecker.Configuration.toConfigurationPlans() && {
            id: "plans",
            label: "Plans",
            url: "/responsable/plans",
          },
          UserAccessChecker.Configuration.toConfigurationPortail() && {
            id: "gestion-du-portail",
            label: "Portail",
            url: "/responsable/v2/gestion-du-portail",
          },
          UserAccessChecker.Configuration.toTargetCategories() && {
            id: "entretiens-et-objectifs",
            label: "Entretiens et objectifs",
            url: "/responsable/configuration-des-entretiens-et-des-objectifs",
          },
          UserAccessChecker.Configuration.toSkillsManagement() && {
            id: "configuration-du-module-competences",
            label: "Compétences",
            url: "/responsable/configuration-du-module-competences/structure-de-fiches-de-poste",
          },
          (User.isSkillupDeveloper() || User.hasPeopleReviewEnabled()) && {
            id: "people-review-administration",
            label: "People Review",
            url: "/responsable/configuration-des-people-reviews/modeles-de-campagne",
          },
          UserAccessChecker.Configuration.toConfigurationImports() && {
            id: "ops-administration",
            label: "[DATA] Imports",
            url: "/responsable/ops-administration",
          },
        ]),
      },
    ]);
  }, [UserAccessChecker]);

  useEffect(() => {
    if (navs.length < 3) {
      setForceOpenId(navs.find((e) => e.subNavs.length > 1)?.id);
    }
  }, [navs]);

  const userData = User.getUserData();

  const logo = useMemo(() => {
    return userData?.activeCompany?.name !== "Skillup"
      ? userData?.activeCompany?.portalLogo
      : undefined;
  }, [userData]);

  return (
    <>
      {loading ? (
        <Loader className={styles.loader} />
      ) : (
        <div className={styles.layout} onClick={onClickLayout}>
          <DSNavigationSideNav
            show={isNavigationOpen && !forceCloseNavigation}
            activeNav={active}
            onChangeNav={(id) => {
              setActive(id);
              if (isMobile) setNavigationState(false);
            }}
            onClose={() => setNavigationState(false)}
            forceOpenId={forceOpenId}
            footer={footer.map((nav) => (
              <DSNavigationSideNavItem
                icon={nav.icon}
                label={nav.label}
                id={nav.id}
                to={nav.url}
                key={nav.url}
              >
                {nav.subNavs?.map((subNav) => (
                  <DSNavigationSideNavSubItem
                    label={subNav.label}
                    id={subNav.id}
                    to={subNav.url}
                    key={subNav.id}
                  />
                ))}
              </DSNavigationSideNavItem>
            ))}
            portalLogo={logo}
          >
            {navs.map((nav) => (
              <DSNavigationSideNavItem
                icon={nav.icon}
                label={nav.label}
                id={nav.id}
                to={nav?.subNavs.length === 1 ? nav.subNavs[0].url : nav.url}
                key={nav.id}
              >
                {(nav?.subNavs.length > 1 &&
                  nav.subNavs.map((subNav) => (
                    <DSNavigationSideNavSubItem
                      label={subNav.label}
                      id={subNav.id}
                      to={subNav.url}
                      key={subNav.id}
                    />
                  ))) ||
                  undefined}
              </DSNavigationSideNavItem>
            ))}
          </DSNavigationSideNav>
          {settings ? (
            <div className={styles.contentLayout}>
              <DSNewHeader
                title={title}
                subtitle={headerSubtitle}
                parent={parent}
                leftItem={leftItem}
                layouts={layouts}
                activeLayout={activeLayout}
                onChangeLayout={onChangeLayout}
                header={header}
                onClickMenu={() => setNavigationState(!isNavigationOpen)}
                showMenu={!isNavigationOpen}
                isHiddenOnMobile={isHiddenOnMobile}
              />
              <div
                className={cx(styles.childrenLayout, { [styles.relative]: isRelative }, className)}
              >
                <Sentry.ErrorBoundary
                  dialogOptions={{ user: User.getUserData() }}
                  showDialog={isDevelopment}
                  fallback={ApplicationError}
                >
                  {children}
                </Sentry.ErrorBoundary>
              </div>
            </div>
          ) : (
            <div className={styles.contentLayout}>
              <DSNewHeader title={title} />
              <ErrorPage
                message="Désolé, une incohérence dans la configuration nous empêche d’afficher cette page. Nous avons été notifiés et nous mettons tout en œuvre pour corriger le problème. Veuillez réessayer ultérieurement."
                iconSrc={UnknownErrorIcon}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default DSLayout;
