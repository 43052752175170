import { useEffect, useMemo, useRef, useState } from "react";

import Highcharts from "highcharts";
import HighchartsReact, { HighchartsReactRefObject } from "highcharts-react-official";

import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { DSButton, Flex, DSTooltip } from "@skillup/ui";
import { formatMonetary } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import { DashboardCardConfig, QuestionData } from "../../types";

import { PIE_PALETTE } from "../../utils/graph";

import styles from "./Histogram.module.scss";
import { SortTopToBottom } from "./Icons/SortTopBottom";
import { SortBottomToTop } from "./Icons/SortBottomTop";

export type HistogramChartProps = {
  data: QuestionData;
  card: DashboardCardConfig;
  loading: boolean;
  isPaginated: boolean;
};

const PAGE_SIZE = 8;

export const HistogramChart = ({ data, card, loading, isPaginated }: HistogramChartProps) => {
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const chartRef = useRef<HighchartsReactRefObject>(null);

  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<"asc" | "desc">("desc");

  const isMoneyValue = card.question_meta.axes[0].data_type === "money";
  const { currency } = settings;

  useEffect(() => {
    if (!chartRef.current?.chart) {
      return;
    }

    if (!loading) chartRef.current?.chart?.hideLoading();
    if (loading) chartRef.current?.chart?.showLoading();
  }, [loading]);

  const max = useMemo(() => data.data.reduce((acc, curr) => Math.max(acc, curr[0]), 0), [data]);
  const pageNumber = Math.ceil(data.data.length / PAGE_SIZE);

  useEffect(() => {
    if (page >= pageNumber || page < 0) {
      setPage(0);
      return;
    }

    const isInverted = sort === "asc";

    if (!isInverted) {
      const start = page * PAGE_SIZE;
      const end = start + PAGE_SIZE;
      chartRef.current.chart.axes[0].setExtremes(start, Math.min(end, data.data.length) - 1);
    } else {
      let end = data.data.length - page * PAGE_SIZE;
      let start = end - PAGE_SIZE;

      if (start < 0) {
        end -= start;
        start = 0;
      }

      chartRef.current.chart.axes[0].setExtremes(
        Math.max(start, 0),
        Math.min(end, data.data.length) - 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, data, sort]);

  const handlePageUp = () => {
    if (page + 1 < pageNumber) {
      setPage((page) => page + 1);
    }
  };

  const handlePageDown = () => {
    if (page - 1 >= 0) {
      setPage((page) => page - 1);
    }
  };

  const canSort = !card.question_meta.axes.some(
    (axis) => axis.id === "training_plan_employee_INSEE_age_slice"
  );

  return (
    <Flex column>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={{
          chart: {
            type: "column",
          },
          title: {
            text: "",
          },
          subtitle: {
            test: "",
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              dataLabels: {
                crop: true,
                allowOverlap: true,
              },
            },
          },
          tooltip: {
            useHTML: true,
            shadox: false,
            padding: 0,
            borderRadius: 0,
            backgroundColor: "transparent",
            formatter: function () {
              return `<div class='${styles.histogramTooltip}'>
                <span class='${styles.name}'>${this.x}</span>
                <span class='${styles.value}'>${
                  isMoneyValue
                    ? formatMonetary(this.y, {
                        currency,
                      })
                    : this.y.toFixed(0)
                }</span>
              </div>`;
            },
          },
          xAxis: {
            min: 0,
            reversed: sort === "asc",
            lineColor: "#E5E8EB",
            labels: {
              autoRotation: false,
              maxStaggerLines: 1,
              staggerLines: undefined,
              formatter: function () {
                return `<span class="${styles.xLabel}" title="${this.value}">${this.value}</span>`;
              },
              useHTML: true,
            },
            categories: data.data.map(
              (d) =>
                d[1] ?? t("training.dashboard.configuration.panel.no-data", { defaultValue: "N/A" })
            ),
          },
          yAxis: {
            min: 0,
            max,
            gridLineColor: "#E5E8EB",
            gridLineDashStyle: "Dash",
            title: "",
            labels: {
              formatter: function () {
                return `<span class="${styles.yLabel}">${Intl.NumberFormat(i18n.language, {}).format(this.value)}</span>`;
              },
              useHTML: true,
            },
          },
          series: [
            {
              dataSorting: {
                enabled: canSort,
              },
              name: card.short_name,
              color: PIE_PALETTE[0],
              data: data.data.map((d) => ({
                name:
                  d[1] ??
                  t("training.dashboard.configuration.panel.no-data", {
                    defaultValue: "N/A",
                  }),
                y: d[0],
              })),
            },
          ],
        }}
      />

      <Flex
        row
        className={styles.footer}
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        {canSort ? (
          <DSTooltip
            tooltipClassName={styles.sortActionTooltip}
            label={t("dashboard.training.histogram.action.sort", {
              defaultValue: "Inverser l'ordre d'affichage",
            })}
          >
            <DSButton
              buttonSize="M"
              emphasis="Low"
              iconOnly
              icon={sort === "asc" ? <SortBottomToTop /> : <SortTopToBottom />}
              onClick={() => setSort((sort) => (sort === "asc" ? "desc" : "asc"))}
            />
          </DSTooltip>
        ) : (
          <div />
        )}

        {isPaginated && (
          <Flex
            className={styles.pagination}
            style={{ justifyContent: "end", alignItems: "center" }}
          >
            <DSButton
              emphasis="Low"
              buttonSize="M"
              disabled={page === 0}
              onClick={handlePageDown}
              iconOnly
              icon={<ChevronLeft />}
            />
            <span>
              {page + 1}/{pageNumber}
            </span>
            <DSButton
              emphasis="Low"
              buttonSize="M"
              disabled={page === pageNumber - 1}
              onClick={handlePageUp}
              iconOnly
              icon={<ChevronRight />}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
