import { MdMoreVert as MoreVert } from "react-icons/md";
import { DSButton, DSDropdown, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import { QuestionData } from "../types";

export type CardMenuProps = {
  data: QuestionData;
};

export const CardMenu = ({ data }: CardMenuProps) => {
  const { t } = useTranslation();

  const canDisplayAll = data?.data.length > 8;

  return (
    <DSDropdown button={<DSButton iconOnly icon={<MoreVert />} />}>
      {canDisplayAll && (
        <>
          <DSDropdownItem
            label={t("training.dashboard.card.actions.display-all", {
              defaultValue: "Afficher l'intégralité de la données",
            })}
            onClick={() => console.log("display all")}
          />
          <DSDropdownDivider />
        </>
      )}
      <DSDropdownItem
        label={t("training.dashboard.card.actions.copy", {
          defaultValue: "Copier dans le presse-papier",
        })}
        onClick={() => console.log("copy")}
      />
      <DSDropdownDivider />
      <DSDropdownItem
        label={t("training.dashboard.card.actions.export", {
          defaultValue: "Télécharger le graphique...",
        })}
        onClick={() => console.log("export")}
      />
    </DSDropdown>
  );
};
