import {
  type FilterConfiguration,
  type FilterConfigurationMap,
  FilterType,
  type FilterTypes,
  type FilterValue,
} from "./types";

export abstract class IFilterManager<Result, Config extends FilterConfigurationMap> {
  constructor(protected readonly filterConfig: Config) {}

  abstract buildTextFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.TEXT>,
    filterValue: FilterValue<typeof FilterType.TEXT>
  ): Result;

  abstract buildMultiSelectFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.MULTISELECT>,
    filterValue: FilterValue<typeof FilterType.MULTISELECT>
  ): Result;

  abstract buildSingleSelectFilter(
    id: string,
    filter: FilterConfiguration<typeof FilterType.SINGLESELECT>,
    filterValue: FilterValue<typeof FilterType.SINGLESELECT>
  ): Result;

  buildFilter<T extends FilterTypes>(id: string, filterValue: FilterValue<T>): Result | undefined {
    if (!filterValue.value || (filterValue.value === "object" && filterValue.value.length === 0)) {
      return undefined;
    }

    const filter = this.filterConfig[id] as FilterConfiguration<T>;

    if (!filter) {
      return undefined;
    }

    // Typescript does not narrow down the type of objects so we must parse them manually
    const { type } = filter;
    switch (type) {
      case FilterType.TEXT:
        return this.buildTextFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.TEXT>,
          filterValue as FilterValue<typeof FilterType.TEXT>
        );
      case FilterType.MULTISELECT:
        return this.buildMultiSelectFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.MULTISELECT>,
          filterValue as FilterValue<typeof FilterType.MULTISELECT>
        );
      case FilterType.SINGLESELECT:
        return this.buildSingleSelectFilter(
          id.toString(),
          filter as FilterConfiguration<typeof FilterType.SINGLESELECT>,
          filterValue as FilterValue<typeof FilterType.SINGLESELECT>
        );
    }

    return undefined;
  }
}
