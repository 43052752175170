import { MdUndo as Undo } from "react-icons/md";
import { MdRedo as Redo } from "react-icons/md";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";
import { match, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  DSButton,
  DSDropdown,
  DSDropdownDivider,
  DSDropdownItem,
  DSDropdownTitle,
  DSTooltip,
  Flex,
} from "@skillup/ui";
import { TemplatePreviewRoles } from "@skillup/espace-rh-bridge";

import { IWithRouter } from "utils/types";
import { plural } from "utils/locale";
import { isUndoKeyboardEvent, isRedoKeyboardEvent } from "./utils";
import {
  useTemplates,
  type Template,
  useTemplate,
  TemplateAction,
} from "services/interviews/useTemplates";
import {
  init,
  clear,
  setSavedKey,
  selectCanRedo,
  selectCanUndo,
  selectTemplate,
  selectErrors,
  checkErrors,
  Section,
} from "./reducer";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { TemplateActions } from "../components/TemplateActions";
import {
  useTemplatesModals,
  useTemplatesActions,
  State as ActionsModalState,
  ModalStates as ActionsModalStates,
} from "../hooks";

import { TemplateBuilderContent } from "./content";
import { TemplateBuilderStructure } from "./structure";
import { WarningQuitWithoutSave, ErrorsOnSaveModal } from "./modals";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";
import { BuilderProvider, useBuilderContext } from "./BuilderContext";
import { EventService } from "./eventService";
import { asyncPreviewAction, filterObject } from "../utils";
import { BuilderModalsProvider, useBuilderModalsContext } from "./BuilderModalsContext";
import { ErrorBadge } from "../components/ErrorBadge/ErrorBadge";

import styles from "./TemplateBuilder.module.scss";
import { getChildErrors, getPageErrors, getSectionErrors } from "./reducer/utils";

interface TemplateBuilderProps extends IWithRouter {
  match: match<{ templateUuid?: string }>;
}

export enum ScrollTriggeredBy {
  User = "triggered-by-user",
  Builder = "triggered-by-builder",
}
function useTemplateData(templateUuid: string) {
  const { templates } = useTemplates({
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const template = templates.find((t) => t.uuid === templateUuid);
  return { template };
}

export const TemplateBuilder = ({ match: { params } }: TemplateBuilderProps): JSX.Element => {
  const { templateUuid } = params;
  const { template } = useTemplateData(templateUuid);
  const eventService = new EventService();
  const storeDispatch = useDispatch();

  useEffect(() => {
    return () => {
      storeDispatch(clear());
    };
  }, [templateUuid, storeDispatch]);

  return (
    <BuilderProvider template={template} eventService={eventService}>
      <BuilderModalsProvider>
        <TemplateBuilderLayout />
      </BuilderModalsProvider>
    </BuilderProvider>
  );
};

export const TemplateBuilderLayout = memo(() => {
  const { addToast, removeAllToasts } = useToasts();
  const history = useHistory();
  const {
    template,
    structureNavRef,
    contentItemsRefs: itemsRefs,
    focusStructureItemFromErrorDropdown,
    updatedItemIdRef,
  } = useBuilderContext();
  const {
    actions: { canDo },
  } = useTemplates();
  const { saveContent, getPreviewLinks } = useTemplate(template?.uuid);

  // Set up state machine for modals (top-right menu)
  const [{ state: actions }, setActionsState] = useTemplatesActions();
  const openEditTargetSettingsModal = useCallback(
    () => setActionsState({ state: ActionsModalStates.EditTargetSettings, template }),
    [setActionsState, template]
  );

  const templateActionsModalToDisplay = useTemplatesModals(actions, setActionsState);
  const { modalToDisplay: builderModalToDisplay } = useBuilderModalsContext();
  // specific modal quitting warning
  const [isOpenWarningOnQuit, setIsOpenWarningOnQuit] = useState(false);
  const [errorsOnSave, setErrorsOnSave] = useState(0);

  // store template and savedKey for handling save button disabled state
  const [lastSavedKey, setLastSavedKey] = useState<string>();
  const choicesForOrdinalTargets = useRef<string[]>();
  const templateStore = useSelector(selectTemplate);
  const canRedo = useSelector(selectCanRedo);
  const canUndo = useSelector(selectCanUndo);
  const builderErrors = useSelector(selectErrors);
  const isFirstInitTemplate = useRef<boolean>(true);
  const storeDispatch = useDispatch();

  const isLastSavedState = useMemo(
    () => lastSavedKey === templateStore.savedKey,
    [lastSavedKey, templateStore]
  );

  const createAndSetSavedKey = useCallback(() => {
    const savedKey = uuid();
    setLastSavedKey(savedKey);
    storeDispatch(setSavedKey(savedKey));
  }, [storeDispatch, setLastSavedKey]);

  // init the store only once
  useEffect(() => {
    if (template) {
      if (isFirstInitTemplate.current) {
        if (template.sections?.length === 2) {
          updatedItemIdRef.current = {
            uuid: template.sections[1].uuid,
            fromAction: "init",
          };
        }
        storeDispatch(init({ template }));
        createAndSetSavedKey();
        storeDispatch(ActionCreators.clearHistory());
        isFirstInitTemplate.current = false;
      } else {
        if (!isEqual(choicesForOrdinalTargets.current, template.choicesForOrdinalTargets)) {
          choicesForOrdinalTargets.current = template.choicesForOrdinalTargets;
          storeDispatch(
            checkErrors({ choicesForOrdinalTargets: template.choicesForOrdinalTargets })
          );
        }
      }
    } else {
      if (!isFirstInitTemplate.current) {
        history.push("/responsable/templates");
      }
    }
  }, [template, updatedItemIdRef, history, createAndSetSavedKey, storeDispatch]);

  const previewLinks = useCallback(async () => {
    if (template && template.uuid) {
      return getPreviewLinks();
    }
    return null;
  }, [template, getPreviewLinks]);

  const handleSave = useCallback(async () => {
    storeDispatch(
      checkErrors({ choicesForOrdinalTargets: template?.choicesForOrdinalTargets ?? [] })
    );
    const errorsInTemplateItems = getErrorsInTemplateItems(
      templateStore.sections,
      template?.choicesForOrdinalTargets ?? []
    );
    if (errorsInTemplateItems.length > 0 || builderErrors.length > 0) {
      setErrorsOnSave(errorsInTemplateItems.length);
      return;
    }
    setErrorsOnSave(0);

    const filteredSections = templateStore.sections.map((section) => {
      const filteredSection = {
        ...section,
        pages: section.pages.map((page) => {
          const filteredPage = {
            ...page,
            children: page.children.map((child) =>
              filterObject(child, ([key]) => key !== "errors")
            ),
          };
          return filterObject(filteredPage, ([key]) => key !== "errors");
        }),
      };
      return filterObject(filteredSection, ([key]) => key !== "errors");
    });

    try {
      await saveContent({
        sections: filteredSections,
        templateUuid: template.uuid,
      });
      createAndSetSavedKey();
      addToast(
        <span>
          Vos modifications ont été enregistrées. <br />
          <br />
          Pensez à vérifier régulièrement que votre paramétrage est pertinent en prévisualisant un
          entretien :
          <br />
          <br />
          <div className={styles.toaster__button}>
            <DSButton
              buttonSize="S"
              emphasis="Mid"
              label="Côté collaborateur"
              onClick={() => {
                asyncPreviewAction("collab", previewLinks);
                removeAllToasts();
              }}
            />
            <DSButton
              buttonSize="S"
              emphasis="Mid"
              label="Côté responsable"
              onClick={() => {
                asyncPreviewAction("manager", previewLinks);
                removeAllToasts();
              }}
            />
          </div>
        </span>,
        {
          appearance: "success",
          autoDismiss: false,
        }
      );
    } catch (e) {
      addToast("Une erreur est survenue lors de l'enregistrement de la trame", {
        appearance: "error",
      });
      return;
    }
  }, [
    template,
    templateStore,
    saveContent,
    createAndSetSavedKey,
    addToast,
    setErrorsOnSave,
    builderErrors,
    storeDispatch,
    previewLinks,
    removeAllToasts,
  ]);

  const handleBackLink = useCallback(() => {
    if (isLastSavedState) {
      history.push("/responsable/templates");
    } else {
      setIsOpenWarningOnQuit(true);
    }
  }, [isLastSavedState, history]);

  useEffect(() => {
    const undoRedoAction = (event: KeyboardEvent) => {
      if (canUndo && isUndoKeyboardEvent(event)) {
        storeDispatch(ActionCreators.undo());
      }

      if (canRedo && isRedoKeyboardEvent(event)) {
        storeDispatch(ActionCreators.redo());
      }
    };
    document.addEventListener("keydown", undoRedoAction);

    return () => document.removeEventListener("keydown", undoRedoAction);
  }, [canUndo, canRedo, storeDispatch]);

  const itemsErrors = useMemo(() => {
    return builderErrors.flatMap(({ uuid, errors: childErrors }) =>
      childErrors.map(({ header }) => ({
        uuid,
        error: header,
      }))
    );
  }, [builderErrors]);

  return (
    <>
      <DSLayout
        onClickLayout={removeAllToasts}
        title={template?.title ?? ""}
        parent={{
          title: "Trames d'entretien",
          onClick: handleBackLink,
        }}
        layouts={[
          {
            customHeaderItems: (
              <div className={styles.header__buttons__undoRedo}>
                <DSTooltip label="Annuler (Ctrl+Z / ⌘+Z)" lightColor>
                  <DSButton
                    iconOnly
                    darkMode
                    buttonSize="M"
                    emphasis="High"
                    icon={<Undo />}
                    disabled={!canUndo}
                    onClick={() => storeDispatch(ActionCreators.undo())}
                  />
                </DSTooltip>
                <DSTooltip label="Rétablir (Ctrl+Y / ⌘+Y)" lightColor>
                  <DSButton
                    iconOnly
                    darkMode
                    buttonSize="M"
                    emphasis="High"
                    icon={<Redo />}
                    disabled={!canRedo}
                    onClick={() => storeDispatch(ActionCreators.redo())}
                  />
                </DSTooltip>
                {itemsErrors.length > 0 && (
                  <DSTooltip
                    label={`Cette trame contient ${itemsErrors.length} ${plural(
                      itemsErrors.length,
                      "erreur%s"
                    )}. Vous devez ${plural(itemsErrors.length, "la", {
                      pluralText: "les",
                    })} corriger pour enregistrer vos modifications.`}
                  >
                    <DSDropdown button={<ErrorBadge value={itemsErrors.length} />}>
                      {itemsErrors.map(({ uuid, error }) => (
                        <DSDropdownItem
                          key={uuid}
                          onClick={() => {
                            itemsRefs.current[uuid]?.focus();
                            focusStructureItemFromErrorDropdown(uuid);
                          }}
                          label={error}
                        />
                      ))}
                    </DSDropdown>
                  </DSTooltip>
                )}
              </div>
            ),
            primaryButton: (
              <DSNewHeaderButton
                label="Enregistrer les modifications"
                onClick={handleSave}
                disabled={isLastSavedState || builderErrors.length > 0}
              />
            ),
            ...(template &&
              canDo &&
              previewLinks && {
                dropdownContent: getActions({ template, setActionsState, canDo, previewLinks }),
              }),
          },
        ]}
      >
        <Flex row className={styles.TemplateBuilder}>
          <nav
            aria-label="Template Structure"
            className={styles.structure}
            ref={structureNavRef}
            id="structure_nav"
          >
            <TemplateBuilderStructure />
          </nav>
          <TemplateBuilderContent openEditTargetSettingsModal={openEditTargetSettingsModal} />
        </Flex>
      </DSLayout>
      {templateActionsModalToDisplay}
      {builderModalToDisplay}
      <WarningQuitWithoutSave
        isOpen={isOpenWarningOnQuit}
        closeModal={() => setIsOpenWarningOnQuit(false)}
        onConfirm={() => history.push("/responsable/templates")}
      />
      <ErrorsOnSaveModal errors={errorsOnSave} closeModal={() => setErrorsOnSave(0)} />
    </>
  );
});

const getActions = ({
  template,
  setActionsState,
  canDo,
  previewLinks,
}: {
  template: Template;
  setActionsState: (state: ActionsModalState) => void;
  canDo: (action: TemplateAction) => boolean;
  previewLinks: () => Promise<Record<TemplatePreviewRoles, string>>;
}): JSX.Element[] => {
  const actions = [];

  if (canDo("preview")) {
    actions.push(<DSDropdownTitle label="Prévisualiser la dernière version enregistrée…" />);
    actions.push(
      TemplateActions.Preview({
        as: "collab",
        asyncAction: () => asyncPreviewAction("collab", previewLinks),
        label: "...en tant que collaborateur",
      })
    );
    actions.push(
      TemplateActions.Preview({
        as: "manager",
        asyncAction: () => asyncPreviewAction("manager", previewLinks),
        label: "...en tant que responsable",
      })
    );
    actions.push(<DSDropdownDivider />);
  }
  if (canDo("previewAsHr")) {
    actions.push(
      TemplateActions.Preview({
        as: "hr",
        asyncAction: () => asyncPreviewAction("hr", previewLinks),
      })
    );
    actions.push(<DSDropdownDivider />);
  }
  if (canDo("update")) {
    actions.push(
      TemplateActions.EditParams({ template: template, setActionsState: setActionsState })
    );
    actions.push(
      TemplateActions.EditTargetSettings({
        template: template,
        setActionsState: setActionsState,
      })
    );
    actions.push(<DSDropdownDivider />);
  }
  if (canDo("archive")) {
    actions.push(TemplateActions.Archive({ template: template, setActionsState: setActionsState }));
  }

  return actions;
};

const getErrorsInTemplateItems = (
  sections: Section[],
  choicesForOrdinalTargets?: string[]
): string[] => {
  const errors = [];
  sections.reduce((acc, section) => {
    const sectionErrors = getSectionErrors(section);
    if (sectionErrors?.length > 0) errors.push(...sectionErrors);
    section.pages.forEach((page) => {
      const pageErrors = getPageErrors(page);
      if (pageErrors?.length > 0) errors.push(...pageErrors);
      page.children.forEach((child) => {
        const childErrors = getChildErrors(child, choicesForOrdinalTargets);
        if (childErrors?.length > 0) errors.push(...childErrors);
      });
    });
    return errors?.length ? [...acc, ...errors] : acc;
  }, []);
  return errors;
};
