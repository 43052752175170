/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";

import User from "utils/User";

import {
  fetchAnalyticsConfig,
  getFields,
  getQuestions,
  upsertAnalyticsConfig,
} from "../adapters/config";
import { ConfigurationForm } from "../components/Configuration/ConfigurationForm";
import {
  CardConfig,
  CardConfigForm,
  DashboardConfigForm,
  Field,
  PanelConfig,
  PanelConfigForm,
  PanelConfigType,
  Question,
} from "../types";
import style from "../views/Configuration.module.scss";

import { TrainingDashboardContext } from "../context";
import { getPanelType } from "../utils/panel";
import { Flex } from "@skillup/design-system";
import { v4 } from "uuid";

const getCardPropertiesFromQuestion = (
  card: CardConfigForm,
  question: Question,
  type: PanelConfigType
): CardConfig => ({
  short_name: card.short_name ?? question.recommended_short_name,
  question_name: question.name,
  description: "",
  relative_width: type === "summary" || type === "monthly" ? 1 : 0.5,
  selected_charts: question.available_charts,
  selected_filters: question.available_filters,
  x_label: question.fields[0] ?? "",
  y_label: question.fields[1] ?? "",
});

export const TrainingDashboardConfiguration = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [dbConfig, setDbConfig] = useState<any>();
  const [fields, setFields] = useState<Field[]>();
  const [questions, setQuestions] = useState<Question[]>();

  const activeUser = User.getUserData();

  const getData = async (refetch = false) => {
    try {
      if (!refetch) {
        setLoading(true);
        setQuestions(await getQuestions());
      }

      const [config, fields] = await Promise.all([
        fetchAnalyticsConfig(activeUser.activeCompany.uuid),
        getFields(),
      ]);

      setDbConfig(config);
      setFields(fields);
    } finally {
      if (!refetch) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [keyIndicators, detailedIndicators, summaryQuestion, monthlyQuestion] = useMemo(
    () => [
      questions?.filter((question) => question.fields.length === 1),
      questions?.filter((question) => question.fields.length === 2),
      questions?.find((question) => question.name === "training_plan_summary"),
      questions?.find(
        (question) => question.name === "sums_of_prices_with_training_plan_budgets_by_month"
      ),
    ],
    [questions]
  );

  const [indicatorDimensionMap, questionsMap] = useMemo(
    () => [
      detailedIndicators?.reduce((acc, question) => {
        return {
          ...acc,
          [question.fields[0]]: {
            ...acc[question.fields[0]],
            [question.fields[1]]: question.name,
          },
        };
      }, {}),
      questions?.reduce((acc, question) => {
        return {
          ...acc,
          [question.name]: {
            indicator: question.fields[0],
            dimension: question.fields[1],
          },
        };
      }, {}),
    ],
    [detailedIndicators, questions]
  );

  const handleSaveForm = async (config: DashboardConfigForm) => {
    await upsertAnalyticsConfig(activeUser.activeCompany.uuid, {
      short_name: config.short_name,
      description: dbConfig.description,
      panels: config.panels.map((panel: PanelConfigForm) => ({
        short_name: panel.short_name,
        description: "",
        relative_width: 1,
        cards: panel.cards.map((card: CardConfigForm) =>
          getCardPropertiesFromQuestion(
            card,
            questions.find((q) => q.name === card.question_name),
            panel.type
          )
        ),
      })),
      feature_tags: ["training"],
    });
    await getData(true);
  };

  const configuration: DashboardConfigForm = useMemo(() => {
    if (!dbConfig || !questionsMap) {
      return undefined;
    }

    return {
      short_name: dbConfig.short_name,
      panels: dbConfig.panels
        .filter((panel) => panel.cards.length > 0)
        .map((panel: PanelConfig): PanelConfigForm => {
          const type = getPanelType(panel, questions, summaryQuestion, monthlyQuestion);

          if (!type) {
            return undefined;
          }

          return {
            id: v4(),
            type,
            short_name: panel.short_name,
            cards: panel.cards.map((card: CardConfig) => ({
              id: v4(),
              short_name: card.short_name,
              question_name: card.question_name,
              ...questionsMap[card.question_name],
            })),
          };
        })
        .filter(Boolean) as PanelConfigForm[],
    };
  }, [dbConfig, questionsMap]);

  return (
    <Flex className={style.configuration}>
      <div className={style.fixedHeaderWrapper} ref={headerRef} />
      {loading && <span>Loading...</span>}
      {!loading && !configuration && <span>No configuration found</span>}
      {!loading && configuration && (
        <TrainingDashboardContext.Provider
          value={{
            fields,
            detailedIndicatorsUtilities: { indicatorDimensionMap, questionsMap },
            keyIndicators,
            detailedIndicators,
            summaryQuestion,
            monthlyQuestion,
          }}
        >
          <ConfigurationForm
            initialValue={configuration}
            onSubmit={handleSaveForm}
            headerRef={headerRef.current}
          />
        </TrainingDashboardContext.Provider>
      )}
    </Flex>
  );
};
