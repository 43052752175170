import { MdGroups, MdGrading, MdOutlineStar, MdInsertDriveFile } from "react-icons/md";

import { DSColors } from "@skillup/ui";
import { KPI, Pie, Flex, Text, Title, Loader, lightenColor } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { ProcessedSkill, SkillAnalytics } from "types/skills";

import HistogramList from "../../../components/HistogramList";
import ScaleLevelGauge from "../../../components/ScaleLevelGauge";
import { KPIContainer, PieContainer } from "./SkillAnalytic.styled";

type GroupedEmployeesByEvaluation = {
  [level: number]: string[];
};

export default ({
  error,
  loading,
  skillData,
  skillsAnalytics,
}: {
  error: boolean;
  loading: boolean;
  skillData: ProcessedSkill[];
  skillsAnalytics: SkillAnalytics;
}) => {
  const { t } = useTranslation();

  if (loading) return <Loader fillSpace />;

  if (!!error || !skillData?.length || !skillsAnalytics) {
    return <Text>{t("skills.dashboard.error", { defaultValue: "Une erreur est survenue" })}</Text>;
  }

  const groupedEmployeeByEvaluation = skillsAnalytics.employeesList.reduce(
    (acc: GroupedEmployeesByEvaluation, employee) => {
      const { level } = employee;

      if (!acc[level]) acc[level] = [];
      acc[level].push(employee.employeeUuid);

      return acc;
    },
    {}
  );

  const groupedEmployeePercentage = Object.entries(groupedEmployeeByEvaluation).map(
    ([level, employees]) => {
      const percentage = (employees.length / skillsAnalytics.employeesList.length) * 100;
      const getLevelFromScale = skillsAnalytics.latestScale.levels.find(
        (l) => l.level === parseInt(level)
      );

      return { level, levelName: getLevelFromScale.name, percentage };
    }
  );

  const {
    associatedEmployees,
    associatedJobs,
    employeesList,
    evaluatedEmployees,
    jobData,
    latestScale,
    overallAverage,
  } = skillsAnalytics;

  return (
    <Flex width="100%" flexDirection="column">
      <Title h3 color="plainText-onLight-lighter">
        {skillData[0].name}
      </Title>
      <Text espaceFont="captionRegular" color="plainText-onLight-default">
        {t("skills.dashboard.skillMainDesc", {
          defaultValue:
            "Les évaluations présentées ici sont celles des collaborateurs actifs ayant un jour été évalués sur cette compétence, indépendamment du fait que celle-ci soit toujours liée à leurs fiches de poste actuelles.",
        })}
      </Text>
      <KPIContainer>
        <KPI
          Icon={MdInsertDriveFile}
          value={associatedJobs.toString()}
          label={t("skills.dashboard.linkedJobsTitle", {
            defaultValue: "Fiches de poste associées",
          })}
          tooltip={t("skills.dashboard.linkedJobsDesc", {
            defaultValue: "Fiches de poste auxquelles cette compétence est liée",
          })}
        />
        <KPI
          Icon={MdGroups}
          value={associatedEmployees?.toString()}
          label={t("skills.dashboard.linkedEmployeesTitle", {
            defaultValue: "Collaborateurs associés",
          })}
          tooltip={t("skills.dashboard.linkedEmployeesDesc", {
            defaultValue: "Collaborateurs associés à une fiche de poste liée à cette compétence",
          })}
        />
        <KPI
          Icon={MdGrading}
          value={evaluatedEmployees?.toString()}
          label={t("skills.dashboard.evaluatedEmployeesTitle", {
            defaultValue: "Collaborateurs évalués",
          })}
          tooltip={t("skills.dashboard.evaluatedEmployeesDesc", {
            defaultValue: "Nombre de collaborateurs évalués sur cette compétence",
          })}
        />
        <KPI
          Icon={MdOutlineStar}
          value={overallAverage?.toString()}
          label={t("skills.dashboard.averageGradeTitle", {
            defaultValue: "Note moyenne",
          })}
          tooltip={t("skills.dashboard.averageGradeDesc", {
            defaultValue: "Moyenne des évaluations des collaborateurs évalués",
          })}
        />
      </KPIContainer>
      {employeesList.length > 0 && (
        <Flex marginTop="m" flexDirection="column">
          <Title h4 color="plainText-onLight-lighter">
            {t("skills.dashboard.scaleLevelGaugeTitle", {
              defaultValue: "Répartition des collaborateurs sur l’échelle d’évaluation",
            })}
          </Title>
          <ScaleLevelGauge
            name={skillData[0].name}
            employees={employeesList}
            levels={latestScale?.levels}
          />
        </Flex>
      )}
      <Flex justifyContent="space-between">
        {groupedEmployeePercentage.length > 0 && (
          <PieContainer>
            <Flex width="100%" paddingHorizontal="s">
              <Title h4 color="plainText-onLight-lighter">
                {t("skills.dashboard.pieTitle", {
                  defaultValue: "Répartition des évaluations",
                })}
              </Title>
            </Flex>
            <Flex padding="xs" width="100%">
              <Pie
                chartSize={"80%"}
                labelDistance={-40}
                valueTitle={t("skills.dashboard.evaluationPercentage", {
                  defaultValue: "Pourcentage d'évaluation",
                })}
                legend={groupedEmployeePercentage.map((groupedPercentage, index, array) => {
                  const { level, levelName, percentage } = groupedPercentage;
                  const color = lightenColor(
                    DSColors.default["decoration-blue-darker"],
                    (array.length - index - 1) * 10
                  );

                  return {
                    color,
                    name: `${level}. ${levelName}`,
                    y: percentage,
                  };
                })}
              />
            </Flex>
          </PieContainer>
        )}
        {jobData.length > 0 && <HistogramList jobData={jobData} />}
      </Flex>
    </Flex>
  );
};
