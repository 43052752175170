import { useState } from "react";
import { DSDropdown, DSDropdownItem, Icon, DSButton } from "@skillup/ui";

import { v4 } from "uuid";
import useTranslation from "hooks/useTranslation";

import style from "../../../views/Configuration.module.scss";
import { ConfigurationTypeTranslationMap } from "../../../utils/ConfigurationTypeTranslationMap";
import { PanelConfigForm, PanelConfigTypeEnum } from "../../../types";
import { useTrainingDashboardContext } from "../../../context";

export type SortablePanelsProps = {
  offset: number;
  panels: PanelConfigForm[];
  onChange: (value: PanelConfigForm[]) => void;
};

export const AddPanelDropdown = ({ offset, onChange, panels }) => {
  const [opened, openedDD] = useState(false);

  const { t } = useTranslation();
  const { summaryQuestion, monthlyQuestion } = useTrainingDashboardContext();

  const typeLabelMap = ConfigurationTypeTranslationMap(t);
  const { MONTHLY, SUMMARY } = PanelConfigTypeEnum;

  const handleAddPanel = (type: string) => {
    const newPanels = [...panels];

    let defaultCardValue = [];

    if (type === SUMMARY) {
      defaultCardValue = [
        {
          short_name: summaryQuestion.recommended_short_name,
          question_name: summaryQuestion.name,
        },
      ];
    }

    if (type === MONTHLY) {
      defaultCardValue = [
        {
          short_name: monthlyQuestion.recommended_short_name,
          question_name: monthlyQuestion.name,
        },
      ];
    }

    newPanels.splice(offset, 0, {
      id: v4(),
      short_name: typeLabelMap[type],
      cards: defaultCardValue,
      type,
    });

    onChange(newPanels);
  };

  return (
    <DSDropdown
      className={style.dropdown}
      button={
        <DSButton
          label={t("training.dashboard.configuration.panel.add", {
            defaultValue: "Ajouter une section",
          })}
          onClick={() => openedDD(!opened)}
          darkMode={true}
          actionIcon={<Icon.ChevronDown />}
          style={{ margin: 10 }}
          buttonSize="S"
        />
      }
      opened={opened}
    >
      {Object.keys(typeLabelMap).map((type) => (
        <DSDropdownItem
          key={type}
          label={typeLabelMap[type]}
          onClick={() => handleAddPanel(type)}
        />
      ))}
    </DSDropdown>
  );
};
