import { MdHelpOutline as HelpOutline } from "react-icons/md";
import React, { ReactElement, useCallback, useMemo, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { AuthRoutes } from "services";

import {
  NavigationHeader,
  DSAvatar,
  DSDropdown,
  DSDropdownItem,
  DSDropdownDivider,
  NavigationTabs,
  NavigationTab,
} from "@skillup/ui";

import authActions from "actions/auth";
import {
  getUserData,
  getUserInitials,
  isManager,
  isInterviewManager,
  isImpostor,
  isSkillupDeveloper,
  isSkillupAdmin,
} from "utils/User";

import styles from "./DSNewHeaderStyles.module.scss";
import { useIsPentestCompany } from "hooks/useCheckCompanyScope";

interface IParent {
  readonly title: string;
  readonly onClick: () => void;
}

export interface Layout {
  readonly tab?: NavigationTab;
  readonly customHeaderItems?: JSX.Element;
  readonly primaryButton?: JSX.Element;
  readonly dropdownContent?: Array<JSX.Element>;
}

const { REACT_APP_HELP_CENTER_URL } = process.env;
const helpCenterUrl = `${REACT_APP_HELP_CENTER_URL}`;

type Defined<T> = T extends undefined ? never : T;

type ExtractActiveLayoutId<T extends Array<Layout>> = Defined<T[number]["tab"]>["id"];

export interface DSNewHeaderProps<T extends Layout> {
  readonly title: string | JSX.Element;
  readonly subtitle?: JSX.Element;
  readonly parent?: IParent;
  readonly leftItem?: JSX.Element;
  readonly className?: string;
  readonly layouts?: ReadonlyArray<T>;
  readonly activeLayout?: ExtractActiveLayoutId<Array<T>>;
  readonly onChangeLayout?: (tab: string) => void;
  readonly header?: JSX.Element;
  readonly onClickMenu?: (e?: MouseEvent) => void;
  readonly showMenu?: boolean;
  readonly isHiddenOnMobile?: boolean;
}

function DSNewHeader<T extends Layout>({
  title,
  subtitle,
  parent,
  leftItem,
  className,
  header,
  layouts = new Array<T>(),
  activeLayout,
  onChangeLayout,
  onClickMenu,
  showMenu,
  isHiddenOnMobile,
}: DSNewHeaderProps<T>) {
  const userIsManager = isManager() || isInterviewManager();
  const userData = getUserData();
  const history = useHistory();
  const isPentestCompany = useIsPentestCompany();
  const isPasswordUser = userData?.authStrategy === "password";

  const initials = getUserInitials(getUserData());

  const changeView = (status: "collab" | "manager") => {
    switch (status) {
      case "collab":
        location.href = AuthRoutes.computeCompanyUserRoute();
        break;
      case "manager":
        location.href = AuthRoutes.computeManagerRoute();
        break;
    }
  };

  const signout = useCallback(async () => {
    const organization = userData?.activeCompany?.reference;
    await authActions.signout();

    const withQueryArgs = stringify(
      {
        organization,
        reason: "user-logout",
      },
      { addQueryPrefix: true }
    );
    window.location.href = `${process.env.REACT_APP_PUBLIC_APP_URL}connexion/${withQueryArgs}`;
  }, [userData]);

  const MenuCustomItems = useMemo(() => {
    return !activeLayout
      ? layouts[0]?.customHeaderItems
      : layouts.find((e) => e.tab?.id === activeLayout)?.customHeaderItems;
  }, [activeLayout, layouts]);

  const MenuButton = useMemo(() => {
    return !activeLayout
      ? layouts[0]?.primaryButton
      : layouts.find((e) => e.tab?.id === activeLayout)?.primaryButton;
  }, [activeLayout, layouts]);

  const MenuDropdown = useMemo(() => {
    const selectedLayout = layouts.find((e) => e.tab?.id === activeLayout);

    return selectedLayout?.dropdownContent && selectedLayout?.dropdownContent?.length >= 1 ? (
      <DSDropdown className={styles.dropdownContainer} darkMode>
        {selectedLayout.dropdownContent}
      </DSDropdown>
    ) : undefined;
  }, [activeLayout, layouts]);

  const UserMenuDropdown = useMemo(() => {
    const Avatar: ReactElement = (
      <DSAvatar
        type={"Initials"}
        imageUrl={undefined}
        initials={initials}
        darkMode
        randomColorSeedString={userData?.uuid}
      />
    );

    return (
      <DSDropdown button={Avatar}>
        <DSDropdownItem label="Espace responsable" selectable selected />
        {userIsManager && (
          <DSDropdownItem label="Espace manager" selectable onClick={() => changeView("manager")} />
        )}
        <DSDropdownItem
          label="Espace collaborateur"
          selectable
          onClick={() => changeView("collab")}
        />

        {isPentestCompany && isPasswordUser && (
          <>
            <DSDropdownDivider />
            <DSDropdownItem
              label="Changer de mot de passe"
              onClick={() =>
                (window.location.href = `/connexion/modification-mot-de-passe/${btoa(
                  userData.email
                )}`)
              }
            />
          </>
        )}

        <DSDropdownDivider />

        <DSDropdownItem
          label="Centre d'aide"
          onClick={() => {
            window.open(helpCenterUrl, "_blank", "noreferrer");
          }}
          icon={<HelpOutline />}
        />
        <DSDropdownDivider />

        {(isSkillupDeveloper() || isImpostor() || isSkillupAdmin()) && (
          <DSDropdownItem
            label="Changer d'utilisateur"
            onClick={() => {
              const withQueryArgs = stringify(
                {
                  redirect: window.location.href,
                },
                { addQueryPrefix: true }
              );

              history.push(`/connexion-en-tant-que${withQueryArgs}`);
            }}
          />
        )}
        {isSkillupAdmin() && (
          <DSDropdownItem
            label="Changer d'entreprise"
            onClick={() => history.push("/responsable/changer-d-entreprise")}
          />
        )}
        {(isSkillupDeveloper() || isImpostor() || isSkillupAdmin()) && <DSDropdownDivider />}
        <DSDropdownItem label="Déconnexion" onClick={signout} />
      </DSDropdown>
    );
  }, [
    initials,
    userIsManager,
    signout,
    history,
    isPentestCompany,
    isPasswordUser,
    userData.email,
    userData.uuid,
  ]);

  const tabMenu = useMemo(() => {
    return layouts.map((e) => e.tab).filter((e): e is NavigationTab => e !== undefined);
  }, [layouts]);

  return (
    <React.Fragment>
      <NavigationHeader
        className={className}
        title={title}
        subtitle={subtitle}
        showMenu={showMenu}
        onClickMenu={onClickMenu}
        parent={parent}
        leftItem={leftItem}
        isHiddenOnMobile={isHiddenOnMobile}
      >
        {MenuCustomItems}
        {MenuButton}
        {MenuDropdown}
        {UserMenuDropdown}
      </NavigationHeader>
      {header}
      {layouts && activeLayout && (
        <NavigationTabs
          spacingLeft={showMenu}
          tabs={tabMenu}
          activeTab={activeLayout}
          className={className}
          onChangeTab={onChangeLayout}
        />
      )}
    </React.Fragment>
  );
}

export default DSNewHeader;
