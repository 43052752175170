import { Dispatch, SetStateAction, useState } from "react";
import { NewCategoryParams } from "@skillup/espace-rh-bridge";
import {
  DSModal,
  DSTextInput,
  Label,
  DSRadioGroup,
  DSRadio,
  DSCheckbox,
  AssistiveArea,
} from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import styles from "./CreateCategoryModal.module.scss";
import { useToasts } from "react-toast-notifications";
import { useTargetCategories } from "../hooks/useTargetCategories";

type Scale = "ordinal" | "numeric" | undefined;

export const CreateCategoryModal = ({
  close,
  targetCategoriesLabels,
}: {
  close: () => void;
  targetCategoriesLabels: string[];
}) => {
  const [categoryLabel, setCategoryLabel] = useState<string | undefined>(undefined);
  const [scale, setScale] = useState<Scale>("numeric");
  const [goal, setGoal] = useState<boolean>(false);
  const [period, setPeriod] = useState<boolean>(false);
  const [weight, setWeight] = useState<boolean>(false);

  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const { createNewCategory } = useTargetCategories();

  const onTargetSave = async (params: NewCategoryParams) => {
    setIsLoading(true);
    const response = await createNewCategory(params);
    if (response) {
      addToast("Nouvelle catégorie créée", { appearance: "success" });
    } else {
      addToast("Échec lors de la création de la nouvelle catégorie", {
        appearance: "error",
      });
    }
    close();
    setIsLoading(false);
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={close}>
        <DSModal.Header.Title title="Définir une catégorie d'objectifs" />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <TargetLabel
          targetCategoriesLabels={targetCategoriesLabels}
          categoryLabel={categoryLabel}
          setCategoryLabel={setCategoryLabel}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
        />
        <TargetScale scale={scale} setScale={setScale} disabled />
        {scale !== "ordinal" && <TargetGoal goal={goal} setGoal={setGoal} disabled />}
        <TargetProperties
          period={period}
          setPeriod={setPeriod}
          weight={weight}
          setWeight={setWeight}
          disabled
        />
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Annuler" onClick={close} />
        <DSModal.Footer.PrimaryButton
          label="Créer la catégorie"
          onClick={() => onTargetSave({ label: categoryLabel, scale, goal, period, weight })}
          disabled={!categoryLabel || categoryLabel.length === 0 || isDuplicate}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

const TargetLabel = ({
  targetCategoriesLabels,
  categoryLabel,
  setCategoryLabel,
  isDuplicate,
  setIsDuplicate,
}: {
  targetCategoriesLabels: string[];
  categoryLabel?: string;
  setCategoryLabel: (value: string) => void;
  isDuplicate: boolean;
  setIsDuplicate: (value: boolean) => void;
}) => {
  const onLabelChange = (value: string) => {
    setCategoryLabel(value);
    targetCategoriesLabels.includes(value) ? setIsDuplicate(true) : setIsDuplicate(false);
  };

  return (
    <Flex flexDirection="column">
      <Label label="Intitulé de la catégorie" required />
      <DSTextInput
        name="category-label"
        value={categoryLabel}
        placeholder="Saisissez un intitulé pour la catégorie"
        onChange={onLabelChange}
        error={isDuplicate || categoryLabel?.length === 0}
        required
      />
      {(isDuplicate || categoryLabel?.length === 0) && (
        <AssistiveArea
          text={isDuplicate ? "Le nom de la catégorie existe déjà." : "Ce champ doit être rempli."}
          mode="error"
        />
      )}
    </Flex>
  );
};

const TargetScale = ({
  scale,
  setScale,
  disabled,
}: {
  scale: Scale;
  setScale: (type: Scale) => void;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets scale through the categories yet

  return (
    <div>
      <Label label="Type d'objectifs" required className={styles.newSection} />
      <DSRadioGroup name="type-d-objectifs">
        <DSRadio
          label="Quantitatifs"
          checked={scale === "numeric"}
          onChange={() => setScale("numeric")}
        />
        <DSRadio
          label="Qualitatifs"
          checked={scale === "ordinal"}
          onChange={() => setScale("ordinal")}
        />
        <DSRadio
          label="Les deux"
          checked={scale === undefined}
          onChange={() => setScale(undefined)}
        />
      </DSRadioGroup>
    </div>
  );
};

const TargetGoal = ({
  goal,
  setGoal,
  disabled,
}: {
  goal: boolean;
  setGoal: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets goal through the categories yet

  return (
    <Flex flexDirection="column" className={styles.goal}>
      <DSCheckbox
        label="Résultat attendu"
        checked={goal}
        onChange={() => setGoal((oldValue) => !oldValue)}
      />
      <Label
        label="Par défaut, l'évaluation des objectifs quantitatifs s'exprime en pourcentage."
        className={styles.customHelpText}
      />
      <Label
        label="Cochez cette case si vous souhaitez laisser la possibilité de définir une autre unité pour le résultat à atteindre."
        className={styles.customHelpText}
      />
    </Flex>
  );
};

const TargetProperties = ({
  period,
  setPeriod,
  weight,
  setWeight,
  disabled,
}: {
  period: boolean;
  setPeriod: Dispatch<SetStateAction<boolean>>;
  weight: boolean;
  setWeight: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
}) => {
  if (disabled) return <></>; // [TCY-1] we do not enable the setting of the targets weight and period through the categories yet

  return (
    <div>
      <Label label="Propriétés des objectifs de la catégorie" className={styles.newSection} />
      <Flex flexDirection="column">
        <DSCheckbox
          label="Période"
          checked={period}
          onChange={() => setPeriod((oldValue) => !oldValue)}
        />
        <DSCheckbox
          label="Pondération"
          checked={weight}
          onChange={() => setWeight((oldValue) => !oldValue)}
        />
      </Flex>
    </div>
  );
};
