import { useCallback, useMemo, useReducer, useRef } from "react";

import { Flex, Text } from "@skillup/design-system";
import { DSDataGrid, FilterRef, DSFilters, useFilters } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";

import placeholderImg from "assets/collaborator/empty_target.png";
import { Collaborator } from "../../../../api";
import { useTargets, QueryOptions, QueryAction } from "../../hooks";
import useTranslation from "hooks/useTranslation";
import { TargetRow, useTableData } from "./useTableData";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { UserTargetEmpty } from "../UserTargetEmpty";

interface Props {
  readonly user: Collaborator;
  openTargetDetail: (targetUuid: string) => void;
}

const queryOptionsReducer = (state: QueryOptions, action: QueryAction) => {
  switch (action.type) {
    case "sort":
      return { ...state, sort: action.sort, order: action.order };
    case "pagination":
      return { ...state, limit: action.pageSize, offset: action.page * action.pageSize };
    case "filter":
      return { ...state, filter: JSON.stringify({ ...action.filter }) };
    default:
      return state;
  }
};

const filterConfig = {
  name: {
    type: ListUtils.FilterType.TEXT,
    label: "Nom de l'objectif",
  },
  targetCategoryLabel: {
    type: ListUtils.FilterType.TEXT,
    label: "Catégorie",
  },
  "creationCampaign.titleForHR": {
    type: ListUtils.FilterType.TEXT,
    label: "Campagne de fixation",
  },
};

const filterProps = {
  name: { label: "Nom de l'objectif", placeholder: "Filtrer par nom" },
  targetCategoryLabel: { label: "Catégorie", placeholder: "Filtrer par catégorie" },
  "creationCampaign.titleForHR": {
    label: "Campagne de fixation",
    placeholder: "Filtrer par campagne de fixation",
  },
};

export const InProgressUserTargetsDataGrid = ({ user, openTargetDetail }: Props) => {
  const { t } = useTranslation();
  const [queryOptions, dispatch] = useReducer(queryOptionsReducer, {
    limit: 10,
    filter: JSON.stringify({ isEvaluated: false }),
  });
  const filterRef = useRef<FilterRef>();

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      dispatch({ type: "pagination", page, pageSize });
    },
    [dispatch]
  );

  const handleSortModelChange = useCallback(
    (sort: string, order?: "ASC" | "DESC") => {
      dispatch({ type: "sort", sort, order });
    },
    [dispatch]
  );

  const { targetsList, targetsTotal, isLoading } = useTargets({
    userUuid: user.uuid,
    queryOptions,
  });
  const { columns, data } = useTableData(targetsList);

  const handleFilterModelChange = useCallback((col) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  }, []);

  const [filters, filterValues, setFilters] = useFilters(filterConfig, filterProps);

  const targetEmptyWithNoFiltersActive = useMemo(() => {
    return targetsTotal === 0 && !Object.values(filterValues).some((filter) => filter.value);
  }, [filterValues, targetsTotal]);

  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
      dispatch({
        type: "filter",
        filter: {
          isEvaluated: false,
          name: filterValues?.name?.value ? filterValues?.name?.value : undefined,
          targetCategoryLabel: filterValues?.targetCategoryLabel?.value
            ? filterValues?.targetCategoryLabel?.value
            : undefined,
          "creationCampaign.titleForHR": filterValues?.["creationCampaign.titleForHR"]?.value
            ? filterValues?.["creationCampaign.titleForHR"]?.value
            : undefined,
        },
      });
    },
    [setFilters, dispatch]
  );
  const entityName = useMemo(
    () => `${data.length} Objectif${data.length > 1 ? "s" : ""}`,
    [data.length]
  );

  if (targetEmptyWithNoFiltersActive) {
    return <UserTargetEmpty user={user} selectedFilter="inProgress" />;
  }
  return (
    <Flex flexDirection="column">
      {data ? (
        <Flex flexDirection="column">
          <DSFilters
            ref={filterRef}
            t={t}
            config={filterConfig}
            filters={filters}
            onChange={handleFilterValuesChange}
          />
          <DSDataGrid
            columns={columns}
            rows={data}
            sorting={{
              property: queryOptions?.sort ?? "name",
              direction: queryOptions?.order ?? "ASC",
            }}
            onSort={handleSortModelChange}
            onFilter={handleFilterModelChange}
            onPageChange={handlePageChange}
            pagination
            rowCount={targetsTotal}
            entityName={entityName}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            loading={isLoading}
            editable
            slotProps={{
              toolbar: {
                translationPrefix: "inProgressTargetsDatagrid",
                entityName,
              },
            }}
            onRowClick={(params: GridRowParams<TargetRow>) => openTargetDetail(params.row.id)}
          />
        </Flex>
      ) : (
        <Flex flexDirection="column" alignItems="center" flexGrow={1}>
          <img src={placeholderImg} alt="bientôt disponible" />
          <Text espaceFont="body1Regular">
            Aucun objectif n'a encore été défini pour {user.fullName}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
