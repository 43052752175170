import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useSetState } from "react-use";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IScheduleColumn } from "@skillup/types";

import DataTable from "components/DataTable";
import type { IProps as TProps } from "components/DataTable/DataTable";
import type { FiltersWithSort, Row } from "components/DataTable/types";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import { loadPartialData, moveToPlan } from "../utils";

import styles from "../SupervisorTrainingScheduleStyles.module.scss";

import type { GetScheduleDataRoute } from "types/api";
import { ReturnedColumn } from "../utils/loadPartialData";
import { useAreas } from "hooks";
import reloadRows from "../utils/reloadRows";
import { createPortal } from "react-dom";
import SidePanel from "../SidePanel";
import {
  showAdminImport,
  showBookingMailReminderModal,
  showCatalogModal,
  showDeleteManualRowsModal,
  showPositioningModal,
  showValidationMailReminderModal,
  startAddingRowProcess,
  startAddingRowToCollectionProcess,
  startManualEditionProcess,
  startRequalificationProcess,
} from "../SupervisorTrainingScheduleModals";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { pureAddRows } from "../utils/addRows";
import { DSDropdownItem } from "@skillup/ui";
import ExportSchedules from "../utils/ExportSchedules";
import User from "utils/User";

interface IParams {
  readonly tab: GetScheduleDataRoute["query"]["tab"] | "dashboard";
  readonly type: "plan-de-formation" | "recueil-des-besoins";
  readonly uuid: string;
  readonly selectedUUID?: string;
}

export interface IProps {
  containerRef: any;
  settingsVersion: number;
  onSetLayouts: (layouts: { primaryButton: JSX.Element; dropdownContent: JSX.Element[] }) => void;
}

export interface IState {
  columns?: Array<IScheduleColumn>;
  isLoading: boolean;
  rows?: Array<Row>;
  reloadingRows: Array<string>;
  filters?: FiltersWithSort["rawFilters"];
  sort?: FiltersWithSort["rawSort"];
  returnedColumns?: ReturnedColumn[];
}

const SupervisorTrainingList = ({ containerRef, settingsVersion, onSetLayouts }: IProps) => {
  const params = useParams<IParams>();
  const history = useHistory();

  const activeTab = params.tab ?? "dashboard";

  const {
    settings: { currency },
  } = useSettings();
  const {
    i18n: { language },
  } = useTranslation();
  const location = useLocation<{ searchBar?: boolean }>();

  const tableRef = useRef(null);

  const searchParams = new URLSearchParams(location.search);

  const defaultSort = useMemo(() => {
    if (searchParams.has("sortTarget[column]")) {
      return {
        target: { key: searchParams.get("sortTarget[column]") },
        direction: (searchParams.get("sortTarget[direction]") as "up" | "down") ?? "up",
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultFilters = useMemo(() => {
    const entries = Array.from(searchParams.entries());

    const filterEntries = entries.filter(([key]) => !key.includes("sortTarget"));

    return Object.fromEntries(filterEntries as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useSetState<IState>({
    isLoading: false,
    reloadingRows: [],
    sort: defaultSort,
    filters: defaultFilters,
  });

  const areas = useAreas();

  const onSetFilters: TProps["onSetFilters"] = useCallback(({ rawFilters, rawSort }) => {
    setState((state) => ({ ...state, filters: rawFilters, sort: rawSort }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(
    async () => {
      setState((state) => ({ ...state, isLoading: true }));

      try {
        const data = await loadPartialData({
          tab: activeTab as any,
          type: params.type as any,
          scheduleUuid: params.uuid,
          sort: state.sort,
          filters: state.filters,
          areas,
        });

        setState((state) => {
          const newColumns =
            data.returnedColumns.filter(
              (column) => !state.returnedColumns?.some((c) => c.key === column.key)
            ) ?? [];
          const leftColumns =
            state.returnedColumns?.filter((column) =>
              data.returnedColumns.some((c) => c.key === column.key)
            ) ?? [];

          return {
            ...state,
            columns: data.columns,
            rows: data.rows,
            returnedColumns: [...leftColumns, ...newColumns],
          } as any;
        });
      } catch (error) {
        // TODO: do something with error ? user display ?
        console.error(error);
        setState((state) => ({ ...state, rows: [] }));
      } finally {
        setState((state) => ({ ...state, isLoading: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab, params.uuid, params.type, areas, state.filters, state.sort]
  );

  useEffect(() => {
    if (state.isLoading) {
      return;
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsVersion, state.filters, state.sort, params.uuid, areas]);

  const { isLoading, columns = [], reloadingRows, rows, returnedColumns } = state;

  const needScroll = location.state?.searchBar;

  const [selectedRows, setSelectedRows] = useState<string[]>(
    params.selectedUUID ? [params.selectedUUID] : []
  );

  useEffect(() => {
    if (params.selectedUUID) {
      setSelectedRows([params.selectedUUID]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedUUID]);

  const onSelectRows = (selectedRows) => {
    const { search } = location;

    if (selectedRows.length === 1) {
      const pathname = `/responsable${params.uuid ? `/${params.uuid}` : ""}/${params.type}/${
        params.tab
      }/${selectedRows[0]}${search}`;

      history.push(pathname);
    } else if (params.selectedUUID) {
      const pathname = `/responsable${params.uuid ? `/${params.uuid}` : ""}/${params.type}/${
        params.tab || "collection-pending"
      }${search}`;

      history.push(pathname);
    }

    setSelectedRows(selectedRows ?? []);
  };

  const onDeselectRows = () => {
    const { search } = location;
    history.push(
      `/responsable${params.uuid ? `/${params.uuid}` : ""}/${params.type}/${params.tab}${search}`
    );
    setSelectedRows([]);
  };

  const reloadRowsData = useCallback(
    async (uuids: string[]) => {
      if (params.tab === "dashboard") return;

      setState((state) => ({
        ...state,
        reloadingRows: state.reloadingRows.concat(uuids),
      }));

      const data = await reloadRows(
        {
          scheduleUuid: params.uuid,
          tab: params.tab as any,
        },
        uuids
      );

      setState((state) => ({
        ...state,
        rows: state.rows
          .filter((row) => !data.movedRows.includes(row.uuid))
          .map((row) => {
            const updatedRow = data.updatedRows.find((updatedRow) => updatedRow.uuid === row.uuid);
            return updatedRow ?? row;
          }) as Row[],
        reloadingRows: state.reloadingRows.filter((x) => !uuids.includes(x)),
      }));

      setSelectedRows((selectedRows) =>
        selectedRows.filter((rowUuid) => !data.movedRows.includes(rowUuid))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.tab, params.uuid]
  );

  useEffect(() => {
    onSetLayouts({
      primaryButton: (
        <DSNewHeaderButton
          label={
            params.type === "plan-de-formation"
              ? "Ajouter une ligne au plan"
              : "Ajouter une ligne au recueil"
          }
          onClick={() => {
            const toExecute =
              params.type === "plan-de-formation"
                ? startAddingRowProcess
                : startAddingRowToCollectionProcess;

            return toExecute({
              scheduleUuid: params.uuid,
              addRowsToList: async (createdRows: string[]) => {
                const data = await pureAddRows(params.tab as any, params.uuid, createdRows);

                const addedRows = data.updatedRows as any as Row[];

                const addedRowsUuids = addedRows.map((row) => row.uuid);

                setState((state) => {
                  return {
                    ...state,
                    rows: [...addedRows, ...state.rows],
                    reloadingRows: addedRowsUuids,
                  };
                });

                setSelectedRows(addedRowsUuids);

                // After a few milliseconds we remove the loading effect.
                setTimeout(() => {
                  setState((state) => ({ ...state, reloadingRows: [] }));
                }, 300);
              },
              showCatalog: () =>
                showCatalogModal({
                  type: params.type === "plan-de-formation" ? "plan" : "collection",
                  scheduleUuid: params.uuid,
                  onRowsAdded: fetchData,
                }),
            });
          }}
        />
      ),
      dropdownContent: [
        User.isSkillupDeveloper() && (
          <DSDropdownItem
            key="import"
            label="Import"
            onClick={() =>
              showAdminImport(params.uuid, () => reloadRowsData(state.rows.map((r) => r.uuid)))
            }
          />
        ),
        <ExportSchedules
          key="export"
          scheduleUuid={params.uuid}
          areas={areas.activeAreas}
          type={params.type === "plan-de-formation" ? "plan" : "collection"}
        />,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, state.rows, params.uuid, params.type]);

  return (
    <div className={cx({ [styles.dataTableWrapper]: activeTab !== "dashboard" })}>
      <DataTable
        ref={(ref) => (tableRef.current = ref)}
        className={cx(styles.dataTable, {
          [styles.dataTableLoading]: isLoading,
        })}
        onSelectRows={onSelectRows}
        onSetFilters={onSetFilters}
        onLoadRows={reloadRowsData}
        // @ts-ignore
        columns={columns}
        rows={rows}
        selectedRows={selectedRows || []}
        reloadingRows={reloadingRows}
        withFiltersInSearch
        needScroll={needScroll}
        returnedColumns={returnedColumns}
        currency={currency}
        locale={language}
        deactivateMultiSelect={activeTab === "dashboard"}
      />

      {(selectedRows || []).length > 0 &&
        containerRef.current !== null &&
        createPortal(
          <SidePanel
            scheduleUuid={params.uuid}
            reloadRows={reloadRowsData}
            className={styles.sidePanel}
            onClose={onDeselectRows}
            onMoveToPlan={(rowsToPass) => moveToPlan(rowsToPass, params.uuid, reloadRowsData)}
            selectedRows={selectedRows ?? []}
            deselectRows={onDeselectRows}
            useCache
            showRequalifyModal={(rowsToPass, summary, cb) => {
              startRequalificationProcess({
                reload: async () => {
                  reloadRowsData(rowsToPass);
                  cb();
                },
                rows: rowsToPass,
                summary,
              });
            }}
            showManualEditionProcess={(rowsToPass, training, reloadSidePanel) => {
              startManualEditionProcess({
                scheduleUuid: params.uuid,
                rowUuid: rowsToPass[0],
                reload: async (updatedRow: string) => {
                  reloadRowsData([updatedRow]);
                  reloadSidePanel();
                },
                trainingToEdit: training,
              });
            }}
            showDeleteManualRowsModal={(rowsToPass) => {
              showDeleteManualRowsModal({
                reload: async (deletedRows: string[]) => {
                  reloadRowsData(deletedRows);
                },
                rows: rowsToPass,
              });
            }}
            showPositioningModal={(rowsToPass, noEmail, noManager) =>
              showPositioningModal({
                rows: rowsToPass,
                reload: () => reloadRowsData(rowsToPass),
                noEmail,
                noManager,
                scheduleUuid: params.uuid,
              })
            }
            showBookingMailReminderModal={(rowsToPass) =>
              showBookingMailReminderModal(rowsToPass, params.uuid, reloadRowsData)
            }
            showValidationMailReminderModal={(rowsToPass) =>
              showValidationMailReminderModal(rowsToPass, reloadRowsData)
            }
            type={params.type === "plan-de-formation" ? "plan" : "collection"}
          />,
          containerRef.current
        )}
    </div>
  );
};

export default SupervisorTrainingList;
