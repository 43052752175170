import { ListUtils } from "@skillup/shared-utils";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";
import { FiltersConfig } from "containers/Supervisor/routes/PeopleReview/types";

export async function getCampaignMatrix(params: {
  campaignID: string;
  xAxisFieldID: string;
  yAxisFieldID: string;
  filters: ListUtils.FilterValues<FiltersConfig>;
}) {
  const result = await buildRequest<CampaignsRoutes.GetMatrix>({
    method: "POST",
    params: { campaignID: params.campaignID },
    path: `/campaigns/{campaignID}/matrix`,
    payload: {
      filters: params.filters,
    },
    query: {
      fieldIDXAxis: params.xAxisFieldID,
      fieldIDYAxis: params.yAxisFieldID,
    },
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
