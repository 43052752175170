import { z } from "zod";

import { PanelConfigTypeEnum } from "../types";

export const configurationSchema = (t: any) => {
  const requireFieldErrors = {
    required_error: t("fields.form.errors.required", {
      defaultValue: `Ce champ doit être rempli `,
    }),
  };

  return z.object({
    panels: z.array(
      z
        .object({
          id: z.string(),
          short_name: z.string(requireFieldErrors),
          type: z.enum([
            PanelConfigTypeEnum.DETAIL_INDICATORS,
            PanelConfigTypeEnum.KEY_INDICATORS,
            PanelConfigTypeEnum.SUMMARY,
            PanelConfigTypeEnum.MONTHLY,
          ]),
          cards: z
            .array(
              z
                .object({
                  short_name: z.string(),
                  question_name: z.string(),
                  indicator: z.string().optional(),
                  dimension: z.string().optional(),
                })
                .partial()
            )
            .min(1),
        })
        .superRefine((panel, ctx) => {
          for (const cardIndex in panel.cards) {
            const card = panel.cards[cardIndex];

            if (panel.type === PanelConfigTypeEnum.DETAIL_INDICATORS) {
              if (!card.indicator) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: requireFieldErrors.required_error,
                  path: ["cards", cardIndex, "indicator"],
                });

                return;
              }

              if (!card.dimension) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: requireFieldErrors.required_error,
                  path: ["cards", cardIndex, "dimension"],
                });

                return;
              }
            }

            if (!card.short_name) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: requireFieldErrors.required_error,
                path: ["cards", cardIndex, "short_name"],
              });
            }

            if (!card.question_name) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: requireFieldErrors.required_error,
                path: ["cards", cardIndex, "question_name"],
              });
            }
          }
        })
    ),
  });
};
