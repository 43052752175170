import { useMemo, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { DSButton } from "@skillup/ui";
import { Duration } from "@skillup/shared-utils";
import { IParsedField, TrainingFields } from "@skillup/training-bridge";
import type { FieldRoutesType } from "@skillup/espace-rh-bridge";

import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import { buildRequest } from "utils/buildRequest";

import { IntraRoutes } from "types/api";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { IForm } from "../../User/components/UserForm";

import { TrainingForm, isValidIntraData } from "../EditionView/utils";
import { IntraFormV2 } from "../components/IntraFormV2";
import { IntraData } from "../components/IntraForm";

import showCatalogModal from "./showCatalogModal";
import styles from "./IntraCreationView.module.scss";
import { useIntras } from "services/intras";

const getInitialTrainingForm = (initialState?: IntraData) => ({
  description: initialState?.properties?.description,
  duration: initialState?.properties?.duration,
  mode: initialState?.properties?.mode,
  prerequisites: initialState?.properties?.prerequisites,
  price: initialState?.properties?.price,
  priceWithTax: initialState?.properties?.priceWithTax,
  program: initialState?.properties?.program,
  objectives: initialState?.properties?.objectives,
  targetAudience: initialState?.properties?.targetAudience,
  name: initialState?.properties?.name,
  trainingOrganization: initialState?.properties?.trainingOrganization,
  trailer: initialState?.properties?.trailer,
  tag: {
    value: initialState?.tag?.value,
    label: initialState?.tag?.label,
  },
  reference: undefined,
});

export default function IntraCreationView() {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: initialState } = useLocation();
  const { addToast } = useToasts();

  const [fields, setFields] = useState<TrainingFields>({});
  const [fieldsForm, setFieldsForm] = useState<IForm>({});

  const [trainingForm, setTrainingForm] = useState<TrainingForm>(
    getInitialTrainingForm(initialState as IntraData)
  );

  const { invalidateList } = useIntras();

  const { data: companyFields } = useTypedFetch<FieldRoutesType.GetV2>({
    method: "GET",
    path: "/fields-v2",
    query: { showDeletedFields: false, showHiddenFields: false },
  });

  useEffect(() => {
    if (!companyFields) return;
    const isHidden = (field: IParsedField) => field.deletedAt && field.deletedAt !== null;

    setFields(
      companyFields
        .filter((field) => field.isTrainingField && !isHidden(field))
        .reduce((acc, curr) => {
          acc[curr.binding] = curr;
          return acc;
        }, {})
    );
  }, [companyFields]);

  const canCreate = useMemo(() => {
    return trainingForm ? isValidIntraData(trainingForm, true) : false;
  }, [trainingForm]);

  const handleSubmit = async () => {
    const newFields = Object.keys(fieldsForm).map((key) => {
      return {
        binding: key,
        value: fieldsForm[key].value,
      };
    });

    const {
      objectives,
      program,
      prerequisites,
      description,
      targetAudience,
      trainingOrganization,
      duration,
      tag,
      trailer,
    } = trainingForm;

    try {
      const request = buildRequest<IntraRoutes.CREATE_INTRA_FIELDS>({
        path: "/trainings/fields",
        method: "POST",
        payload: {
          trainingData: {
            ...trainingForm,
            objectives: objectives ?? undefined,
            program: program ?? undefined,
            prerequisites: prerequisites ?? undefined,
            description: description ?? undefined,
            targetAudience: targetAudience ?? undefined,
            trainingOrganizationName: trainingOrganization,
            duration: Duration.encodeObjectToString({ hours: duration }),
            tag: {
              uuid: tag?.value,
              name: tag?.label,
              seoSlug: tag?.label.replace(" ", "-"),
            },
            trailer: trailer ?? undefined,
          },
          fieldsData: newFields.filter((f) => f.value),
        },
      });

      await request();

      await invalidateList();

      addToast(
        t("trainings.intra.creation.submitSuccess", {
          defaultValue: "Création effectuée avec succèss",
        }),
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      history.push("/responsable/programmes/gestion-intras/");
    } catch (err) {
      addToast(
        t("trainings.intra.creation.submitError", {
          defaultValue: "Erreur lors de la création",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  return (
    <DSLayout
      title={t("trainings.intra.creation.title", {
        defaultValue: "Création d'un programme",
      })}
      parent={{
        title: t("trainings.intra.creation.intraTitle", {
          defaultValue: "Création d'un intra",
        }),

        onClick: () => history.push("/responsable/programmes/gestion-intras/"),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              disabled={!canCreate}
              tooltip={
                !canCreate
                  ? t("trainings.intra.creation.error.mandatory", {
                      defaultValue: "Veuillez remplir tous les champs obligatoires",
                    })
                  : undefined
              }
              label={t("trainings.intra.creation.submit", {
                defaultValue: "Enregistrer",
              })}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            />
          ),
        },
      ]}
    >
      <div className={styles.creationView}>
        <DSButton
          label={t("trainings.intra.creation.catalog.label", {
            defaultValue: "Créer un programme depuis le catalogue",
          })}
          buttonSize={"S"}
          emphasis={"Mid"}
          onClick={() => showCatalogModal(setTrainingForm, t)}
          type="button"
        />

        <IntraFormV2
          t={t}
          fields={fields}
          trainingForm={trainingForm}
          setTrainingForm={setTrainingForm}
          fieldsForm={fieldsForm}
          setFieldsForm={setFieldsForm}
          creation
        />
      </div>
    </DSLayout>
  );
}
