import { useEffect, useMemo, useState } from "react";

import DSLayout from "components/DSLayout";
import { DSDropdownItem, DSFilters, Flex } from "@skillup/ui";

import Loader from "components/Loader";
import useTranslation from "hooks/useTranslation";
import User from "utils/User";

import { DashboardConfigData } from "../types";
import { defaultConfig } from "../utils/defaultConfig";

import { fetchAnalyticsConfig, upsertAnalyticsConfig } from "../adapters/config";
import { fetchDashboardPanels, getFiltersConfig } from "../adapters/insights";

import { DashboardPanel } from "../components/DashboardPanel";
import history from "utils/history";
import styles from "./Dashboard.module.scss";

export const TrainingDashboard = () => {
  const { t } = useTranslation();

  const layouts = useMemo(
    () => [
      {
        dropdownContent: [
          <DSDropdownItem
            key="configuration"
            onClick={() => {
              history.push("/responsable/dashboard/training/configuration");
            }}
            label={t("training.dashboard.redirect.configuration", {
              defaultValue: "Personnaliser le dashboard",
            })}
          />,
        ],
      },
    ],
    [t]
  );

  return (
    <DSLayout layouts={layouts} title="Dashboard - Formation">
      <DashboardContent />
    </DSLayout>
  );
};

function DashboardContent() {
  const activeUser = User.getUserData();
  const activeSchedule = User.getActiveSchedule();

  const { t } = useTranslation();
  const [filters, setFilters] = useState<{
    config: any;
    props: any;
  }>();
  const [dashboardPanels, setDashboardPanels] = useState<DashboardConfigData["panels"]>();
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});

  const companyUuid = activeUser.activeCompany.uuid;

  useEffect(() => {
    (async () => {
      const analyticsConfig = await fetchAnalyticsConfig(companyUuid);
      if (!analyticsConfig) {
        await upsertAnalyticsConfig(companyUuid, defaultConfig);
      }

      const [panels, filters] = await Promise.all([
        fetchDashboardPanels(),
        getFiltersConfig(activeSchedule.name),
      ]);

      const initialFilterValues = Object.keys(filters.config).reduce((acc, key) => {
        acc[key] = {
          value: filters.props[key].defaultValue,
          operator: filters.props[key].operator,
          type: filters.config[key].type,
        };

        return acc;
      }, {});

      setFilters(filters);
      setFilterValues(initialFilterValues);
      setDashboardPanels(panels);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filters || !filterValues) {
    return (
      <div>
        Waiting for filters to load... <Loader />
      </div>
    );
  }

  return (
    <Flex column className={styles.dashboard}>
      <DSFilters t={t} filters={filters.props} config={filters.config} onChange={setFilterValues} />

      <Flex column className={styles.panels}>
        {dashboardPanels?.map((panel) => (
          <DashboardPanel key={panel.short_name} panel={panel} filters={filterValues} />
        ))}
      </Flex>
    </Flex>
  );
}
