import { MdSearch as Search } from "react-icons/md";
import { Fragment, useCallback, useState } from "react";
import { useToggle } from "react-use";
import { isEmpty, isNil } from "lodash";
import { atom, useRecoilState } from "recoil";

import { colors, DataTable, DSDropdownItem, DSTextInput } from "@skillup/ui";
import { InterviewWithStates, IRoutes } from "@skillup/types";
import { CampaignTemplate, ISO8601Date, InterviewsRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import DataLayer from "utils/DataLayer";
import { plural } from "utils/locale";

import { CampaignUserUpdateModal } from "./modals";
import { ReopenModal } from "./modals";
import { DeletionModal } from "./modals";

import InterviewsHeader from "./InterviewsHeader";
import useTableData from "./useTabledata";

import styles from "./InterviewsStyles.module.scss";

interface IProps {
  readonly campaign: {
    uuid: string;
    titleForHR: string;
    type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
    template?: CampaignTemplate;
    archivedAt?: ISO8601Date;
  };
  readonly interviews: InterviewWithStates[];
  readonly resync: () => Promise<InterviewWithStates[]>;
}

function Interviews({ campaign, interviews, resync }: IProps) {
  const [isDownloadingPDF, toggleDownloadingPDF] = useToggle(false);
  const [isSendingReminder, toggleSendingReminder] = useToggle(false);
  const [filterQuery, setFilterQuery] = useState<string>();

  const downloadInterviewAsPDF = useCallback(
    async (interview): Promise<InterviewWithStates> => {
      if (isDownloadingPDF) return undefined;

      toggleDownloadingPDF(true);

      try {
        await downloadTypedFileAsUser<InterviewsRoutes.GetPDF>(
          {
            method: "GET",
            path: "/interview/{interviewUuid}/pdf",
            params: { interviewUuid: interview.uuid },
          },
          {
            target: "API",
            deduceFileDataFromResponseHeaders: true,
          }
        );
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Impossible de télécharger l'entretien",
          type: "error",
        });
      } finally {
        toggleDownloadingPDF(false);
        return undefined;
      }
    },
    [toggleDownloadingPDF, isDownloadingPDF]
  );
  const sendReminder = useCallback(
    async (interview): Promise<InterviewWithStates> => {
      if (isSendingReminder) return undefined;

      toggleSendingReminder(true);

      try {
        await DataLayer.request({
          method: "POST",
          url: `/v1/campaigns/${campaign.uuid}/interviews/${interview.uuid}/reminder`,
          body: JSON.stringify({}),
        });

        Acta.dispatchEvent("sendAppMessage", {
          message: "Relance envoyée.",
          type: "success",
        });
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Impossible d'envoyer une relance.",
          type: "error",
        });
      } finally {
        toggleSendingReminder(false);
        return undefined;
      }
    },
    [toggleSendingReminder, isSendingReminder, campaign]
  );

  const { columns, data: dataTable } = useTableData(interviews ?? [], campaign.type);
  const paginationHook = useRecoilState(interviewsPagination);

  const interviewsToDisplay =
    isNil(filterQuery) && isEmpty(filterQuery)
      ? dataTable
      : dataTable.filter((interview) =>
          `${interview.data.collaborator} ${interview.data.manager}`
            .toLowerCase()
            .includes(filterQuery.toLowerCase())
        );

  const isCampaignClosed = !isNil(campaign?.archivedAt);

  return (
    <div className={styles.Interviews}>
      <label className={styles.label}>Rechercher un entretien</label>

      <div className={styles.row}>
        <div className={styles.search}>
          <DSTextInput
            name="search-collab-or-manager"
            value={filterQuery}
            onChange={(newQuery) => setFilterQuery(newQuery)}
            placeholder="Collaborateur, responsable"
            actionButton={<Search size="1.375rem" color={colors.blueyGrey} />}
          />
        </div>
      </div>

      <DataTable
        columns={columns}
        rows={interviewsToDisplay}
        header={{
          selectedRowsLabel: (selected, total) =>
            `${plural(selected, "%n entretien%s sélectionné%s")} sur ${total}`,
          totalRowsLabel: (total) => {
            let label = plural(total, "%n entretien%s");
            if (total < interviews.length) {
              label += `${plural(total, " filtré%s")} sur ${interviews.length}`;
            }
            return label;
          },
          actions: () => (
            <InterviewsHeader campaign={campaign} interviews={interviews} resync={resync} />
          ),
        }}
        defaultSort={{ key: "collaboratorStatus", desc: false }}
        actions={(row) => {
          const interview = interviews.find((i) => i.uuid === row.id);
          return (
            <Fragment>
              {campaign.type === "adHoc" && (
                <DSDropdownItem
                  key="send-reminder"
                  label={isSendingReminder ? "Envoi en cours..." : "Envoyer une relance"}
                  onClick={() => {
                    if (!isSendingReminder) return sendReminder(interview);
                    return undefined;
                  }}
                  disabled={isDownloadingPDF}
                />
              )}
              <DSDropdownItem
                key="download-pdf"
                label={isDownloadingPDF ? "Export en cours..." : "Exporter l’entretien (PDF)"}
                onClick={() => {
                  if (!isDownloadingPDF) return downloadInterviewAsPDF(interview);
                  return undefined;
                }}
                disabled={isDownloadingPDF}
              />
              <DSDropdownItem
                key="change-manager"
                label="Changer le responsable de l’entretien"
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <CampaignUserUpdateModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: `Changer le responsable de ${interview.employee.fullName}`,
                  })
                }
                disabled={
                  interview.manager.state === "signed" ||
                  interview.employee.state === "signed" ||
                  isCampaignClosed
                }
              />
              <DSDropdownItem
                key="open-interview"
                label="Rouvrir l’entretien"
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <ReopenModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: "Rouvrir l’entretien",
                  })
                }
                disabled={
                  !(interview.manager.state === "signed" || interview.employee.state === "signed")
                }
              />
              <DSDropdownItem
                key="delete-interview"
                label="Supprimer l’entretien"
                onClick={() =>
                  Acta.setState("modalDisplayed", {
                    content: (
                      <DeletionModal
                        campaignUuid={campaign.uuid}
                        interview={interview}
                        resync={resync}
                      />
                    ),
                    size: "small",
                    title: "Supprimer l'entretien",
                  })
                }
              />
            </Fragment>
          );
        }}
        pagination={{
          rowsPerPageLabel: "Lignes par page",
          itemsCountLabel: "Entretiens %range% sur %count%",
          pageLabel: "Page",
          stateHook: paginationHook,
        }}
        mode="compact"
      />
    </div>
  );
}

const interviewsPagination = atom<number>({
  key: "interviews.pagination",
  default: 10,
});

export default Interviews;
