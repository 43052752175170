import { MdKeyboardArrowDown as ArrowDownKeyboard } from "react-icons/md";
import { MdEdit as Edit } from "react-icons/md";
import { useCallback, useContext, useMemo, useState } from "react";
import { Route, Switch, useHistory, useParams, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import type { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";
import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSButton,
  DSDropdown,
  DSDropdownItem,
  Loader,
  useMediaQueries,
  useModal,
} from "@skillup/ui";
import { useTypedFetch } from "hooks";
import useSettings, { useAccessCheckForList } from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import UserHeader from "./components/UserHeader";
import UserInfo from "./components/UserInfo";
import UserTraining from "./components/UserTraining";
import UserInterview from "./components/UserInterview/UserInterview";
import UserSkills from "./components/UserSkills/UserSkills";
import { UserTargets } from "./components/UserTargets";

import patchUser from "./api/patchUser";

import { UpdateCollaboratorPayload } from "./api";
import EditUserModal from "./components/EditUserModal/EditUserModal";
import { ContextProvider, IContext, SupervisorUserViewContext } from "./Context";

import styles from "./SupervisorUserDetailView.module.scss";
import UserCareerDevMonitoring from "./components/UserCareerDevMonitoring/UserCareerDevMonitoring";
import UserCareer from "./components/UserCareer/UserCareer";
import User from "utils/User";

export default () => (
  <ContextProvider>
    <Container />
  </ContextProvider>
);

const Container = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { isMobile } = useMediaQueries();
  const { uuid, tab } = useParams<{
    uuid?: string;
    tab: string;
  }>();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const { settings, userAccessModules, loading: loadingSettings } = useSettings();
  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const { isOpen: isEditUserOpen, show: showEditUser, hide: closeEditUserModal } = useModal();
  const [isExporting, setExporting] = useState(false);

  const {
    state: {
      training: { filter: trainingFilter },
      interview: { filter: interviewFilter },
    },
  } = useContext<IContext>(SupervisorUserViewContext);

  const canAccess = useAccessCheckForList(["career-data"]);

  const {
    data: user,
    loading: loadingUser,
    error,
    refetch,
  } = useTypedFetch<CollaboratorsRoutes.GetOne>({
    method: "GET",
    path: `/collaborators/{uuid}`,
    params: { uuid },
    query: {
      userDetail: true,
    },
  });

  const isLoading = loadingSettings || loadingUser || loadingUpdate;

  const updateUser = useCallback(
    async (uuid: string, payload: UpdateCollaboratorPayload) => {
      try {
        setLoadingUpdate(true);
        await patchUser(uuid, payload);
        await refetch();
        setLoadingUpdate(false);

        addToast("Les données du collaborateur ont été mises à jour.", {
          appearance: "success",
        });
      } catch (err) {
        setLoadingUpdate(false);
        addToast("Erreur lors de la mise à jour du collaborateur", { appearance: "error" });
      }
    },
    [addToast, setLoadingUpdate, refetch]
  );

  const handleTrainingExport = (mode: "all" | "active") => async () => {
    try {
      setExporting(true);
      await downloadTypedFileAsUser<CollaboratorsRoutes.GetTrainingsExport>(
        {
          method: "GET",
          path: "/collaborators/{uuid}/trainings/export",
          params: { uuid: user.uuid },
          query: mode === "active" ? { state: trainingFilter } : undefined,
        },
        {
          target: "API",
          deduceFileDataFromResponseHeaders: true,
        }
      );
    } catch (error) {
      addToast("Une erreur est survenue pendant le téléchargement du fichier Excel.", {
        appearance: "error",
      });
    } finally {
      setExporting(false);
    }
  };

  const handleInterviewExport = (mode: "all" | "active") => async () => {
    try {
      setExporting(true);
      await downloadTypedFileAsUser<CollaboratorsRoutes.GetInterviewsZIP>(
        {
          method: "GET",
          path: "/collaborators/{uuid}/interviews/zip",
          params: { uuid: user.uuid },
          query: mode === "active" ? { type: interviewFilter } : undefined,
        },
        {
          target: "API",
          deduceFileDataFromResponseHeaders: true,
        }
      );
    } catch (error) {
      addToast("Une erreur est survenue pendant le téléchargement du fichier ZIP.", {
        appearance: "error",
      });
    } finally {
      setExporting(false);
    }
  };

  const isSkillupDeveloper = User.isSkillupDeveloper();

  return (
    <DSLayout
      title={user?.fullName || "..."}
      className={styles.DSLayoutSupervisor}
      parent={{
        title: searchParams.get("previousPageTitle") ?? "Collaborateurs",
        onClick: () => {
          searchParams.get("previousPageURI")
            ? history.push(searchParams.get("previousPageURI"))
            : history.goBack();
        },
      }}
      header={!isLoading && !error && !isMobile && <UserHeader user={user} />}
      layouts={[
        ...(UserAccessChecker.Trainings.toTrainings() && !isMobile
          ? [
              {
                tab: {
                  id: "formations",
                  label: "Formations",
                  url: `/responsable/collaborateurs/${uuid}/formations`,
                },
                primaryButton: (
                  <DSDropdown
                    className={styles.dropdownContainer}
                    button={
                      !isMobile && (
                        <DSButton
                          label="Exporter"
                          emphasis="High"
                          buttonSize="M"
                          actionIcon={<ArrowDownKeyboard />}
                          darkMode
                        />
                      )
                    }
                  >
                    <DSDropdownItem
                      label="Exporter toutes les données"
                      onClick={handleTrainingExport("all")}
                    />
                    <DSDropdownItem
                      label="Exporter la vue active"
                      onClick={handleTrainingExport("active")}
                    />
                  </DSDropdown>
                ),
              },
            ]
          : []),
        ...(UserAccessChecker.Interviews.toInterviews() && !isMobile
          ? [
              {
                tab: {
                  id: "entretiens",
                  label: "Entretiens",
                  url: `/responsable/collaborateurs/${uuid}/entretiens`,
                },
                primaryButton: (
                  <DSDropdown
                    className={styles.dropdownContainer}
                    button={
                      !isMobile && (
                        <DSButton
                          label="Exporter"
                          emphasis="High"
                          buttonSize="M"
                          actionIcon={<ArrowDownKeyboard />}
                          darkMode
                        />
                      )
                    }
                  >
                    <DSDropdownItem
                      label="Exporter les PDF de tous les entretiens"
                      onClick={handleInterviewExport("all")}
                    />
                    <DSDropdownItem
                      label="Exporter les entretiens de la vue active"
                      onClick={handleInterviewExport("active")}
                    />
                  </DSDropdown>
                ),
              },
            ]
          : []),
        ...(!isMobile
          ? [
              {
                tab: {
                  id: "targets",
                  label: "Objectifs",
                  url: `/responsable/collaborateurs/${uuid}/targets`,
                },
              },
            ]
          : []),
        ...(UserAccessChecker.Interviews.toInterviews() &&
        UserAccessChecker.Skills.toSkills() &&
        !isMobile
          ? [
              {
                tab: {
                  id: "competences",
                  label: "Compétences",
                  url: `/responsable/collaborateurs/${uuid}/competences`,
                },
              },
            ]
          : []),
        {
          tab: !isMobile
            ? {
                id: "informations-collaborateur",
                label: "Informations collaborateur",
                url: `/responsable/collaborateurs/${uuid}`,
                alignRight: true,
              }
            : undefined,
          primaryButton: isMobile && (
            <DSNewHeaderButton
              icon={<Edit />}
              emphasis="High"
              buttonSize="S"
              onClick={showEditUser}
              label="Modifier les informations du collaborateur"
            />
          ),
        },
        ...(canAccess["career-data"]
          ? [
              {
                tab: {
                  id: "career",
                  label: "Carrière",
                  url: `/responsable/collaborateurs/${uuid}/career`,
                },
                primaryButton: (
                  <DSButton
                    label="Exporter"
                    emphasis="High"
                    buttonSize="M"
                    darkMode
                    onAbort={() => alert("Export clicked")}
                  />
                ),
              },
            ]
          : []),
        ...(isSkillupDeveloper && !isMobile
          ? [
              {
                tab: {
                  id: "career-dev",
                  label: "[DEV] Carrière",
                  url: `/responsable/collaborateurs/${uuid}/career-dev`,
                },
                primaryButton: (
                  <DSButton
                    label="Exporter"
                    emphasis="High"
                    buttonSize="M"
                    darkMode
                    onAbort={() => alert("Export clicked")}
                  />
                ),
              },
            ]
          : []),
      ]}
      activeLayout={!isMobile ? tab || "informations-collaborateur" : undefined}
    >
      <div className={styles.SupervisorUserDetailView}>
        {isLoading && <Loader className={styles.loader} />}
        {isExporting && (
          <DSAlert
            className={styles.alert}
            type={DSAlertType.WARNING}
            display={DSAlertDisplay.FULL_BLEED}
            closeButton
            onClose={() => setExporting(false)}
          >
            Export en cours. Cela peut durer jusqu’à 1 minute. Ne quittez pas cette page tant que
            l’export n'est pas prêt.
          </DSAlert>
        )}
        {!isLoading && !error && (
          <Switch>
            <Route exact path="/responsable/collaborateurs/:uuid/formations">
              <UserTraining user={user} />
            </Route>
            <Route exact path="/responsable/collaborateurs/:uuid/entretiens">
              <UserInterview user={user} />
            </Route>
            <Route exact path="/responsable/collaborateurs/:uuid/targets">
              <UserTargets user={user} />
            </Route>
            <Route exact path="/responsable/collaborateurs/:uuid">
              <UserInfo user={user} onEditUser={showEditUser} />
            </Route>
            <Route exact path="/responsable/collaborateurs/:uuid/competences">
              <UserSkills user={user} />
            </Route>
            <Route exact path="/responsable/collaborateurs/:uuid/career">
              <UserCareer user={user} />
            </Route>
            {isSkillupDeveloper && (
              <>
                <Route exact path="/responsable/collaborateurs/:uuid/career-dev">
                  <UserCareerDevMonitoring user={user} />
                </Route>
              </>
            )}
          </Switch>
        )}
        {error && (
          <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.FULL_BLEED}>
            Impossible de récupérer les informations du collaborateur
          </DSAlert>
        )}
        {user && (
          <EditUserModal
            isOpen={isEditUserOpen}
            userUuid={user.uuid}
            userData={user}
            closeModal={closeEditUserModal}
            updateRequest={updateUser}
          />
        )}
      </div>
    </DSLayout>
  );
};
