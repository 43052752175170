import { BasicRouteType } from "@skillup/espace-rh-api/src/types";

export type DashboardCardData = {
  short_name: string;
  relative_width: number;
  charts: Array<"table" | "bar" | "pie">;
  filters: string[];
  x_label: string;
  y_label: string;
  question_meta: {
    id: string;
    axes: {
      id: string;
      data_type: "money" | "number" | "duration";
      fingerprint?: {
        distinct_values: string[];
      };
    }[];
  };
};

export type DashboardPanelData = {
  short_name: string;
  relative_width: number;
  cards: DashboardCardData[];
};

export interface DashboardConfigData {
  feature_tags: string[];
  panels: {
    short_name: string;
    relative_width: number;
    cards: {
      short_name: string;
      relative_width: number;
      charts: Array<"table" | "bar" | "pie">;
      filters: string[];
      x_label: string;
      y_label: string;
      question_meta: {
        id: string;
        axes: {
          id: string;
          data_type: "money" | "number" | "duration";
          fingerprint?: {
            distinct_values: string[];
          };
        }[];
      };
    }[];
  }[];
}

export type DashboardCardConfig = DashboardConfigData["panels"][number]["cards"][number];

export interface FiltersConfigData {
  feature_tags: string[];
  filters: {
    id: string;
    field_id: string;
    kind: "multi_select" | "single_select";
    fingerprint: {
      number_distinct_values: number;
      number_empty_values: number;
      distinct_values: string[];
      max: null | number;
      min: null | number;
      mode: {
        value: string;
        occurrences: number;
      };
      axis_short_name: string | null;
    };
  }[];
}

export interface ItemLayout {
  name: string;
  size?: "large" | "small";
  panel?: { name: string; label: string };
  indicators?: { name: string; label: string }[];
}

export interface IndicatorConfig {
  id: number;
  type: string;
  attributes: {
    name: string;
    label: string;
    kinds: Array<"table" | "bar">;
    x_label: string;
    y_label: string;
    value_bindings: {
      [key: string]: {
        [key: string]: string;
      };
    };
    type_bindings: {
      [key: string]: string;
    };
    label_bindings: {
      [key: string]: string;
    };
    panels: { label: string; order: number; width: number }[];
    filters: string[];
  };
}

export interface ActiveIndicator {
  name: string;
  panel?: string | undefined;
  config?: IndicatorConfig["attributes"];
}

export type ChartIndicatorData = Array<{
  label: string;
  rawValue: any;
  numberValue?: number;
  formattedValue: string;
}>;

export interface GetDashboardConfigRouteType extends BasicRouteType {
  method: "GET";
  path: "/config";
  response: {
    data: {
      id: number;
      type: string;
      attributes: {
        dashboards: DashboardConfigData[];
      };
    };
    meta: null; // Replace 'null' with the correct type if necessary
    included: null; // Replace 'null' with the correct type if necessary
  };
}

export interface GetDashboardFiltersRouteType extends BasicRouteType {
  method: "GET";
  path: "/config/dashboards_filters";
  response: {
    data: {
      attributes: {
        dashboards: FiltersConfigData[];
      };
    };
  };
}

export type QuestionData = {
  data: [number, string][];
  columns: { name: string; type: "number" | "string" | "duration" }[];
};

export interface GetDashboardQuestionDataRouteType extends BasicRouteType {
  method: "GET";
  path: "/indicator/{questionName}?{filters}";
  response: {
    data: {
      attributes: QuestionData;
    }[];
  };
}

export interface GetIndicatorConfigRouteType extends BasicRouteType {
  method: "GET";
  path: "/indicator/{name}/config";
  params: {
    name: string;
  };
  response: {
    data: IndicatorConfig[];
    meta: null; // Replace 'null' with the correct type if necessary
    included: null; // Replace 'null' with the correct type if necessary
  };
}

export interface GetIndicatorFiltersRouteType extends BasicRouteType {
  method: "GET";
  path: "/indicator/{name}/filter_values/{filter}";
  params: {
    name: string;
    filter: string;
  };
  response: {
    data: Array<{
      id: number;
      type: string;
      attributes: {
        columns: Array<{
          name: string;
          type: string;
        }>;
        data: Array<String[]>;
      };
    }>;
    meta: null; // Replace 'null' with the correct type if necessary
    included: null; // Replace 'null' with the correct type if necessary
  };
}

export interface GetConfigsRouteType extends BasicRouteType {
  method: "GET";
  path: "/public/configs";
  response: {
    data: {
      id: number;
      type: string;
      attributes: {
        uuid: string[];
      };
    };
    meta: null; // Replace 'null' with the correct type if necessary
    included: null; // Replace 'null' with the correct type if necessary
  };
}

export interface GetConfigRouteType extends BasicRouteType {
  method: "GET";
  path: "/public/config/{companyUuid}";
  response: {
    data: {
      attributes: {
        dashboards: DashboardConfig[];
        axes: [
          {
            field_name: string;
            short_name: string;
            description: string;
            coalescing_value: any;
            value_bindings: any;
          },
        ];
      };
    };
  };
}

export interface GetFieldsRouteType extends BasicRouteType {
  method: "GET";
  path: "/refine/fields";
  response: {
    data: {
      attributes: {
        field_ids: string[];
      };
    };
    meta: null;
    included: null;
  };
}

export interface GetFieldRouteType extends BasicRouteType {
  method: "GET";
  path: `/refine/field/{fieldId}`;
  params: {
    fieldId: string;
  };
  response: {
    data: {
      type: string;
      id: number;
      attributes: Field;
    };
  };
}

export type DashboardConfigForm = {
  short_name: string;
  panels: PanelConfigForm[];
};

export const PanelConfigTypeEnum = {
  DETAIL_INDICATORS: "detail-indicators",
  KEY_INDICATORS: "key-indicators",
  SUMMARY: "summary",
  MONTHLY: "monthly",
} as const;
export type PanelConfigType = (typeof PanelConfigTypeEnum)[keyof typeof PanelConfigTypeEnum];

export type PanelConfigForm = {
  id: string;
  short_name: string;
  type: PanelConfigType;
  cards: CardConfigForm[];
};

export type CardConfigForm = {
  id: string;
  short_name: string;
  question_name: string;
  indicator: string;
  dimension: string;
};

export type DashboardConfig = {
  short_name: string;
  description: string;
  panels: PanelConfig[];
  feature_tags: string[];
};

export type PanelConfig = {
  short_name: string;
  description: string;
  relative_width: number;
  cards: CardConfig[];
};

export type CardConfig = {
  short_name: string;
  description: string;
  relative_width: number;
  question_name: string;
  selected_charts: string[];
  selected_filters: string[];
  x_label: string;
  y_label: string;
};

export type Field = {
  name: string;
  data_type: string;
  recommended_display_name: string;
  recommended_description: string;
  recommended_value_bindings: {
    value_1: string;
  };
  recommended_coalescing_value: string;
};

export type Question = {
  name: string;
  recommended_short_name: string;
  recommended_description: string;
  fields: string[];
  available_charts: string[];
  recommended_x_label: string;
  recommended_y_label: string;
  available_filters: string[];
};

export interface GetQuestionsRouteType extends BasicRouteType {
  method: "GET";
  path: "/refine/questions";
  response: {
    data: {
      attributes: {
        question_ids: string[];
      };
    };
    meta: null;
    included: null;
  };
}

export interface GetQuestionRouteType extends BasicRouteType {
  method: "GET";
  path: `/refine/question/{questionId}`;
  params: { questionId: string };
  response: {
    data: {
      attributes: Question;
    };
  };
}
