import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MdClose as Close } from "react-icons/md";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { Popover, DSButton, DSAvatarGroup } from "@skillup/ui";

import {
  Link,
  RoleContainer,
  CardContainer,
  DeclineButton,
  PopoverContent,
} from "./SuggestionCard.styled";

interface CardProps {
  isDeclined?: boolean;
  openModal: () => void;
  employees: Array<{
    uuid: string;
    fullName: string;
  }>;
}

const SuggestionCard = ({ employees, isDeclined, openModal }: CardProps) => {
  const { t } = useTranslation();
  const [closePopover, setClosePopover] = useState<() => void>();

  return (
    <CardContainer>
      <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
        {employees.length > 1
          ? t("skills.suggestions.employees", {
              count: employees.length,
              defaultValue: "{{count}} collaborateurs",
            })
          : t("skills.suggestions.employee", {
              defaultValue: "1 collaborateur",
            })}
      </Text>
      <Flex marginVertical="s">
        <DSAvatarGroup
          size="S"
          maxItems={5}
          firstElementForeground
          totalItems={employees.length}
          users={employees.map((employee) => ({
            uuid: employee.uuid,
            fullName: employee.fullName,
            initials: getInitials(employee),
          }))}
        />
        <Popover
          actions={({ close }) => {
            setClosePopover(() => close);
          }}
          children={
            <Link>
              {t("skills.suggestions.seeList", {
                defaultValue: "Voir la liste",
              })}
            </Link>
          }
          content={() => (
            <PopoverContent>
              <Flex justifyContent="space-between">
                <Text marginBottom="xxs" espaceFont="captionBold" color="plainText-onLight-default">
                  {employees.length > 1
                    ? t("skills.suggestions.associatedEmployees", {
                        count: employees.length,
                        defaultValue: "{{count}} collaborateurs associés",
                      })
                    : t("skills.suggestions.associatedEmployee", {
                        defaultValue: "1 collaborateur associé",
                      })}
                </Text>
                <DSButton iconOnly buttonSize="S" icon={<Close />} onClick={closePopover} />
              </Flex>

              {employees.map((employee) => (
                <Text padding="xxs" espaceFont="captionRegular" color="plainText-onLight-default">
                  {employee.fullName}
                </Text>
              ))}
            </PopoverContent>
          )}
        />
      </Flex>
      <RoleContainer marginBottom="s">
        <Flex flex={1} flexDirection="column">
          <Text espaceFont="captionRegular">
            {t("skills.suggestions.role", {
              defaultValue: "Rôle",
            })}
          </Text>
          <Text espaceFont="body1Bold" color="plainText-onLight-default">
            Eleveur de grenouilles flamboyantes
          </Text>
        </Flex>
        <Flex marginHorizontal="s">
          <ArrowForwardIcon />
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Text espaceFont="captionRegular">
            {t("skills.suggestions.jobSuggested", {
              defaultValue: "Fiche de poste suggérée",
            })}
          </Text>
          <Text espaceFont="body1Bold" color="plainText-onLight-default">
            Eleveur de poneys arc-en-ciel
          </Text>
        </Flex>
      </RoleContainer>
      <Flex justifyContent="flex-end">
        {!isDeclined && (
          <DeclineButton
            buttonSize="S"
            emphasis="Mid"
            label={t("skills.suggestions.button.decline", {
              defaultValue: "Refuser la suggestion",
            })}
          />
        )}
        <DSButton
          buttonSize="S"
          emphasis="High"
          onClick={openModal}
          label={t("skills.suggestions.button.approve", {
            defaultValue: "Traiter la suggestion",
          })}
        />
      </Flex>
    </CardContainer>
  );
};

export default SuggestionCard;
