import { useRef, useMemo, useState, useEffect, useCallback, MutableRefObject } from "react";

import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import { MdChevronLeft as ChevronLeft } from "react-icons/md";
import { MdChevronRight as ChevronRight } from "react-icons/md";
import { HighchartsReactRefObject } from "highcharts-react-official";

import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

import { DSDropdown } from "@skillup/ui";
import { Text, Flex, Title, Histogram } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import HistogramActions from "./components/HistogramActions";
import {
  IconWrapper,
  AverageLegend,
  ExpectedLegend,
  HistogramContainer,
} from "./HistogramList.styled";

exporting(Highcharts);
exportdata(Highcharts);

interface HistogramListPropsType {
  jobData: Array<{
    jobUuid: string;
    average: number;
    jobName: string;
    evaluations: number;
    expectedLevel?: number;
  }>;
}

const HistogramList = ({ jobData }: HistogramListPropsType) => {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(0);
  const [sortedJobData, setSortedJobData] = useState([...jobData]);
  const [isAscending, setIsAscending] = useState(true);
  const [minAxisValue, setMinAxisValue] = useState(0);
  const [maxAxisValue, setMaxAxisValue] = useState(9);
  const chartRef: MutableRefObject<null | HighchartsReactRefObject> = useRef(null);

  const itemsPerPage = 10;
  const totalPageCount = Math.ceil(jobData.length / itemsPerPage);
  const currentPage = pageIndex + 1;

  useEffect(() => {
    handleSort();
    if (sortedJobData.length > 10) {
      if (chartRef && chartRef.current && chartRef.current.chart) {
        chartRef.current.chart.xAxis[0].setExtremes(minAxisValue, maxAxisValue, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviousPage = () => {
    if (pageIndex === 0) return;

    setPageIndex(pageIndex - 1);
    if (chartRef && chartRef.current && chartRef.current.chart) {
      const newMinAxisValue = Math.max(0, minAxisValue - itemsPerPage);
      const newMaxAxisValue = maxAxisValue - ((maxAxisValue % itemsPerPage) + 1);

      chartRef.current.chart.xAxis[0].setExtremes(newMinAxisValue, newMaxAxisValue, true);
      setMinAxisValue(newMinAxisValue);
      setMaxAxisValue(newMaxAxisValue);
    }
  };

  const handleNextPage = () => {
    if (sortedJobData.length < itemsPerPage) return;

    setPageIndex(pageIndex + 1);

    if (chartRef && chartRef.current && chartRef.current.chart) {
      const newMinAxisValue = minAxisValue + itemsPerPage;
      const newMaxAxisValue = Math.min(maxAxisValue + itemsPerPage, sortedJobData.length - 1);
      chartRef.current.chart.xAxis[0].setExtremes(newMinAxisValue, newMaxAxisValue, true);
      setMinAxisValue(newMinAxisValue);
      setMaxAxisValue(newMaxAxisValue);
    }
  };

  const downloadHighchartsXLS = useCallback(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.downloadXLS();
    }
  }, [chartRef]);

  const downloadHighchartsPNG = useCallback(() => {
    if (chartRef && chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.exportChart({}, chartRef.current.chart.options);
    }
  }, [chartRef]);

  const menuItems = useMemo(() => {
    return HistogramActions({
      downloadHighchartsPNG,
      downloadHighchartsXLS,
      t,
    });
  }, [downloadHighchartsXLS, downloadHighchartsPNG, t]);

  const handleSort = () => {
    const sorted = [...sortedJobData].sort((a, b) =>
      isAscending
        ? Math.round(a.average * 100) - Math.round(b.average * 100)
        : Math.round(b.average * 100) - Math.round(a.average * 100)
    );
    setSortedJobData(sorted);
    setIsAscending(!isAscending);
  };

  const hasAtLeastOneExpectedValue = jobData.some((job) => job.expectedLevel);

  return (
    <HistogramContainer>
      <Flex width="100%" paddingHorizontal="s" flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          <Title h4 color="plainText-onLight-lighter">
            {t("skills.dashboard.histogramTitle", {
              defaultValue: "Moyenne des évaluations par fiche de poste",
            })}
          </Title>
          <DSDropdown>{menuItems}</DSDropdown>
        </Flex>
        <Flex marginVertical="xs">
          <Flex marginRight="s" alignItems="center">
            <AverageLegend />
            <Text espaceFont="captionRegular" color="plainText-onLight-default">
              {t("skills.dashboard.evaluationAverage", {
                defaultValue: "Moyenne des évaluations",
              })}
            </Text>
          </Flex>
          {hasAtLeastOneExpectedValue && (
            <Flex alignItems="center">
              <ExpectedLegend />
              <Text espaceFont="captionRegular" color="plainText-onLight-default">
                {t("skills.dashboard.expectedLevel", {
                  defaultValue: "Niveau attendu",
                })}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex padding="s" width="100%">
        <Histogram
          chartRef={chartRef}
          key={sortedJobData.length < itemsPerPage ? sortedJobData.length : "default"}
          tooltipNameValue={t("skills.dashboard.evaluationAverage", {
            defaultValue: "Moyenne des évaluations",
          })}
          tooltipNameMoreValue={t("skills.dashboard.evaluationsNumber", {
            defaultValue: "Nombre de collaborateurs évalués",
          })}
          tooltipNameComparedValue={
            hasAtLeastOneExpectedValue
              ? t("skills.dashboard.expectedLevel", {
                  defaultValue: "Niveau attendu",
                })
              : undefined
          }
          values={sortedJobData.map((job) => {
            const { average, evaluations, expectedLevel, jobName, jobUuid } = job;

            return {
              comparedValue: hasAtLeastOneExpectedValue ? expectedLevel : undefined,
              moreValue: evaluations,
              name: jobName,
              pathUrl: `/responsable/analyses/fiche-de-poste/${jobUuid}`,
              value: average,
            };
          })}
        />
      </Flex>
      <Flex width="100%" paddingHorizontal="m" justifyContent="space-between">
        <IconWrapper onClick={handleSort}>
          <SignalCellularAltIcon fontSize="small" />
        </IconWrapper>
        <Flex alignItems="center">
          <IconWrapper disabled={pageIndex === 0} onClick={handlePreviousPage}>
            <ChevronLeft size="1.5rem" />
          </IconWrapper>
          <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
            {currentPage}/{totalPageCount}
          </Text>
          <IconWrapper
            disabled={sortedJobData.length < itemsPerPage || totalPageCount === currentPage}
            onClick={handleNextPage}
          >
            <ChevronRight size="1.5rem" />
          </IconWrapper>
        </Flex>
      </Flex>
    </HistogramContainer>
  );
};

export default HistogramList;
