import { useCallback, useRef, useMemo } from "react";
import { ListUtils } from "@skillup/shared-utils";
import {
  DSButton,
  DSDataGrid,
  DSFilters,
  FilterRef,
  useFilters,
  generateModalContext,
} from "@skillup/ui";
import useTranslation from "hooks/useTranslation";
import useTableData from "../hooks/useTableData";
import { plural } from "utils/locale";
import { CreateCategoryModal } from "../modals";
import styles from "./TargetSettings.module.scss";
import { useTargetCategories } from "../hooks/useTargetCategories";

const useModal = generateModalContext();

const filterConfig = {
  title: {
    type: ListUtils.FilterType.TEXT,
    label: "Intitulé de la catégorie",
  },
};

const filtersInput = {
  title: { label: "Intitulé de la catégorie", placeholder: "Filtrer par titre" },
};

export const TargetCategoriesDataGrid = () => {
  const { t } = useTranslation();
  const { targetCategories } = useTargetCategories();

  const { columns, data: tableData } = useTableData(targetCategories);

  const filterRef = useRef<FilterRef>();
  const [filters, filterValues, setFilters] = useFilters(filterConfig, filtersInput);

  const handleFilterModelChange = useCallback((col) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  }, []);

  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
    },
    [setFilters]
  );

  const rows = tableData.filter((row) =>
    filterValues?.title?.value
      ? row.title.toLowerCase().includes(filterValues?.title?.value.toLowerCase())
      : true
  );
  const entityName = useMemo(
    () => `${rows.length} ${plural(rows.length, "catégorie%s")}`,
    [rows.length]
  );

  const createCategoryModal = useModal();

  return (
    <div className={styles.TargetSettings}>
      <DSFilters
        ref={filterRef}
        t={t}
        config={filterConfig}
        filters={filters}
        onChange={handleFilterValuesChange}
      />
      <DSDataGrid
        columns={columns}
        rows={rows}
        initialState={{
          sorting: {
            sortModel: [{ field: "title", sort: "asc" }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode="client"
        entityName={entityName}
        slotProps={{
          toolbar: {
            translationPrefix: "targetCategoriesDatagrid",
            entityName,
          },
        }}
        onFilter={handleFilterModelChange}
      />
      <DSButton
        label={"Ajouter une catégorie"}
        buttonSize="S"
        emphasis="High"
        onClick={createCategoryModal.openModal}
        className={styles.addButton}
      />
      {createCategoryModal.isOpen && (
        <CreateCategoryModal
          close={createCategoryModal.closeModal}
          targetCategoriesLabels={targetCategories.map((tc) => tc.label)}
        />
      )}
    </div>
  );
};
