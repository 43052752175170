import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MdExpandMore as ExpandMore } from "react-icons/md";
import { MdExpandLess as ExpandLess } from "react-icons/md";
import { MdTipsAndUpdates as TipsAndUpdates } from "react-icons/md";

import { Text, Flex, Title } from "@skillup/design-system";
import { DSColor, generateModalContext } from "@skillup/ui";

import DSLayout from "components/DSLayout";

import SuggestionCard from "./components/SuggestionCard";
import ApproveSuggestionModal from "./components/ApproveSuggestionModal";
import { Container, CardContainer, ExpandDeclinedButton } from "./Suggestions.styled";

export const Suggestions = () => {
  const { t } = useTranslation();
  const [showDeclined, setShowDeclined] = useState(false);
  const declinedCount = 12;
  const collaboratorCount = 30;
  const toggleShowDeclined = () => {
    setShowDeclined(!showDeclined);
  };

  const useModal = generateModalContext();
  const {
    closeModal: closeApproveModal,
    isOpen: isModalOpen,
    openModal: openApproveModal,
  } = useModal(false);

  const mockedEmployees = [
    {
      uuid: "1",
      fullName: "John Poe",
    },
    {
      uuid: "2",
      fullName: "Mane Doe",
    },
    {
      uuid: "3",
      fullName: "Tohn Smith",
    },
    {
      uuid: "4",
      fullName: "Kane Mith",
    },
    {
      uuid: "5",
      fullName: "John Doe",
    },
    {
      uuid: "6",
      fullName: "Jane Doe",
    },
    {
      uuid: "7",
      fullName: "John Smith",
    },
    {
      uuid: "8",
      fullName: "Jane Smith",
    },
  ];

  return (
    <DSLayout
      title={t("skills.suggestions.layout.name", {
        defaultValue: "Suggestions d'association de fiches de poste",
      })}
    >
      <Flex width="100%" alignItems="center" flexDirection="column">
        <Container>
          <Flex width="100%" flexDirection="column">
            <Flex alignItems="center">
              <TipsAndUpdates size="1.5rem" color={DSColor["plainText-onLight-lighter"]} />
              <Title h4 marginLeft="xs" color="plainText-onLight-lighter">
                {collaboratorCount > 1
                  ? t("skills.suggestions.title.plurial", {
                      collaboratorCount,
                      defaultValue:
                        "Suggestions d'association pour {{collaboratorCount}} collaborateurs sans fiche de poste",
                    })
                  : t("skills.suggestions.title.single", {
                      defaultValue:
                        "Suggestions d'association pour 1 collaborateur sans fiche de poste",
                    })}
              </Title>
            </Flex>
            <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
              {t("skills.suggestions.info", {
                defaultValue: "Suggestions regroupées en fonction du rôle des collaborateurs",
              })}
            </Text>
          </Flex>
          <CardContainer>
            <SuggestionCard employees={mockedEmployees} openModal={openApproveModal} />
            <SuggestionCard employees={mockedEmployees} openModal={openApproveModal} />
            <SuggestionCard employees={mockedEmployees} openModal={openApproveModal} />
          </CardContainer>
          {declinedCount > 0 && (
            <>
              <ExpandDeclinedButton onClick={toggleShowDeclined}>
                {showDeclined ? (
                  <ExpandLess size="1.5rem" color={DSColor["action-onLight-darkest"]} />
                ) : (
                  <ExpandMore size="1.5rem" color={DSColor["action-onLight-darkest"]} />
                )}
                <Text marginLeft="xxs" espaceFont="body2Regular" color="action-onLight-darkest">
                  {declinedCount > 1
                    ? t("skills.suggestions.showMore.plurial", {
                        declinedCount,
                        defaultValue: "{{declinedCount}} suggestions refusées",
                      })
                    : t("skills.suggestions.showMore.single", {
                        defaultValue: "1 suggestion refusée",
                      })}
                </Text>
              </ExpandDeclinedButton>
              {showDeclined && (
                <>
                  <SuggestionCard
                    isDeclined
                    employees={mockedEmployees}
                    openModal={openApproveModal}
                  />
                  <SuggestionCard
                    isDeclined
                    employees={mockedEmployees}
                    openModal={openApproveModal}
                  />
                </>
              )}
            </>
          )}
          {isModalOpen && (
            <ApproveSuggestionModal
              isOpen={isModalOpen}
              employees={mockedEmployees}
              closeModal={closeApproveModal}
            />
          )}
        </Container>
      </Flex>
    </DSLayout>
  );
};
