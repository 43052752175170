import { ListUtils } from "@skillup/shared-utils";

import { DashboardCardConfig, PanelConfigType, QuestionData } from "../../types";

import { useDashboardCardData } from "../../utils/card";

import { DetailedIndicator } from "./DetailedIndicator";
import { KeyIndicator } from "./KeyIndicator";
import { Summary } from "./Summary";

export type Props = {
  card: DashboardCardConfig;
  filters: ListUtils.FilterValues<any>;
  type: PanelConfigType;
};

export type CardProps = {
  data: QuestionData;
  loading: boolean;
  card: DashboardCardConfig;
};

const ComponentPanelTypeMap: { [key in PanelConfigType]: (props: CardProps) => any } = {
  "detail-indicators": DetailedIndicator,
  "key-indicators": KeyIndicator,
  monthly: ({ data }) => <div>Monthly {JSON.stringify(data)}</div>,
  summary: Summary,
};

export const DashboardCard = ({ card, type, filters }: Props) => {
  const { data, loading } = useDashboardCardData(card, filters);

  const Component = ComponentPanelTypeMap[type];

  return <Component data={data} loading={loading} card={card} />;
};
