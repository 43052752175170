import styled from "styled-components";

import { SkuiSpacing } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

export const Container = styled(Flex)`
  max-width: 990px;
  flex-wrap: wrap;
  padding: ${SkuiSpacing.m};
`;

export const CardContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: ${SkuiSpacing.l};
  margin: ${SkuiSpacing.m} 0;
`;

export const ExpandDeclinedButton = styled(Flex)`
  width: 100%;
  align-items: center;
  cursor: pointer;
`;
