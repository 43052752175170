import { useRef, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GridColDef } from "@mui/x-data-grid-pro";

import { Flex, Loader } from "@skillup/design-system";
import { DSFilters, FilterRef, DSDataGrid } from "@skillup/ui";

import { plural } from "utils/locale";

import useSkillsTableData from "../utils/useSkillsTableData";
import { useSkillsGridFilters } from "../utils/useSkillsGridFilters";
import { AnalyticsProvider, useAnalyticsContext } from "../OverviewContext";

const SkillsOverview = () => {
  return (
    <AnalyticsProvider>
      <Flex width="100%" height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </AnalyticsProvider>
  );
};

const Layout = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const filterRef = useRef<FilterRef>();
  const { getSkillAnalytics } = useAnalyticsContext();

  const [conf, filters, filterValues, setFilterValues] = useSkillsGridFilters(t);

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
      setFilterValues(filterValues);
    }
  };

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 20,
      },
    },
  };

  const { data: skills, status } = getSkillAnalytics(filterValues);

  const { columns, rows: tableData } = useSkillsTableData({
    skills,
    t,
  });

  const rows = tableData
    ?.filter((row) =>
      filterValues?.name?.value
        ? row.name.toLowerCase().includes(filterValues?.name?.value.toLowerCase())
        : true
    )
    .filter((row) =>
      filterValues?.category?.value
        ? row.category.toLowerCase().includes(filterValues?.category?.value.toLowerCase())
        : true
    );

  if (status === "loading") return <Loader fillSpace />;

  const handleRowClick = (row) => {
    const searchParams = new URLSearchParams(location.search);

    history.push(`/responsable/analyses/competence/${row.id}?${searchParams.toString()}`);
  };

  return (
    <Flex paddingTop="s" paddingHorizontal="s" flexDirection="column">
      <DSFilters t={t} config={conf} ref={filterRef} filters={filters} onChange={setFilterValues} />

      <DSDataGrid
        editable
        pagination
        rows={rows ?? []}
        columns={columns}
        persistenceId={"skills"}
        disableRowSelectionOnClick
        initialState={initialState}
        rowCount={rows?.length ?? 0}
        onRowClick={handleRowClick}
        onFilter={handleTableColumnFilterClick}
        entityName={plural(
          rows?.length || 0,
          `%n ${t("skills.list.skill.entityName", {
            defaultValue: "compétence",
          })}%s`
        )}
        emptyOverlay={{
          text: t("skills.list.skill.emptyOverlayText", {
            defaultValue: "Aucune compétence ne correspond à votre recherche.",
          }),
        }}
        errorOverlay={{
          text: [
            t("skills.list.skill.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des compétences.`,
            }),
            t("skills.list.collaborator.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("skills.list.collaborator.errorOverlayText.thirdSentence", {
              defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />
    </Flex>
  );
};

export default SkillsOverview;
