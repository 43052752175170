import { MdDelete as Delete } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";

import { useMutation } from "@tanstack/react-query";

import {
  DropDownCheckbox,
  DSModal,
  DSTextInput,
  DSFormGroupTextInput,
  NumberInput,
  AssistiveArea,
  Select as DSSelect,
  Flex,
  Checkbox,
  DSButton,
  DSRadioGroup,
  DSRadio,
} from "@skillup/ui";
import { DateRangePicker, Text, TimeRangePicker } from "@skillup/design-system";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

import { searchUsersByQuery } from "fetchers/users-fetcher";
import useTranslation from "hooks/useTranslation";
import useAreas from "hooks/useAreas";
import type { ProjectsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import User, { hasColdReviewsEnabled, hasHotReviewsEnabled } from "utils/User";

import {
  formatPayloadForCreation,
  generateReference,
  manageDatesError,
  processTimeError,
} from "./utils";
import { State } from "./types";
import {
  PriceInputs,
  SelectIntraAutocomplete,
  AssistiveErrorText,
  ReferenceInput,
} from "./components";

import styles from "./Modal.module.scss";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  trainingUuid?: string;
  trainingReference?: string;
};

export const CreationModal = ({ onClose, isOpen, trainingUuid, trainingReference }: Props) => {
  const { t } = useTranslation();
  const { allAreas } = useAreas();
  const { addToast } = useToasts();

  const hasAccessToReviews = hasColdReviewsEnabled() || hasHotReviewsEnabled();

  const initialState: State = {
    areas: (allAreas ?? []).map((area) => area.uuid),
    price: { type: "total" },
    priceWithTax: undefined,
    dates: [{ allDay: true }],
    evalGroupsGenerated: hasAccessToReviews ? true : undefined,
    trainers: [],
    intra: undefined,
    min: undefined,
    max: undefined,
  };

  const [state, setState] = useSetState<State>(initialState);
  const firstDate = state.dates?.[0]?.dateRange?.[0];

  const [hasTimeError, setHasTimeError] = useState<{ [key: number]: boolean }>({});

  const setProperty =
    <T extends keyof State>(property: T) =>
    (value: State[T]) =>
      setState({ [property]: value });

  const { mutate, isLoading } = useMutation(async () => {
    try {
      const payload = formatPayloadForCreation(state);

      await buildRequest<ProjectsRoutes.Create>({
        method: "POST",
        path: "/projects",
        payload,
      })();
      closeModal();
    } catch (err) {
      addToast(
        t("supervisor.sessions.creationModal.submitError", {
          defaultValue: "Une erreur est survenue lors de la création.",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      console.error(err);
    }
  });

  const formIsValid = useMemo(() => {
    const requiredFields = [
      "intra",
      "dates",
      "city",
      "price",
      "areas",
      "reference",
      ...(User.isCompanyWithTax() ? ["priceWithTax"] : []),
    ];

    return requiredFields.every((field) => {
      if (field === "price" && (state.price.value === undefined || state.price.value === null))
        return false;
      if (
        field === "priceWithTax" &&
        (state.priceWithTax === undefined || state.priceWithTax === null)
      )
        return false;
      if (
        field === "dates" &&
        (state.dates.length === 0 ||
          state.dates.some((date) => !date.dateRange || date.dateRange.some((d) => !d)))
      )
        return false;
      if (
        field === "dates" &&
        state.dates.some(
          (date) => !date.allDay && (!date.hourRange || date.hourRange.some((d) => !d))
        )
      )
        return false;
      if (field === "dates" && state.dates.some((date) => manageDatesError(date, t))) return false;
      if (field === "areas" && state.areas.length === 0) return false;
      if (state.max && state.min > state.max) return false;
      if (state.max === 0) return false;
      if (state.min < 0) return false;
      if (state[field] === undefined || state[field] === "" || state[field] === null) return false;
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  function setDates(index: number, dateRange: Date[], hourRange?: Date[], allDay = false) {
    setHasTimeError(processTimeError(index, hasTimeError, dateRange, hourRange, allDay));
    setState((state) => {
      return {
        ...state,
        dates: state.dates.map((date, i) => {
          if (i === index) {
            return { dateRange, hourRange, allDay };
          }
          return date;
        }),
      };
    });
  }

  useEffect(() => {
    if (state.intra?.reference) {
      setProperty("reference")(generateReference(state.intra.reference));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.intra?.reference]);

  const closeModal = () => {
    onClose();
  };

  return (
    <DSModal isOpen={isOpen} className={styles.DSModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("trainings.sessions.modal.create.title", {
            defaultValue: "Créer une session",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.modal}>
          <SelectIntraAutocomplete
            intra={state.intra}
            intraUuid={trainingUuid}
            intraReference={trainingReference}
            onChange={(newIntra) => setProperty("intra")(newIntra)}
            placeholder={t("supervisor.sessions.creationModal.program.placeholder", {
              defaultValue: "Sélectionnez un lieu",
            })}
          />

          <section className={styles.infoSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.creationModal.sessionInfo", {
                defaultValue: "Infos de session",
              })}
            </Text>

            <DSFormGroupTextInput className={styles.dateForm} label="Dates" required>
              <>
                {state.dates.map((date, index) => (
                  <DSFormGroupTextInput
                    key={index}
                    className={styles.dateFormGroup}
                    assistiveArea={manageDatesError(date, t) as any}
                  >
                    <Flex wrap className={styles.dateContainer}>
                      <DateRangePicker
                        className={styles.dateRangePicker}
                        onChange={(value) => setDates(index, value, date.hourRange, date.allDay)}
                        slotProps={{
                          popper: {
                            style: {
                              zIndex: 1000,
                            },
                          },
                        }}
                      />
                      {!date.allDay && (
                        <div className={styles.timeRangeContainer}>
                          <TimeRangePicker
                            className={cx({ [styles.timeError]: hasTimeError[index] })}
                            onChange={(value) =>
                              setDates(index, date.dateRange, value, date.allDay)
                            }
                            slotProps={{
                              popper: {
                                style: {
                                  zIndex: 1000,
                                },
                              },
                            }}
                          />
                        </div>
                      )}
                      <Checkbox
                        key="toggle-time"
                        className={styles.toggle}
                        label={t("supervisor.sessions.creationModal.allDay", {
                          defaultValue: "Toute la journée",
                        })}
                        value="all"
                        checked={date.allDay}
                        onClick={() =>
                          setDates(index, date.dateRange, date.hourRange, !date.allDay)
                        }
                      />
                      <DSButton
                        onClick={() => {
                          setProperty("dates")(state.dates.filter((_, i) => i !== index));
                        }}
                        iconOnly
                        icon={<Delete />}
                        buttonSize={"S"}
                        emphasis={"Low"}
                      />
                    </Flex>
                  </DSFormGroupTextInput>
                ))}

                <DSButton
                  label={t("supervisor.sessions.creationModal.addDate", {
                    defaultValue: "Ajouter des dates à la session",
                  })}
                  buttonSize={"S"}
                  emphasis={"Low"}
                  onClick={() => setProperty("dates")(state.dates.concat({ allDay: true }))}
                  type="button"
                />
              </>
            </DSFormGroupTextInput>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.city", {
                defaultValue: "Ville",
              })}
              required
            >
              <DSTextInput
                className={styles.shortInput}
                name="Ville"
                placeholder={t("supervisor.sessions.creationModal.cityPlaceholder", {
                  defaultValue: "Ex: Paris",
                })}
                onChange={setProperty("city")}
              />
            </DSFormGroupTextInput>

            <Flex>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.room", {
                  defaultValue: "Salle",
                })}
              >
                <DSTextInput
                  name="Salle"
                  placeholder={t("supervisor.sessions.creationModal.roomPlaceholder", {
                    defaultValue: "Ex: 439",
                  })}
                  className={styles.shortInput}
                  onChange={setProperty("room")}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.link", {
                  defaultValue: "Lien de connexion",
                })}
              >
                <DSTextInput
                  name="Lien de connexion"
                  placeholder={t("supervisor.sessions.creationModal.linkPlaceholder", {
                    defaultValue: "Ex : https://www.skillup.co/",
                  })}
                  className={styles.shortInput}
                  onChange={setProperty("link")}
                />
              </DSFormGroupTextInput>
            </Flex>

            <DSFormGroupTextInput
              className={styles.priceForm}
              label={t("supervisor.sessions.creationModal.sessionPrice", {
                defaultValue: "Prix de la session",
              })}
              required
            >
              <>
                <AssistiveArea
                  mode="default"
                  text={t("supervisor.sessions.creationModal.sessionPrice.assistiveText", {
                    defaultValue:
                      "Si vous renseignez un prix de session, le prix prévisionnel par participant sera ignoré.",
                  })}
                />

                <DSRadioGroup name="sessionPrice">
                  <DSRadio
                    label={t("supervisor.sessions.creationModal.sessionPrice", {
                      defaultValue: "Prix de la session",
                    })}
                    checked={state.price.type === "total"}
                    onChange={() => {
                      setProperty("price")({
                        type: "total",
                        value: null,
                      });
                      setProperty("priceWithTax")(null);
                    }}
                  />
                  {state.price.type === "total" && (
                    <PriceInputs
                      state={state}
                      setPriceHT={(value) => setProperty("price")({ type: "total", value })}
                      setPriceTTC={(value) => setProperty("priceWithTax")(value)}
                    />
                  )}
                  <DSRadio
                    label={t("supervisor.sessions.creationModal.sessionPrice.provisional", {
                      defaultValue: "Prix prévisionnel par participant",
                    })}
                    checked={state.price.type === "perTrainee"}
                    onChange={() => {
                      setProperty("price")({
                        type: "perTrainee",
                        value: state.intra?.price,
                      });
                      setProperty("priceWithTax")(state.intra?.priceWithTax);
                    }}
                  />
                  {state.price.type === "perTrainee" && (
                    <PriceInputs
                      state={state}
                      setPriceHT={(value) => setProperty("price")({ type: "perTrainee", value })}
                      setPriceTTC={(value) => setProperty("priceWithTax")(value)}
                    />
                  )}
                </DSRadioGroup>
              </>
            </DSFormGroupTextInput>

            <Flex className={styles.attendeeForm} row wrap>
              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.minimum", {
                  defaultValue: "Nombre de participants minimum",
                })}
                className={styles.input}
                assistiveArea={
                  state.min > state.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.creationModal.minimum.inferior", {
                            defaultValue:
                              "La valeur minimale doit être inférieure à la valeur maximale",
                          })}
                        />
                      ) as any)
                    : state.min < 1
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.creationModal.minimum.superior", {
                              defaultValue: "La valeur minimale doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={0}
                  step={1}
                  max={state.max}
                  className={styles.participantsNumber}
                  errored={state.min > state.max || state.min < 1}
                  onChange={setProperty("min")}
                  placeholder={t("supervisor.sessions.creationModal.minimum.placeholder", {
                    defaultValue: "Ex : 1",
                  })}
                />
              </DSFormGroupTextInput>

              <DSFormGroupTextInput
                label={t("supervisor.sessions.creationModal.minimum", {
                  defaultValue: "Nombre de participants maximum",
                })}
                className={styles.input}
                assistiveArea={
                  state.max && state.min > state.max
                    ? ((
                        <AssistiveErrorText
                          errorText={t("supervisor.sessions.creationModal.minimum.inferior", {
                            defaultValue:
                              "La valeur minimale doit être inférieure à la valeur maximale",
                          })}
                        />
                      ) as any)
                    : state.max === 0
                      ? ((
                          <AssistiveErrorText
                            errorText={t("supervisor.sessions.creationModal.maximum.superior", {
                              defaultValue: "La valeur maximal doit être supérieure à 0",
                            })}
                          />
                        ) as any)
                      : undefined
                }
              >
                <NumberInput
                  min={state.min ?? 0}
                  step={1}
                  errored={state.min > state.max || state.max < 1}
                  onChange={setProperty("max")}
                  className={styles.participantsNumber}
                  placeholder={t("supervisor.sessions.creationModal.maximum.placeholder", {
                    defaultValue: "Ex : 10",
                  })}
                />
              </DSFormGroupTextInput>
            </Flex>
          </section>

          <section className={styles.perimeterSection}>
            <Text espaceFont="body2Bold" color="plainText-onLight-default">
              {t("supervisor.sessions.creationModal.parameterSession", {
                defaultValue: "Paramètres de la session",
              })}
            </Text>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.perimeter.label", {
                defaultValue: "Périmètres",
              })}
              className={styles.perimeterForm}
              required
            >
              <DSSelect
                multi
                value={state.areas}
                canSelectAll
                allValuesLabel={t("supervisor.sessions.creationModal.perimeter.optionAll", {
                  defaultValue: "Tous sélectionnés",
                })}
                options={(allAreas ?? []).map((area) => ({
                  value: area.uuid,
                  label: area.name,
                }))}
                onChange={(option) => setProperty("areas")(option ?? [])}
              />
            </DSFormGroupTextInput>

            <DSFormGroupTextInput
              label={t("supervisor.sessions.creationModal.internalTrainer.label", {
                defaultValue: "Formateur(s) interne(s)",
              })}
              className={styles.trainerForm}
            >
              <DropDownCheckbox
                aria-label="trainers"
                labels={{
                  itemSingular: t("supervisor.sessions.creationModal.internalTrainer.optionLabel", {
                    defaultValue: "formateur",
                    count: 1,
                  }),
                  itemPlural: t("supervisor.sessions.creationModal.internalTrainer.optionLabel", {
                    defaultValue: "formateurs",
                    count: 2,
                  }),
                  noItemSelected: t(
                    "supervisor.sessions.creationModal.internalTrainer.noItemSelected",
                    {
                      defaultValue: "Choisissez le(s) formateur(s) parmi vos collaborateurs",
                    }
                  ),
                  searchPlaceholder: t(
                    "supervisor.sessions.creationModal.internalTrainer.searchPlaceholder",
                    {
                      defaultValue: "Rechercher un collaborateur...",
                    }
                  ),
                  noItemFound: t("supervisor.sessions.creationModal.internalTrainer.noItemFound", {
                    defaultValue: "Aucun collaborateur trouvé, veuillez modifier votre recherche.",
                  }),
                }}
                items={state.trainers?.map((user) => ({
                  label: user.fullName,
                  value: user.uuid,
                  isSelected: state.trainers?.some(({ uuid }) => uuid === user.uuid),
                }))}
                onChange={(selection) => {
                  setProperty("trainers")(
                    selection
                      .filter((user) => user.isSelected)
                      .map((user) => ({ uuid: user.value, fullName: user.label }))
                  );
                }}
                canSelectAll={false}
                searchAutoFocus
                onSearch={async (query) => {
                  const users = await searchUsersByQuery(query, USER_FILE_VALIDATION_TYPE.NONE);
                  return users.map((user) => ({
                    label: user.fullName,
                    value: user.uuid,
                    isSelected: !!state.trainers?.some(({ uuid }) => uuid === user.uuid),
                  }));
                }}
              />
            </DSFormGroupTextInput>

            {state.intra?.reference && (
              <ReferenceInput
                onChange={setProperty("reference")}
                programRef={state.intra?.reference}
                sessionDate={!isNaN(firstDate?.getTime()) ? firstDate?.toISOString() : undefined}
                reference={state.reference}
              />
            )}

            {hasAccessToReviews && (
              <DSFormGroupTextInput
                className={styles.reviewsForm}
                label={t("supervisor.sessions.creationModal.reviewSession", {
                  defaultValue: "Paramètre des évaluations dans la session",
                })}
              >
                <>
                  <Checkbox
                    className={styles.toggle}
                    label={t("supervisor.sessions.creationModal.generateReviews", {
                      defaultValue: "Générer les évaluations",
                    })}
                    value="toggle-reviews"
                    checked={state.evalGroupsGenerated}
                    onClick={() => setProperty("evalGroupsGenerated")(!state.evalGroupsGenerated)}
                  />
                  <AssistiveArea
                    mode="default"
                    text={t("supervisor.sessions.creationModal.reviews.assistiveText", {
                      defaultValue:
                        "La génération des évaluations entraînera un envoi d’une invitation à évaluer la formation aux collaborateurs",
                    })}
                  />
                </>
              </DSFormGroupTextInput>
            )}
          </section>
        </div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("common.cancel", { defaultValue: "Annuler" })}
          onClick={closeModal}
        />
        <DSModal.Footer.PrimaryButton
          label={t("supervisor.sessions.creationModal.submit", {
            defaultValue: "Créer la session",
          })}
          disabled={!formIsValid}
          tooltip={
            !formIsValid
              ? t("supervisor.sessions.creationModal.submitError", {
                  defaultValue:
                    "Vous devez remplir tous les champs obligatoires (indiqués par une astérisque rouge)",
                })
              : ""
          }
          loading={isLoading}
          onClick={() => mutate()}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
