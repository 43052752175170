import { ListUtils } from "@skillup/shared-utils";
import { JobRow } from "containers/Supervisor/routes/Skills/Jobs/pages/List/utils/parseJobIntoRow";
import { SkillRow } from "containers/Supervisor/routes/Skills/Skills/pages/List/utils/parseSkillIntoRow";
import { ProcessedSkill } from "types/skills";

interface UserToAvatarParams {
  uuid: string;
  initials: string;
  fullName: string;
}

export const usersToAvatar = (user: UserToAvatarParams) => ({
  uuid: user.uuid,
  initials: user.initials,
  fullName: user.fullName,
});

// This is a temporary fix for the date issue
// The issue is the following:
// Type is define as Date in the ProcessedSkill interface wich is a good thing
// However the back outputs a json with string instead of Dates (not a good thing)
// This function is used to convert the string to Date
export const stringToDates = (skills: ProcessedSkill[]): ProcessedSkill[] => {
  return skills?.map((skill) => {
    return {
      ...skill,
      createdAt: new Date(skill.createdAt),
      updatedAt: new Date(skill.updatedAt),
      archivedAt: skill?.archivedAt ? new Date(skill?.archivedAt) : undefined,
      category: skill.category
        ? {
            ...skill.category,
            deletedAt: skill?.category?.deletedAt
              ? new Date(skill?.category?.deletedAt)
              : undefined,
          }
        : undefined,
    };
  });
};

export const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString());
    reader.onerror = (error) => reject(error);
  });
};

export const cleanBase64 = (base64: string) => {
  return base64.replace(
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    ""
  );
};

export const sortString = (a, b) => a.name.localeCompare(b.name);

export const toUpperCaseFirstChar = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const computeFilteredRows = <T extends SkillRow | JobRow>(
  rows: T[],
  filterValues: ListUtils.FilterValues<any>
): T[] => {
  const result = Object.entries(filterValues).reduce(
    (acc, [key, filter]) => {
      const filterValue = filter?.value?.toLowerCase();

      if (filterValue === "") return acc;

      const rowsFiltered = acc.rows.filter((row) => {
        const fieldFound = row.fields.find((field) => field.name === key);

        if (!fieldFound) {
          const rowValue: string =
            typeof row[key] === "string" ? row[key]?.toLowerCase() : row[key]?.toString();
          return rowValue?.includes(filterValue);
        }

        return fieldFound.value?.toLowerCase().includes(filterValue);
      });

      return { rows: rowsFiltered, value: filterValue };
    },
    { rows: rows.filter((row) => !row.isArchived), value: "" }
  );

  if (result.value === "") {
    return rows.filter((row) => !row.isArchived);
  }

  if (result.rows.length > 0) {
    return result.rows;
  }

  return [];
};
