import { useTranslation } from "react-i18next";
import { useRef, useMemo, useState, Dispatch, useEffect, SetStateAction } from "react";

import { ClickAwayListener } from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  GridColDef,
  GridRowModel,
  useGridApiRef,
  GridCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { ListUtils } from "@skillup/shared-utils";
import {
  Text,
  Flex,
  ColumnsVisibilityPopperWrapper,
  ColumnsVisibilityPopperContent,
} from "@skillup/design-system";
import {
  DSButton,
  DSTooltip,
  DSDropdown,
  DSDataGrid,
  DSDropdownItem,
  VerticalDivider,
  DSDropdownDivider,
} from "@skillup/ui";

import { getReviewsData } from "services/peopleReview/reviews/getReviewsData";
import { removeEmployees } from "services/peopleReview/useCampaignDetails/removeEmployee";
import { arbitrateEmployees } from "services/peopleReview/useCampaignDetails/arbitrateEmployees";
import { updateFieldsDataForEmployee } from "services/peopleReview/field/updateFieldsDataForEmployee";
import {
  exportableColumns,
  exportPPRCampaign,
} from "services/peopleReview/useCampaignDetails/exportCampaign.query";

import { userPopoverColDef } from "./Columns/UserPopover";
import { CommentModal } from "../CommentModal/CommentModal";
import { getActionMenuVisibility } from "./Columns/ActionMenu";
import { Review, Campaign, OpenModal, ActiveModal } from "../../types";
import { AssignActionsModal } from "../AssignActionsModal/AssignActionsModal";
import { ChangeManagerModal } from "../ChangeManagerModal/ChangeManagerModal";
import {
  roleColDef,
  siteColDef,
  emailColDef,
  branchColDef,
  statusColDef,
  managerColDef,
  serviceColDef,
  contractColDef,
  divisionColDef,
  joinDateColDef,
  lastNameColDef,
  firstNameColDef,
  perimetersColDef,
  getActionMenuColDef,
  managerCommentColDef,
  getCustomFieldColDefs,
  getCampaignFieldsColDefs,
  coordinatorCommentColDef,
  registrationNumberColDef,
  getAssignedActionsColDef,
} from "./Columns";

const QUERY_KEY = "people-reviews-campaign-reviews";

/**
 * @wip add missing toasts on mutation success or error
 */

type TalentGridProps = {
  campaign: Campaign;
  filterValues: ListUtils.FilterValues<ListUtils.FilterConfigurationMap>;
};
export function TalentGrid({ campaign, filterValues }: TalentGridProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const [activeModal, setActiveModal] = useState<ActiveModal>(null);
  const [selectedReviews, setSelectedReviews] = useState<Review[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<
    Partial<Record<keyof Review, boolean>>
  >({
    branch: false,
    contract: false,
    division: false,
    email: false,
    firstName: false,
    fullName: true,
    joinDate: false,
    lastName: false,
    ...(campaign.hasManagerPreparation && { manager: false }),
    customField0: false,
    customField1: false,
    customField2: false,
    customField3: false,
    customField4: false,
    customField5: false,
    customField6: false,
    customField7: false,
    customField8: false,
    customField9: false,
    perimeters: true,
    registrationNumber: false,
    role: false,
    service: false,
    site: false,
  });
  const [pagination, setPagination] = useState<ListUtils.PaginationProperties>({
    page: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<ListUtils.SortingProperties>({
    direction: "ASC",
    property: userPopoverColDef.field,
  });

  const { data, error, isLoading } = useQuery(
    [QUERY_KEY, campaign.id, filterValues, sorting, pagination.pageSize, pagination.page],
    () => getReviewsData({ campaignID: campaign.id, filters: filterValues, pagination, sorting }),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const { mutate: mutateField } = useMutation(updateFieldsDataForEmployee, {
    onError: () => {
      apiRef.current.setRows(data.reviews);
      /**
       * @wip add toast
       * @wip add toast
       * @wip add toast
       * @wip add toast
       */
    },
    onSuccess: () => {
      /**
       * @wip use const here
       * @wip use const here
       * @wip use const here
       * @wip use const here
       */
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries(["people-reviews-campaign-reviews", campaign.id]);
      /**
       * @wip add toast
       * @wip add toast
       * @wip add toast
       * @wip add toast
       */
    },
  });

  /**
   * libs/skillup-ui/src/components/DSDataGrid/hooks/usePersistColumnSettings.ts is using apiRef.restoreState()
   * to restore the data-grid column configuration from localstorage.
   * BUT restoreState does not set columnVisibilityModel.
   *
   * Also, as we took control of the columnVisibilityModel, we have to synchronize it ourselves.
   *
   * So we have to manually get the columnVisibilityModel from the localstorage if it exists
   * and set the setColumnVisibilityModel state accordingly
   */
  useEffect(() => {
    if (apiRef.current) {
      const raw = localStorage.getItem(`columns-${persistenceId}`);
      const parsed = JSON.parse(raw);
      if (parsed?.columnVisibilityModel) {
        setColumnVisibilityModel(parsed.columnVisibilityModel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef]);

  /**
   * Synchro the columnVisibilityModel ourselves as the onColumnVisibilityModelChange prop on the data-grid
   * is "lagging". It behave like it's being one step backward of its true columnVisibilityModel.
   */
  useEffect(() => {
    localStorage.setItem(`columns-${persistenceId}`, JSON.stringify({ columnVisibilityModel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnVisibilityModel]);

  const openModal: OpenModal = (modal, reviews) => {
    setActiveModal({
      modal,
      reviews,
    });
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const campaignFieldsColDefs = useMemo(() => getCampaignFieldsColDefs(campaign), [campaign]);
  const assignedActionsColDef = useMemo(() => getAssignedActionsColDef(campaign), [campaign]);
  const actionsColdDef = useMemo(
    () => getActionMenuColDef({ campaign, openModal }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaign]
  );

  const handleOnCellClick = (params: GridCellParams<Review>) => {
    if (params.isEditable) {
      if (apiRef.current.getCellMode(params.id, params.field) === "view") {
        apiRef.current.startCellEditMode({ id: params.id, field: params.field });
      }
    } else {
      switch (params.field) {
        case assignedActionsColDef.field:
          return setActiveModal({
            modal: "assigningActionsV2",
            reviews: [data.reviews.find(({ id }) => id === params.row.id)],
          });

        case coordinatorCommentColDef.field:
        case managerCommentColDef.field:
          return setActiveModal({
            modal: "editingComment",
            reviews: [data.reviews.find(({ id }) => id === params.row.id)],
          });

        case managerColDef.field:
          return setActiveModal({
            modal: "changeManager",
            reviews: [data.reviews.find(({ id }) => id === params.row.id)],
          });
      }
    }
  };

  const persistenceId = `talent-grid.v2.${campaign.id}`;

  const employeeColumns = [
    userPopoverColDef,
    firstNameColDef,
    lastNameColDef,
    ...(campaign.hasManagerPreparation ? [managerColDef] : []),
    roleColDef,
    emailColDef,
    divisionColDef,
    serviceColDef,
    siteColDef,
    joinDateColDef,
    contractColDef,
    branchColDef,
    registrationNumberColDef,
    perimetersColDef,
    ...getCustomFieldColDefs(campaign),
  ];

  const reviewColumns = [
    statusColDef,
    ...campaignFieldsColDefs,
    assignedActionsColDef,
    ...(campaign.hasManagerPreparation ? [managerCommentColDef] : []),
    coordinatorCommentColDef,
  ];

  if (!data) {
    return null;
  }

  return (
    <>
      <DSDataGrid
        editable
        editMode="cell"
        apiRef={apiRef}
        rows={data.reviews}
        loading={isLoading}
        disableColumnFilter
        sortingMode="server"
        rowCount={data.total}
        paginationMode="server"
        isError={Boolean(error)}
        disableRowSelectionOnClick
        persistenceId={persistenceId}
        columnVisibilityModel={columnVisibilityModel}
        experimentalFeatures={{ columnGrouping: true }}
        checkboxSelection={campaign.permissions["mass-selection"].isVisible}
        onCellClick={handleOnCellClick}
        slots={{
          toolbar: Toolbar,
        }}
        onPaginationModelChange={(model) => setPagination(model)}
        onCellKeyDown={(params, event) => {
          if (event.code === "Enter") handleOnCellClick(params);
        }}
        onRowSelectionModelChange={(rowSelectionModel) =>
          setSelectedReviews(data.reviews.filter(({ id }) => rowSelectionModel.includes(id)))
        }
        columns={[
          ...employeeColumns,
          ...reviewColumns,
          ...(getActionMenuVisibility(campaign) ? [actionsColdDef] : []),
        ]}
        onSortModelChange={(model) => {
          setSorting({
            direction: model[0].sort === "asc" ? "ASC" : "DESC",
            property: model[0].field,
          });
        }}
        entityName={t("people-review-campaign.talentgrid.entity-name.employees", {
          count: campaign.reviewCount,
          defaultValue:
            campaign.reviewCount > 1 ? "{{count}} collaborateurs" : "{{count}} collaborateur",
        })}
        emptyOverlay={{
          button: {
            callback: () => apiRef?.current?.setFilterModel({ items: [] }),
            label: t("peopleReview.talentGrid.emptyBtnLabel", {
              defaultValue: "Réinitialiser les filtres",
            }),
          },
          text: t("peopleReview.talentGrid.emptyOverlayText", {
            defaultValue: "Aucun collaborateur ne correspond à vos filtres.",
          }),
        }}
        columnGroupingModel={[
          {
            children: employeeColumns,
            groupId: t("people-review-campaign.talentgrid.column-group.employee", {
              defaultValue: "Données collaborateur",
            }),
          },
          {
            children: reviewColumns,
            groupId: t("people-review-campaign.talentgrid.column-group.arbitration", {
              defaultValue: "Arbitrage",
            }),
          },
        ]}
        processRowUpdate={(newRow: GridRowModel<Review>) => {
          mutateField({
            campaignId: campaign.id,
            data: Object.keys(newRow.fields)
              .map((fieldID) => ({
                fieldID,
                value: newRow.fields[fieldID] == null ? null : Number(newRow.fields[fieldID]),
              }))
              .filter(({ value }) => value !== null),
            reviewID: newRow.id,
          });

          return newRow;
        }}
        initialState={{
          pagination: {
            paginationModel: pagination,
          },
          pinnedColumns: {
            left: [
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              ...employeeColumns.map(({ field }) => field),
            ],
            right: [actionsColdDef.field],
          },
          sorting: {
            sortModel: [
              {
                field: userPopoverColDef.field,
                sort: "asc",
              },
            ],
          },
        }}
        slotProps={{
          toolbar: {
            campaign: campaign,
            columns: employeeColumns,
            columnVisibilityModel: columnVisibilityModel,
            filteredReviewCount: data.total,
            openModal: openModal,
            selectedReviews: selectedReviews,
            setColumnVisibilityModel: (value) => {
              apiRef.current.publishEvent("columnVisibilityModelChange", null);
              return setColumnVisibilityModel(value);
            },
          } satisfies ToolbarProps,
        }}
        errorOverlay={{
          text: [
            t("peopleReview.talentGrid.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des collaborateurs.`,
            }),
            t("peopleReview.talentGrid.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("peopleReview.talentGrid.errorOverlayText.thirdSentence", {
              defaultValue: `Si l'erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />

      <Modals campaign={campaign} closeModal={closeModal} activeModal={activeModal} />
    </>
  );
}

type ModalProps = {
  campaign: Campaign;
  closeModal: () => void;
  activeModal: ActiveModal;
};
function Modals({ activeModal, campaign, closeModal }: ModalProps) {
  if (activeModal) {
    switch (activeModal.modal) {
      case "assigningActionsV2":
        return (
          <AssignActionsModal
            campaign={campaign}
            review={activeModal.reviews[0]}
            onClose={closeModal}
          />
        );

      case "changeManager": {
        return (
          <ChangeManagerModal
            campaign={campaign}
            reviews={activeModal.reviews}
            onClose={closeModal}
          />
        );
      }

      case "editingComment":
        return (
          <CommentModal campaign={campaign} review={activeModal.reviews[0]} onClose={closeModal} />
        );
    }
  }

  return null;
}

type ToolbarProps = {
  campaign: Campaign;
  openModal: OpenModal;
  selectedReviews: Review[];
  filteredReviewCount: number;
  columns: GridColDef<Review>[];
  columnVisibilityModel: Record<string, boolean>;
  setColumnVisibilityModel: Dispatch<SetStateAction<Record<string, boolean>>>;
};
function Toolbar({
  campaign,
  columns,
  columnVisibilityModel,
  filteredReviewCount,
  openModal,
  selectedReviews,
  setColumnVisibilityModel,
}: ToolbarProps) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const popperAnchorRef = useRef<HTMLDivElement>(null);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const { isLoading: isArbitrateLoading, mutate: arbitrate } = useMutation(arbitrateEmployees, {
    onSuccess() {
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, campaign.id] });
      /**
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * To do so, export the useQuery in some file and export the key separately
       */
    },
  });
  const { isLoading: isRemoveLoading, mutate: remove } = useMutation(removeEmployees, {
    onSuccess() {
      queryClient.invalidateQueries(["people-reviews-campaign", campaign.id]);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, campaign.id] });
      /**
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * @wip invalidate campaign progress too
       * To do so, export the useQuery in some file and export the key separately
       */
    },
  });
  const { isLoading: isExportLoading, mutate: export_ } = useMutation(exportPPRCampaign, {});

  return (
    <Flex marginBottom="xs" justifyContent="space-between">
      <Flex alignItems="center">
        {filteredReviewCount < campaign.reviewCount ? (
          <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
            {t("people-review-campaign.talentgrid.toolbar.counts.filtered-employees", {
              count: filteredReviewCount,
              defaultValue:
                filteredReviewCount > 1
                  ? "{{count}} collaborateurs filtrés sur {{totalCount}}"
                  : "{{count}} collaborateur filtré sur {{totalCount}}",
              totalCount: campaign.reviewCount,
            })}
          </Text>
        ) : (
          <>
            <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
              {t("people-review-campaign.talentgrid.toolbar.counts.employees", {
                count: campaign.reviewCount,
                defaultValue:
                  campaign.reviewCount > 1 ? "{{count}} collaborateurs" : "{{count}} collaborateur",
              })}
            </Text>
            {campaign.hasManagerPreparation && (
              <>
                <VerticalDivider left="xs" right="xs" />
                <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                  {t("people-review-campaign.talentgrid.toolbar.counts.managers", {
                    count: campaign.managerCount,
                    defaultValue:
                      campaign.managerCount > 1 ? "{{count}} managers" : "{{count}} manager",
                  })}
                </Text>
              </>
            )}
            <VerticalDivider left="xs" right="xs" />
            <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
              {t("people-review-campaign.talentgrid.toolbar.counts.coordinators", {
                count: campaign.coordinatorCount,
                defaultValue:
                  campaign.coordinatorCount > 1
                    ? "{{count}} coordinateurs"
                    : "{{count}} coordinateur",
              })}
            </Text>
            <VerticalDivider left="xs" right="xs" />
            <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
              {t("people-review-campaign.talentgrid.toolbar.counts.observers", {
                count: campaign.observerCount,
                defaultValue:
                  campaign.observerCount > 1 ? "{{count}} observateurs" : "{{count}} observateur",
              })}
            </Text>
          </>
        )}
      </Flex>

      <Flex gap="s">
        {campaign.permissions.arbitrate.isVisible && (
          <DSTooltip
            label={
              campaign.permissions.arbitrate.isEnabled
                ? selectedReviews.length === 0
                  ? t("people-review.campaign.toolbar-action.selection-is-empty", {
                      defaultValue: "Sélectionnez au moins une ligne dans le tableau",
                    })
                  : null
                : t(campaign.permissions.arbitrate.reasonKey, {
                    defaultValue: "Vous n'avez pas les droits pour valider les arbitrages.",
                  })
            }
          >
            <DSButton
              buttonSize="S"
              loading={isArbitrateLoading}
              label={t("peopleReview.talentGrid.bulkActions.arbitrate.label", {
                defaultValue: "Valider les arbitrages",
              })}
              disabled={
                !selectedReviews.length ||
                !campaign.permissions.arbitrate.isEnabled ||
                isArbitrateLoading
              }
              onClick={() =>
                arbitrate({
                  campaignId: campaign.id,
                  reviewIDs: selectedReviews.map(({ id }) => id),
                })
              }
            />
          </DSTooltip>
        )}

        <div ref={popperAnchorRef}>
          <DSDropdown>
            {campaign.permissions["change-manager"].isVisible && (
              <DSDropdownItem
                key="change-manager"
                onClick={() => openModal("changeManager", selectedReviews)}
                disabled={
                  !selectedReviews.length || !campaign.permissions["change-manager"].isEnabled
                }
                label={
                  !selectedReviews.length
                    ? t("peopleReview.talentGrid.bulkActions.changeManager.disabled.label", {
                        defaultValue: "Changer de manager",
                      })
                    : t("peopleReview.talentGrid.bulkActions.changeManager.label", {
                        count: selectedReviews.length,
                        defaultValue:
                          selectedReviews.length === 1
                            ? "Changer de manager pour un collaborateur"
                            : "Changer de manager pour {{count}} collaborateurs",
                      })
                }
                {...(!selectedReviews.length && {
                  tooltipLabel: t(
                    "peopleReview.talentGrid.bulkActions.changeManager.disabled.tooltip",
                    {
                      defaultValue: "Sélectionnez au moins une ligne dans le tableau",
                    }
                  ),
                })}
              />
            )}

            {campaign.permissions["delete-reviews"].isVisible && (
              <DSDropdownItem
                key="remove-Review"
                disabled={
                  !selectedReviews.length ||
                  !campaign.permissions["delete-reviews"].isEnabled ||
                  isRemoveLoading
                }
                onClick={() =>
                  remove({
                    campaignID: campaign.id,
                    reviewIDs: selectedReviews.map(({ id }) => id),
                  })
                }
                label={
                  !selectedReviews.length
                    ? t("peopleReview.talentGrid.bulkActions.removeReview.disabled.label", {
                        defaultValue: "Retirer un collaborateur de cette campagne",
                      })
                    : t("peopleReview.talentGrid.bulkActions.removeReview.label", {
                        count: selectedReviews.length,
                        defaultValue:
                          selectedReviews.length === 1
                            ? "Retirer un collaborateur de cette campagne"
                            : "Retirer les {{count}} collaborateurs de cette campagne",
                      })
                }
                {...(!selectedReviews.length && {
                  tooltipLabel: t(
                    "peopleReview.talentGrid.bulkActions.removeReview.disabledTooltip",
                    {
                      defaultValue: "Sélectionnez au moins une ligne dans le tableau",
                    }
                  ),
                })}
              />
            )}

            {campaign.permissions.export.isVisible && (
              <DSDropdownItem
                key="export-campaign"
                disabled={!campaign.permissions.export.isEnabled || isExportLoading}
                label={t("peopleReview.button.exportCampaign", {
                  defaultValue: "Exporter la campagne",
                })}
                onClick={() =>
                  export_({
                    campaignID: campaign.id,
                    columnsToExport: exportableColumns.filter(
                      (column) => columnVisibilityModel[column]
                    ),
                  })
                }
              />
            )}

            {(campaign.permissions["change-manager"].isVisible ||
              campaign.permissions["delete-reviews"].isVisible ||
              campaign.permissions.export.isVisible) && <DSDropdownDivider />}

            <DSDropdownItem
              key="select-columns"
              onClick={() => setIsPopperOpen(true)}
              label={t("talentGrid.select-columns.label", {
                defaultValue: "Gérer les colonnes du tableau",
              })}
            />
          </DSDropdown>

          <ColumnsVisibilityPopperWrapper isOpen={isPopperOpen} anchor={popperAnchorRef.current}>
            <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
              <div role="presentation">
                <ColumnsVisibilityPopperContent
                  columnVisibilityModel={columnVisibilityModel}
                  setColumnVisibilityModel={setColumnVisibilityModel}
                  /**
                   * @wip this component should accept GridColDef[] instead
                   * @wip this component should accept GridColDef[] instead
                   * @wip this component should accept GridColDef[] instead
                   * @wip this component should accept GridColDef[] instead
                   */
                  columnsConfig={columns
                    .filter(({ hideable }) => hideable ?? true)
                    .map(({ field, headerName }) => ({ headerName, key: field }))}
                />
              </div>
            </ClickAwayListener>
          </ColumnsVisibilityPopperWrapper>
        </div>
      </Flex>
    </Flex>
  );
}
